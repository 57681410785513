import {
    Flex, Text, useColorModeValue, useToast,
    Icon,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Button, Tabs, TabList, Tab, TabPanels, TabPanel, Input, Grid
} from "@chakra-ui/react";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { useEffect, useState, useRef } from "react";
import { BsCircleFill } from "react-icons/bs";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import Editor from "../../../../components/Editor/Editor";
import * as tus from 'tus-js-client'

const CREATE_SUBCONTRACTOR_ORDER = gql`
  mutation createSubcontractorOrder($input: CreateSubcontractorOrderInput!) {
    createSubcontractorOrder(input: $input) {
      id
    }
  }
`;

const GET_SUBCONTRACTORS = gql`
  query currentUser {
    currentUser {
      id
        photographyCompany {
            id
            subcontractors {
                id
                name
                email
                subcontractorType
                language
                mailSubject
                mailText
                bookingEmail
                isGlobal
                price
                priceType
            }
        }   
    }
}`

const GET_SUBCONTRACTOR_RENDERED_TEXTS = gql`
  query getSubcontractorRenderedMailText($id: ID!,$orderId: ID!) {
    getSubcontractorRenderedMailText(id: $id, orderId: $orderId) {
        mailSubject
        mailText
    }
}`

interface IProps {
    onClose: any,
    orderId: string,
    isOpen: boolean
}

const MediaProcesserV2: React.FC<IProps> = ({ orderId, isOpen, onClose }) => {
    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();
    const contractorBoxColor = useColorModeValue("gray.200", "white");
    const bgPrevButton = useColorModeValue("gray.100", "gray.100");
    const [activeBullets, setActiveBullets] = useState({
        subcontractorTab: true,
        uploadTab: false,
        messageTab: false,
    });
    const [selectedSubContractor, setSelectedSubContractor] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [filesForUpload, setfilesForUpload] = useState<FileList>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [mediaUploadItemsLevel0, setMediaUploadItemsLevel0] = useState([]);

    const subcontractorTab = useRef<HTMLButtonElement>();
    const uploadTab = useRef<HTMLButtonElement>();
    const messageTab = useRef<HTMLButtonElement>();

    const { loading, error, data } = useQuery(GET_SUBCONTRACTORS);
    const [createOrder] = useMutation(CREATE_SUBCONTRACTOR_ORDER);
    const [getRenderedTexts, { data: dataRenderedTexts }] = useLazyQuery(GET_SUBCONTRACTOR_RENDERED_TEXTS, { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });



    const setSubContractor = (subcontractorId) => {
        //find the subcontractor
        data.currentUser.photographyCompany.subcontractors.forEach((subcontractor) => {
            if (subcontractor.id === subcontractorId) {
                setSelectedSubContractor({ ...subcontractor });
                const input = {
                    id: subcontractorId,
                    orderId: orderId
                }
                getRenderedTexts({ variables: input }).then((data) => {
                    let tmp = { ...subcontractor };
                    tmp.mailText = data.data.getSubcontractorRenderedMailText.mailText;
                    tmp.mailSubject = data.data.getSubcontractorRenderedMailText.mailSubject;
                    setSelectedSubContractor(tmp);
                })
            }
        });
    }
    const updateData = (field: string, data: any) => {
        selectedSubContractor[field] = data;
        setSelectedSubContractor({ ...selectedSubContractor });
    };

    const updateText = (text) => {
        if (text !== "") {
            selectedSubContractor.mailText = text;
            setSelectedSubContractor({ ...selectedSubContractor });
        }
    };

    const sentMaterial = () => {
        setIsUploading(true);
        let file = filesForUpload[0];
        const uniqueRef = "tus_" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        // Create a new tus upload
        let upload = new tus.Upload(file, {
            endpoint: process.env.REACT_APP_EXTERNAL_UPLOAD_API_URL + '/uploads/files/',
            retryDelays: [0, 3000, 5000, 10000, 20000],

            chunkSize: 10000000 * 5,

            metadata: {
                filename: file.name,
                filetype: file.type,
                uniqueRef: uniqueRef,
            },
            onError: function (error) {
                console.log('Failed because: ' + error)
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                let percentage = ((bytesUploaded / bytesTotal) * 100)
                setUploadProgress(percentage);
                console.log(bytesUploaded, bytesTotal, percentage + '%')
            },
            onSuccess: function () {
                console.log('file done:', upload)
                createSubContractorOrder(uniqueRef);
                //console.log('Download %s from %s', upload.file.name, upload.url)
            },
        })

        upload.start()
    }

    const createSubContractorOrder = (mediaRef: string) => {
        let inputData = {} as any;
        inputData.orderId = orderId;
        inputData.subcontractorId = selectedSubContractor.id;
        inputData.mailSubject = selectedSubContractor.mailSubject;
        inputData.mailText = selectedSubContractor.mailText;
        inputData.subcontractorMediaRef = mediaRef;

        console.log('selectedSubContractor', selectedSubContractor)
        console.log('inputData', inputData)


        createOrder({ variables: { input: inputData } }).then(() => {

            toast({
                title: "Ordren er oprettet.",
                description: "",
                status: "success",
                duration: 2000,
                isClosable: true,
            })

            onClose();

        }).catch(() => {
            toast({
                title: "Ordren kunne ikke oprettes.",
                description: "Der opstod en fejl i oprettelsen af ordren.",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        });

    }


    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                var fileCatcher = document.getElementById("file-catcher");
                var fileInput = document.getElementById("file-input") as HTMLInputElement;
                var fileListDisplay = document.getElementById("file-list-display");

                const getAllFileEntries = async (dataTransferItemList) => {
                    let fileEntries = [];
                    // Use BFS to traverse entire directory/file structure
                    let queue = [];
                    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
                    for (let i = 0; i < dataTransferItemList.length; i++) {
                        queue.push(dataTransferItemList[i].webkitGetAsEntry());
                    }
                    while (queue.length > 0) {
                        let entry = queue.shift();
                        if (entry.isFile) {
                            fileEntries.push(entry);
                        } else if (entry.isDirectory) {
                            let reader = entry.createReader();
                            queue.push(...(await readAllDirectoryEntries(reader)));
                        }
                    }
                    // return fileEntries;
                    return Promise.all(
                        fileEntries.map((entry) => readEntryContentAsync(entry))
                    );
                };

                // Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
                const readAllDirectoryEntries = async (directoryReader) => {
                    let entries = [];
                    let readEntries = await readEntriesPromise(directoryReader) as any;
                    while (readEntries.length > 0) {
                        entries.push(...readEntries);
                        readEntries = await readEntriesPromise(directoryReader);
                    }
                    return entries;
                };

                // Wrap readEntries in a promise to make working with readEntries easier
                const readEntriesPromise = async (directoryReader) => {
                    try {
                        return await new Promise((resolve, reject) => {
                            directoryReader.readEntries(resolve, reject);
                        });
                    } catch (err) {
                        console.error(err);
                    }
                };

                const readEntryContentAsync = async (entry) => {
                    return new Promise((resolve) => {
                        let reading = 0;
                        const contents = [];

                        reading++;
                        entry.file(async (file) => {
                            reading--;
                            const rawFile = file;
                            rawFile.path = entry.fullPath;
                            contents.push(rawFile);

                            if (reading === 0) {
                                resolve(contents);
                            }
                        });
                    });
                };



                //TUS upload
                fileInput.addEventListener('change', function (e) {
                    let notZipFile = false;
                    for (var i = 0; i < fileInput.files.length; i++) {
                        let file = fileInput.files[i];
                        console.log('file', file)
                        if (!file.name.includes('.zip')) {
                            notZipFile = true;
                        }
                    }

                    if (notZipFile) {
                        toast({
                            title: "Filen er ikke en zip fil. ",
                            description: "Der kan kun uploades zip filer.",
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                        return;
                    }

                    setfilesForUpload(fileInput.files);

                    //const flattenFiles = Array.from(fileInput.files) as any;

                    const uploadTextContainer = document.getElementsByClassName("uploadTextContainer");
                    if (uploadTextContainer.length > 0) {
                        (uploadTextContainer[0] as HTMLDivElement).style.display = "none";
                    }
                    fileInput.style.display = "none";
                    fileListDisplay.style.display = "flex";


                    setMediaUploadItemsLevel0([{ name: fileInput.files[0].name, isfolder: false }]);
                })


                fileCatcher.addEventListener("drop", async function (event: any) {
                    console.log('file dropped');

                    let items = event.dataTransfer.items;
                    event.preventDefault();


                    const files = await getAllFileEntries(items);
                    const flattenFiles = files.reduce((acc: any, val: any) => acc.concat(val), []) as any;

                    setfilesForUpload(flattenFiles as FileList);

                    //check that all files are zip files
                    let notZipFile = false;
                    for (let i = 0; i < flattenFiles.length; i++) {
                        if (flattenFiles[i].type !== 'application/zip') {
                            notZipFile = true;
                        }
                    }

                    if (notZipFile) {
                        toast({
                            title: "Filen er ikke en zip fil. ",
                            description: "Der kan kun uploades zip filer.",
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                        });
                        return;
                    }

                    const uploadTextContainer = document.getElementsByClassName("uploadTextContainer");
                    if (uploadTextContainer.length > 0) {
                        (uploadTextContainer[0] as HTMLDivElement).style.display = "none";
                    }
                    fileInput.style.display = "none";
                    fileListDisplay.style.display = "flex";


                    setMediaUploadItemsLevel0([{ name: flattenFiles[0].name, isfolder: false }]);
                });
            }, 1000);
        }
    }, [isOpen, toast]);

    if (loading) return <p></p>; //loading

    if (error) {
        return <p>Error getting subcontractors from server (1)</p>;
    }

    if (!data) {
        return <p>Error getting subcontractors from server (2)</p>;
    }


    const MediaProcessers = data.currentUser.photographyCompany.subcontractors.filter((subcontractor: any) => subcontractor.subcontractorType === 'MEDIA_PROCESSOR');

    const translatePriceType = (priceType) => {
        switch (priceType) {
            case 'PRICE_PER_IMAGE':
                return 'billede';
            case 'PRICE_PER_ORDER':
                return 'ordre';
            case 'NONE':
                return '';
            default:
                return '';
        }
    }

    const uploadProgressText = (progress: number) => {
        return Math.round(progress);
    }

    return (

        <Modal onClose={onClose} size="xl" isOpen={isOpen} >
            <ModalOverlay />
            <ModalContent maxH="800px" maxW="1000px" bg={'#f9f9f9'}>
                <ModalHeader>Send materiale til redigering</ModalHeader>

                <ModalCloseButton />
                <ModalBody>


                    <Flex direction='column' style={{ width: '100%' }}>
                        <Flex
                            direction="column"
                            minH="100vh"
                            align="center"
                            pt={{ sm: "0px", md: "0px" }}
                        >
                            <Tabs variant="unstyled" mt="24px" width={'100%'}>
                                <TabList display="flex" justifyContent="center">
                                    <Tab ref={subcontractorTab}
                                        _focus={{ outline: "none" }}
                                        w={{ sm: "80px", md: "200px" }}
                                        onClick={() =>
                                            setActiveBullets({
                                                subcontractorTab: true,
                                                uploadTab: false,
                                                messageTab: false,
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: "80px", md: "200px" },
                                                height: "3px",
                                                bg: activeBullets.uploadTab ? textColor : "gray.200",
                                                left: { sm: "12px", md: "32px" },
                                                top: { sm: activeBullets.subcontractorTab ? "6px" : "4px", md: null },
                                                position: "absolute",
                                                bottom: activeBullets.subcontractorTab ? "40px" : "38px",
                                                zIndex: -1,
                                                transition: "all .3s ease",
                                            }}
                                        >
                                            <Icon
                                                as={BsCircleFill}
                                                color={activeBullets.subcontractorTab ? textColor : "gray.300"}
                                                w={activeBullets.subcontractorTab ? "16px" : "12px"}
                                                h={activeBullets.subcontractorTab ? "16px" : "12px"}
                                                mb="8px"
                                            />
                                            <Text
                                                color={activeBullets.subcontractorTab ? { textColor } : "gray.300"}
                                                fontWeight={activeBullets.subcontractorTab ? "bold" : "normal"}
                                                display={{ sm: "none", md: "block" }}
                                            >
                                                Leverandør
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        ref={uploadTab}
                                        _focus={{ outline: "none" }}
                                        w={{ sm: "80px", md: "200px" }}
                                        onClick={() =>
                                            setActiveBullets({
                                                subcontractorTab: true,
                                                uploadTab: true,
                                                messageTab: false,
                                            })
                                        }
                                    >
                                        <Flex

                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: "80px", md: "200px" },
                                                height: "3px",
                                                bg: activeBullets.messageTab ? textColor : "gray.200",
                                                left: { sm: "12px", md: "60px" },
                                                top: { sm: activeBullets.uploadTab ? "6px" : "4px", md: null },
                                                position: "absolute",
                                                bottom: activeBullets.uploadTab ? "40px" : "38px",
                                                zIndex: -1,
                                                transition: "all .3s ease",
                                            }}
                                        >
                                            <Icon
                                                as={BsCircleFill}
                                                color={activeBullets.uploadTab ? textColor : "gray.300"}
                                                w={activeBullets.uploadTab ? "16px" : "12px"}
                                                h={activeBullets.uploadTab ? "16px" : "12px"}
                                                mb="8px"
                                            />
                                            <Text
                                                color={activeBullets.uploadTab ? { textColor } : "gray.300"}
                                                fontWeight={activeBullets.uploadTab ? "bold" : "normal"}
                                                transition="all .3s ease"
                                                _hover={{ color: textColor }}
                                                display={{ sm: "none", md: "block" }}
                                            >
                                                Upload materiale
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        ref={messageTab}
                                        _focus={{ outline: "none" }}
                                        w={{ sm: "80px", md: "200px" }}
                                        onClick={() =>
                                            setActiveBullets({
                                                subcontractorTab: true,
                                                uploadTab: true,
                                                messageTab: true,
                                            })
                                        }
                                    >
                                        <Flex direction="column" justify="center" align="center">
                                            <Icon
                                                as={BsCircleFill}
                                                color={activeBullets.messageTab ? textColor : "gray.300"}
                                                w={activeBullets.messageTab ? "16px" : "12px"}
                                                h={activeBullets.messageTab ? "16px" : "12px"}
                                                mb="8px"
                                            />
                                            <Text
                                                color={activeBullets.messageTab ? { textColor } : "gray.300"}
                                                fontWeight={activeBullets.messageTab ? "bold" : "normal"}
                                                transition="all .3s ease"
                                                _hover={{ color: textColor }}
                                                display={{ sm: "none", md: "block" }}
                                            >
                                                Send besked
                                            </Text>
                                        </Flex>
                                    </Tab>
                                </TabList>
                                <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
                                    <TabPanel>
                                        <Card>
                                            <CardHeader mb="40px">
                                                <Flex direction="column">
                                                    <Text
                                                        color={textColor}
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        mb="3px"
                                                    >
                                                        Leverandør
                                                    </Text>
                                                    <Text color="gray.400" fontWeight="normal" fontSize="sm">
                                                        Vælg en underleverandør
                                                    </Text>
                                                </Flex>
                                            </CardHeader>
                                            <CardBody>
                                                <Flex direction="column" w="100%">
                                                    <Grid
                                                        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                                        templateRows={{ md: "repeat(1, 1fr)" }}
                                                        gap="10px"
                                                    >
                                                        {MediaProcessers.length === 0 ? (

                                                            <Text fontSize="md" color={textColor} textAlign="center">
                                                                <i>Der er ikke oprettet nogen leverandører</i>
                                                            </Text>
                                                        ) : (<>
                                                            {MediaProcessers.map((subcontractor: any) => {
                                                                if (selectedSubContractor && selectedSubContractor.id === subcontractor.id) {

                                                                    return (
                                                                        <Card border={'2px solid'} borderColor={contractorBoxColor} bg={contractorBoxColor} key={subcontractor.id} width={'100%'} color="white" p="10px" borderRadius="5px" cursor="pointer">
                                                                            {subcontractor.isGlobal ? (
                                                                                <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                                                                                    <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                        {subcontractor.name}
                                                                                    </Text>
                                                                                    <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                        - {subcontractor.price} kr. pr. {translatePriceType(subcontractor.priceType)}
                                                                                    </Text>
                                                                                </div>
                                                                            ) : (
                                                                                <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                    {subcontractor.name}
                                                                                </Text>

                                                                            )
                                                                            }
                                                                        </Card>
                                                                    )
                                                                } else {

                                                                    return (
                                                                        <Card border={'2px solid'} borderColor={contractorBoxColor} key={subcontractor.id} width={'100%'} color="white" p="10px" borderRadius="5px" cursor="pointer" onClick={() => { setSubContractor(subcontractor.id); uploadTab.current.click() }}>
                                                                            {subcontractor.isGlobal ? (
                                                                                <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                                                                                    <Text fontSize="sm" fontWeight="bold" color={textColor} >
                                                                                        {subcontractor.name}
                                                                                    </Text>
                                                                                    <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                        - {subcontractor.price} kr. pr. {translatePriceType(subcontractor.priceType)}
                                                                                    </Text>
                                                                                </div>
                                                                            ) : (
                                                                                <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                    {subcontractor.name}
                                                                                </Text>
                                                                            )
                                                                            }
                                                                        </Card>
                                                                    )
                                                                }
                                                            })}
                                                        </>
                                                        )}

                                                    </Grid>
                                                    {selectedSubContractor !== null && (
                                                        <Button
                                                            variant="no-hover"
                                                            bg="#151928"
                                                            alignSelf="flex-end"
                                                            mt="24px"
                                                            w="100px"
                                                            h="35px"
                                                            onClick={() => { if (uploadTab.current) { uploadTab.current.click() } }}
                                                        >
                                                            <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                Næste
                                                            </Text>
                                                        </Button>
                                                    )}
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel>
                                        <Card>
                                            <CardHeader mb="40px">
                                                <Text
                                                    color={textColor}
                                                    fontSize="lg"
                                                    fontWeight="bold"
                                                    mb="3px"
                                                >
                                                    Upload materiale
                                                </Text>
                                            </CardHeader>
                                            <CardBody>
                                                <Flex direction="column" w="100%">
                                                    <Flex align='center' mb='18px'>
                                                        <form id='file-catcher' style={{ width: '100%' }}>
                                                            <div className="fileuploadContainer" style={{ width: '100%' }}>
                                                                <input id='file-input' type='file' />
                                                                <div className="uploadTextContainer">
                                                                    <i className="material-icons uploadIcon">cloud_upload</i>
                                                                    <p className="uploadText"><b>Zip dine filer</b><br />Klik derefter her eller træk din zip fil herhen.</p>
                                                                </div>

                                                                <div id='file-list-display' style={{ display: 'none', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                    {mediaUploadItemsLevel0.length > 1 ? (
                                                                        <>

                                                                            {mediaUploadItemsLevel0.map((item, index) => {
                                                                                return (
                                                                                    <div key={index} className="uploadMediaProcesserItemContainer">
                                                                                        <svg fill="#616161" height="120" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 48 48" >
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M47.987,21.938c-0.006-0.091-0.023-0.178-0.053-0.264c-0.011-0.032-0.019-0.063-0.033-0.094
			c-0.048-0.104-0.109-0.202-0.193-0.285c-0.001-0.001-0.001-0.001-0.001-0.001L42,15.586V10c0-0.022-0.011-0.041-0.013-0.063
			c-0.006-0.088-0.023-0.173-0.051-0.257c-0.011-0.032-0.019-0.063-0.034-0.094c-0.049-0.106-0.11-0.207-0.196-0.293l-9-9
			c-0.086-0.086-0.187-0.148-0.294-0.196c-0.03-0.014-0.06-0.022-0.091-0.032c-0.085-0.03-0.172-0.047-0.263-0.052
			C32.039,0.01,32.021,0,32,0H7C6.448,0,6,0.448,6,1v14.586l-5.707,5.707c0,0-0.001,0.001-0.002,0.002
			c-0.084,0.084-0.144,0.182-0.192,0.285c-0.014,0.031-0.022,0.062-0.033,0.094c-0.03,0.086-0.048,0.173-0.053,0.264
			C0.011,21.96,0,21.978,0,22v19c0,0.552,0.448,1,1,1h5v5c0,0.552,0.448,1,1,1h34c0.552,0,1-0.448,1-1v-5h5c0.552,0,1-0.448,1-1V22
			C48,21.978,47.989,21.96,47.987,21.938z M44.586,21H42v-2.586L44.586,21z M38.586,9H33V3.414L38.586,9z M8,2h23v8
			c0,0.552,0.448,1,1,1h8v5v5H8v-5V2z M6,18.414V21H3.414L6,18.414z M40,46H8v-4h32V46z M46,40H2V23h5h34h5V40z"/>
                                                                                                    <polygon points="14.582,27.766 18.356,27.766 14.31,36.317 14.31,38 20.6,38 20.6,36.164 16.571,36.164 20.6,27.613 20.6,25.964 
			14.582,25.964 		"/>
                                                                                                    <rect x="22.436" y="25.964" width="2.04" height="12.036" />
                                                                                                    <path d="M32.542,26.72c-0.323-0.277-0.688-0.473-1.097-0.586c-0.408-0.113-0.805-0.17-1.19-0.17h-3.332V38h2.006v-4.828h1.428
			c0.419,0,0.827-0.074,1.224-0.221c0.397-0.147,0.748-0.374,1.054-0.68c0.306-0.306,0.553-0.688,0.739-1.148
			c0.187-0.459,0.28-0.994,0.28-1.606c0-0.68-0.105-1.247-0.314-1.7C33.132,27.364,32.866,26.998,32.542,26.72z M31.259,31.005
			c-0.306,0.334-0.697,0.501-1.173,0.501H28.93v-3.825h1.156c0.476,0,0.867,0.147,1.173,0.442c0.306,0.295,0.459,0.765,0.459,1.411
			C31.718,30.18,31.565,30.67,31.259,31.005z"/>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                        <div style={{ fontSize: '14px' }}>{item.name}</div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {mediaUploadItemsLevel0.length > 0 && (
                                                                                <div className="uploadMediaProcesserItemContainer">
                                                                                    <svg fill="#616161" height="120" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 48 48" >
                                                                                        <g>
                                                                                            <g>
                                                                                                <path d="M47.987,21.938c-0.006-0.091-0.023-0.178-0.053-0.264c-0.011-0.032-0.019-0.063-0.033-0.094
			c-0.048-0.104-0.109-0.202-0.193-0.285c-0.001-0.001-0.001-0.001-0.001-0.001L42,15.586V10c0-0.022-0.011-0.041-0.013-0.063
			c-0.006-0.088-0.023-0.173-0.051-0.257c-0.011-0.032-0.019-0.063-0.034-0.094c-0.049-0.106-0.11-0.207-0.196-0.293l-9-9
			c-0.086-0.086-0.187-0.148-0.294-0.196c-0.03-0.014-0.06-0.022-0.091-0.032c-0.085-0.03-0.172-0.047-0.263-0.052
			C32.039,0.01,32.021,0,32,0H7C6.448,0,6,0.448,6,1v14.586l-5.707,5.707c0,0-0.001,0.001-0.002,0.002
			c-0.084,0.084-0.144,0.182-0.192,0.285c-0.014,0.031-0.022,0.062-0.033,0.094c-0.03,0.086-0.048,0.173-0.053,0.264
			C0.011,21.96,0,21.978,0,22v19c0,0.552,0.448,1,1,1h5v5c0,0.552,0.448,1,1,1h34c0.552,0,1-0.448,1-1v-5h5c0.552,0,1-0.448,1-1V22
			C48,21.978,47.989,21.96,47.987,21.938z M44.586,21H42v-2.586L44.586,21z M38.586,9H33V3.414L38.586,9z M8,2h23v8
			c0,0.552,0.448,1,1,1h8v5v5H8v-5V2z M6,18.414V21H3.414L6,18.414z M40,46H8v-4h32V46z M46,40H2V23h5h34h5V40z"/>
                                                                                                <polygon points="14.582,27.766 18.356,27.766 14.31,36.317 14.31,38 20.6,38 20.6,36.164 16.571,36.164 20.6,27.613 20.6,25.964 
			14.582,25.964 		"/>
                                                                                                <rect x="22.436" y="25.964" width="2.04" height="12.036" />
                                                                                                <path d="M32.542,26.72c-0.323-0.277-0.688-0.473-1.097-0.586c-0.408-0.113-0.805-0.17-1.19-0.17h-3.332V38h2.006v-4.828h1.428
			c0.419,0,0.827-0.074,1.224-0.221c0.397-0.147,0.748-0.374,1.054-0.68c0.306-0.306,0.553-0.688,0.739-1.148
			c0.187-0.459,0.28-0.994,0.28-1.606c0-0.68-0.105-1.247-0.314-1.7C33.132,27.364,32.866,26.998,32.542,26.72z M31.259,31.005
			c-0.306,0.334-0.697,0.501-1.173,0.501H28.93v-3.825h1.156c0.476,0,0.867,0.147,1.173,0.442c0.306,0.295,0.459,0.765,0.459,1.411
			C31.718,30.18,31.565,30.67,31.259,31.005z"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                    <div style={{ fontSize: '14px' }}>{mediaUploadItemsLevel0[0].name}</div>
                                                                                </div>
                                                                            )}
                                                                        </>

                                                                    )}

                                                                </div>
                                                            </div>
                                                            <button id="submitbtn" type='submit' style={{ display: 'none' }}>
                                                                Submit
                                                            </button>
                                                        </form>

                                                    </Flex>
                                                    <Flex justify="space-between">
                                                        <Button
                                                            variant="no-hover"
                                                            bg={bgPrevButton}
                                                            alignSelf="flex-end"
                                                            mt="24px"
                                                            w="100px"
                                                            h="35px"
                                                            onClick={() => { if (subcontractorTab.current) { subcontractorTab.current.click() } }}

                                                        >
                                                            <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                                                Tilbage
                                                            </Text>
                                                        </Button>
                                                        <Button
                                                            variant="no-hover"
                                                            bg="#151928"
                                                            alignSelf="flex-end"
                                                            mt="24px"
                                                            w="100px"
                                                            h="35px"
                                                            onClick={() => { if (messageTab.current) { messageTab.current.click() } }}
                                                        >
                                                            <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                Næste
                                                            </Text>
                                                        </Button>
                                                    </Flex>
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel>
                                        <Card>
                                            <CardHeader mb="40px">
                                                <Text
                                                    color={textColor}
                                                    fontSize="lg"
                                                    fontWeight="bold"
                                                    mb="3px"
                                                >
                                                    {isUploading ? (
                                                        ""
                                                    ) : (
                                                        "Send besked"
                                                    )}

                                                </Text>
                                            </CardHeader>
                                            <CardBody>
                                                {isUploading ? (
                                                    <Flex direction="column" justifyContent={"center"} w="100%">
                                                        <Flex direction="column" alignItems={"center"} w="100%" h="200px">
                                                            <div className="lds-ripple">
                                                                <div></div>
                                                                <div></div>
                                                            </div>

                                                            <Text fontSize="md" textAlign={"center"} color={textColor} fontWeight="bold" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                {uploadProgressText(uploadProgress)}%
                                                            </Text>
                                                            <Text fontSize="md" textAlign={"center"} color={textColor} whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                Uploader filen og sender ordren.
                                                            </Text>

                                                        </Flex>
                                                    </Flex>
                                                ) : (
                                                    <Flex direction="column" w="100%">
                                                        <Flex align="center" mb="18px">
                                                            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                Emne:{" "}
                                                            </Text>
                                                            <Input
                                                                variant="flushed"
                                                                type="text"
                                                                name="mailSubject"
                                                                defaultValue={(selectedSubContractor !== null ? selectedSubContractor.mailSubject : "")}
                                                                onBlur={(e) =>
                                                                    updateData("mailSubject", e.target.value)
                                                                }
                                                            />
                                                        </Flex>

                                                        {selectedSubContractor && !selectedSubContractor.isGlobal && (
                                                            <Flex align="center" mb="18px">
                                                                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                    Sendes til:{" "}
                                                                </Text>

                                                                {selectedSubContractor && (
                                                                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                        {selectedSubContractor.bookingEmail}
                                                                    </Text>
                                                                )}
                                                            </Flex>
                                                        )}

                                                        <Flex align="center" mb="18px">
                                                            {dataRenderedTexts && (
                                                                <Editor value={(dataRenderedTexts !== null ? dataRenderedTexts.getSubcontractorRenderedMailText.mailText : "")} changed={(e) => (updateText(e))} showFields={false} />
                                                            )}
                                                        </Flex>


                                                        <Flex justify="space-between">
                                                            <Button
                                                                variant="no-hover"
                                                                bg={bgPrevButton}
                                                                alignSelf="flex-end"
                                                                mt="24px"
                                                                w="100px"
                                                                h="35px"
                                                                onClick={() => { if (uploadTab.current) { uploadTab.current.click() } }}

                                                            >
                                                                <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                                                    Tilbage
                                                                </Text>
                                                            </Button>
                                                            <Button
                                                                variant="no-hover"
                                                                bg="#151928"
                                                                alignSelf="flex-end"
                                                                mt="24px"
                                                                w="100px"
                                                                h="35px"
                                                                onClick={() => { sentMaterial() }}
                                                            >
                                                                <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                    Send
                                                                </Text>
                                                            </Button>
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </TabPanel>

                                </TabPanels>
                            </Tabs>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent >
        </Modal >
    );

}

export default MediaProcesserV2;