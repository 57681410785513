// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Stack,
  Spacer,
  Button,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import IconBox from "./../../../../../components/Icons/IconBox";
import { DocumentIcon } from "./../../../../../components/Icons/Icons";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import CardHeader from "./../../../../../components/Card/CardHeader";
import { TbTrashX } from "react-icons/tb";
import React, { useRef, useState } from "react";
import { gql, useMutation } from "@apollo/client";

const uploadFileGql = gql`
  mutation uploadPhotographyCompanyDoc($input: UploadPhotographyCompanyDocInput!) {
    uploadPhotographyCompanyDoc(input: $input)
  }
`;

const deleteFileGql = gql`
  mutation deletePhotographyCompanyDoc($input: DeletePhotographyCompanyDocInput!) {
    deletePhotographyCompanyDoc(input: $input)
  }
`;


export enum DocumentType {
  CONTRACT = "CONTRACT", // eslint-disable-line
  PROPARATION_BROCHURE = "PROPARATION_BROCHURE", // eslint-disable-line
  OTHER = "OTHER", // eslint-disable-line
  BUYING_TERMS = "BUYING_TERMS" // eslint-disable-line
}

interface IProps {
  data: any
  forceRerender: any
  heading: String
  documentType: DocumentType
  allowMultiple: boolean
}

const ProfileDocument: React.FC<IProps> = ({ data, forceRerender, heading, documentType, allowMultiple }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [deleteDocId, setdeleteDocId] = useState("");
  const [uploadFile, { loading: loadingUpload, data: dataUpload }] = useMutation(uploadFileGql);
  const [deleteFile, { loading: loadingDelete, data: dataDelete }] = useMutation(deleteFileGql);
  const inputRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");

  if (!loadingUpload && dataUpload?.uploadPhotographyCompanyDoc) {
    setTimeout(() => {
      forceRerender();
    }, 500);
  }

  if (!loadingDelete && dataDelete) {
    setTimeout(() => {
      forceRerender();
    }, 500);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleDeleteDocClick = (id) => {
    setdeleteDocId(id);
    onOpen();
  };

  const deleteDoc = () => {
    onClose();

    const inputData = {
      photographyCompanyId: data.id,
      docId: deleteDocId,
    };

    deleteFile({ variables: { input: inputData } });
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  async function handleFileChange(e) {
    if (!e.target.files) {
      return;
    }

    const fileBase64 = await toBase64(e.target.files[0]);

    const inputData = {
      photographyCompanyId: data.id,
      documentType: documentType,
      filename: e.target.files[0].name,
      mimeType: e.target.files[0].type,
      fileContentBase64: fileBase64,
    };

    uploadFile({ variables: { input: inputData } });
  }

  const downloadDoc = (docUrl) => {
    window.open(docUrl);
  };

  const filteredDocuments = data.documents.filter((document) => document.documentType === documentType);

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
      <CardHeader style={{ justifyContent: "space-between" }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {heading}
        </Text>

        {((allowMultiple) || (filteredDocuments.length === 0))  && (
        <Text fontSize="lg" color="gray.400" cursor="pointer" onClick={() => handleUploadClick()}>
          Upload
        </Text>
        )}
        <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} />
      </CardHeader>

      <CardBody w="100%" pt="28px" style={{ flexDirection: "column" }}>
        {filteredDocuments.map((document) => (
        
          <Stack key={document.id} direction="column" spacing="24px" w="100%" style={{ paddingTop: "10px" }}>
            <Flex align="center" w="100%">
              <Flex align="center">
                <IconBox h={"40px"} w={"40px"} bg="gray.100" me="16px">
                  <DocumentIcon h={"20px"} w={"20px"} color="gray.400" />
                </IconBox>
                <Flex direction="column" cursor="pointer" onClick={() => downloadDoc(document.orgDocStorageUrl)}>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    {document.filename}
                  </Text>
                  <Text color="gray.400" fontSize="xs">
                    {document.filename}
                  </Text>
                </Flex>
              </Flex>
              <Spacer />
              <Button variant="transparent-with-icon" px="0px">
                <Icon as={TbTrashX} color="gray.400" w="20px" h="20px" cursor="pointer" onClick={() => handleDeleteDocClick(document.id)} />
              </Button>
            </Flex>
          </Stack>
         
        ))}
      </CardBody>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={deleteDoc}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet dokument
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteDoc} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};

export default ProfileDocument;
