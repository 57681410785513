import { CSSProperties, forwardRef, HTMLAttributes } from "react"
import { FaRegPlayCircle } from "react-icons/fa";
import mwLogo from "./../../../../assets/svg/Mindworking-logo.svg";
import { MwMediaDescriptions } from "./../../../../mwMediaDescriptions";

import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import React from "react";

type Props = {
    media: any
    handleCheckBoxClicked?: any
    handleDescriptionChanged?: any
    isSelected?: boolean
    isOpacityEnabled?: boolean
    isDragging?: boolean
} & HTMLAttributes<HTMLDivElement>



const OrderMediaListThumbnail = forwardRef<HTMLDivElement, Props>(
    //const OrderMediaListThumbnail: React.FC<IProps> = ({ media, isSelected, handleCheckBoxClicked }) => {



    ({ media, isSelected, handleCheckBoxClicked, handleDescriptionChanged, isOpacityEnabled, isDragging, style, ...props }, ref) => {
        const [mediaDescription, setMediaDescription] = React.useState(media.description);

        const styles: CSSProperties = {
            opacity: isOpacityEnabled ? "0.4" : "1",
            cursor: isDragging ? "grabbing" : "grab",
            lineHeight: "0.5",
            transform: isDragging ? "scale(1.15)" : "scale(1)",
            ...style
        }

        const handleDescriptionChangedIntarnal = (id, value, updateServer) => {
            setMediaDescription(value);

            if (!updateServer) {
                return;
            }

            if (media.description === value) {
                return;
            }

            //updateServer
            handleDescriptionChanged(id, value);
        }
//onBlur={(e) => { handleDescriptionChangedIntarnal(media.id, e.currentTarget.value, true) }}
        return (
            <div className="mediaItem" ref={ref} style={styles} {...props} key={'Thumbnail_' + media.id}  >

                <div className="mediaItemThumbnailContainer" onClick={(e) => { e.stopPropagation(); document.getElementById('checkbox_' + media.id).click(); }}>
                    {media.mediaType === 'IMAGE' ? (
                        <img src={media.thumpnailUrl} alt="placeholder" className="mediaItemThumbnail" />
                    ) : (
                        <>
                            <img src={media.thumpnailUrl} alt="placeholder" className="mediaItemThumbnail" />
                            <div className="orderMediaListVideoPlayIconContainer">
                                <FaRegPlayCircle className="orderMediaListVideoPlayIcon" />
                            </div>
                        </>
                    )}
                    <div className="orderMediaListUploadedStatus">
                        {media.mindworkingStatus === 'UPLOADED' && (
                            <img src={mwLogo} height={20} width={20} alt='Uploaded til Mindworking' />
                        )}

                        {media.leadHubStatus === 'UPLOADED' && (
                            <img src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAANlBMVEX////////9/f/////r7PxZaegaOuPJzfbX2vgwSeS4vfQ4TuWKlO5qeOqbo/CmrfFEV+Z5hewsICHSAAAAA3RSTlMDufvnWETiAAAAtUlEQVR4AXTSCwoDIQxFUcd/fNqJ+99sNUwZSGMAEY7CRXTu8sEcfy0Lx7mcP6N37z7+6YsplyNWonjEBgpH7BgSYWLCZ613vi0k7B6gGRilJxKqgUV6Ksgb+EGSZLaCJqYcmaH0olBi5cgAZ40syMwAU9TIKZaxrO00hUPuMMrqThorATQYq5imxuBrDQ1jY9co09EXYpo4cL+vpPD5DMTDwrgttGziMz788Dt8SRNvosabHQBywQ1KsNl1KQAAAABJRU5ErkJggg=='} style={{
                                borderRadius: '15px',
                                position: 'relative',
                                top: '-2px',
                                width: '17px',
                            }} alt='Uploaded til LeadHub' />
                        )}
                    </div>
                </div>



                <AutoComplete openOnFocus onChange={value => { handleDescriptionChangedIntarnal(media.id, value, true) }} >
                    <AutoCompleteInput variant="filled"
                        value={mediaDescription}
                        placeholder="Beskrivelse"
                        onChange={(e) => { handleDescriptionChangedIntarnal(media.id, e.currentTarget.value, false) }}
                        

                    />
                    <AutoCompleteList >
                        {MwMediaDescriptions.map((description, cid) => (
                            <AutoCompleteItem
                                key={`option-${cid}`}
                                value={description}
                                textTransform="capitalize"
                            >
                                {description}
                            </AutoCompleteItem>
                        ))}
                    </AutoCompleteList>
                </AutoComplete>

                {/*
                <Input variant="flushed" placeholder="Beskrivelse" defaultValue={media.description} onClick={(e) => { e.stopPropagation(); }} onBlur={(e) => { handleDescriptionChanged(media.id, e.currentTarget.value) }} />
               */}
                <div className="SelectFileCheckboxContainer">
                    <input id={'checkbox_' + media.id} checked={isSelected} type="checkbox" value={media.id} className={'SelectFileCheckbox_' + media.id} name="SelectFileCheckbox" onChange={() => { }} onClick={handleCheckBoxClicked} />
                </div>
            </div>
        );
    }
)

export default OrderMediaListThumbnail;