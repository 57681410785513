/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { HSeparator } from "./../Separator/Separator";
import PropTypes from "prop-types";
import React from "react";

export default function Configurator(props) {

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode

  const signOut = () => {
    localStorage.removeItem("system_user_type");
    localStorage.removeItem("system_user_id");
    localStorage.removeItem("system_user_name");
    localStorage.removeItem("loglevel");
    localStorage.removeItem("system_token");
    localStorage.removeItem("user");
    document.location.href = '/';
  }

  const settingsRef = React.useRef();
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
      >
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              Layout
            </Text>
            <HSeparator />
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">
              {/*
              <Box
                display={fixedDisplay}
                justifyContent="space-between "
                mb="16px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Navbar Fixed
                </Text>
                <Switch
                  colorScheme="yellow"
                  isChecked={switched}
                  onChange={() => {
                    if (switched === true) {
                      props.onSwitch(false);
                      setSwitched(false);
                    } else {
                      props.onSwitch(true);
                      setSwitched(true);
                    }
                  }}
                />
              </Box>
              */}
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="24px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Mørk/Lys
                </Text>
                <Button onClick={toggleColorMode}>
                  Skift til {colorMode === "light" ? "Mørk" : "Lys"}
                </Button>
              </Flex>
              <br/>
              <br/>
              <br/>
              
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="24px"
              >
               <Text fontSize="md" fontWeight="600" mb="4px">
                  &nbsp;
                </Text>
                <Button onClick={signOut}>
                  Logud
                </Button>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
