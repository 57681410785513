// Chakra imports
import { Select, Text, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import { useState } from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { useAuth } from "./../../../../auth-context/auth.context";

const OrdersDataSelector = ({
  updatefn,
  fromDate,
  toDate,
  status,
  customerId,
  customers,
  products,
  productId,
  showCustomerSelector,
  users,
  userId
}) => {


  const [selectedDates, setSelectedDates] = useState([new Date(fromDate), new Date(toDate)]);
  const [selectedUserId, setSelectedUserId] = useState(userId);
  const [selectedProductId, setSelectedProductId] = useState(productId);
  const [selectedCustomerId, setSelectedCustomerId] = useState(customerId);
  const [selectedStatus, setSelectedStatus] = useState(status);
  const userData = useAuth()

  const updateDates = (dates) => {
    if (dates.length === 2 && dates[1] != null) {
      const fromIsoDate = new Date(dates[0].getTime() - dates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(dates[1].getTime() - dates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];

      localStorage.setItem("order_selected_fromDate", fromIsoDate);
      localStorage.setItem("order_selected_toDate", toIsoDate);
      updatefn(fromIsoDate, toIsoDate, selectedCustomerId, status, selectedProductId, userId);

    }
  };

  const updateStatus = (event) => {

    if (selectedDates.length === 2 && selectedDates[1] != null) {
      const fromIsoDate = new Date(selectedDates[0].getTime() - selectedDates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - selectedDates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];


      let status = "";

      if (event.target.value !== "") {
        status = event.target.value;
      }
      setSelectedStatus(status);
      localStorage.setItem("order_selected_status", status);

      updatefn(fromIsoDate, toIsoDate, selectedCustomerId, status, selectedProductId, userId);
    }
  };


  /*
  const updateAffiliatePartnerProduct = (event) => {
    if (selectedDates.length === 2 && selectedDates[1] != null) {
      const fromIsoDate = new Date(selectedDates[0].getTime() - selectedDates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - selectedDates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];

      let products = [];
      if (event.target.value !== "") {
        if (event.target.value.indexOf(",") > -1) {
          products = event.target.value.split(",");
        } else {
          products.push(event.target.value);
        }
      }

      setSelectedAffiliatePartnerProducts(products);

      updatefn(fromIsoDate, toIsoDate, products, selectedRealEstateBrokers, selectedStatus);
    }
  };
  */

  const updateUser = (event) => {
    if (selectedDates.length === 2 && selectedDates[1] != null) {

      const fromIsoDate = new Date(selectedDates[0].getTime() - selectedDates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - selectedDates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      let userId = '';
      if (event.target.value !== "") {
        userId = event.target.value;
      }
      setSelectedUserId(userId);

      localStorage.setItem("order_selected_userId", userId);

      updatefn(fromIsoDate, toIsoDate, customerId, selectedStatus, selectedProductId, userId);
    }
  };

  const updateProduct = (event) => {
    if (selectedDates.length === 2 && selectedDates[1] != null) {

      const fromIsoDate = new Date(selectedDates[0].getTime() - selectedDates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - selectedDates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      let productId = '';
      if (event.target.value !== "") {
        productId = event.target.value;
      }
      setSelectedProductId(productId);

      localStorage.setItem("order_selected_productId", productId);

      updatefn(fromIsoDate, toIsoDate, customerId, selectedStatus, productId, selectedUserId);
    }
  };

  const updateCustomer = (event) => {
    if (selectedDates.length === 2 && selectedDates[1] != null) {
      const fromIsoDate = new Date(selectedDates[0].getTime() - selectedDates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - selectedDates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      let customerId = '';
      if (event.target.value !== "") {
        customerId = event.target.value;
      }
      setSelectedCustomerId(customerId);

      localStorage.setItem("order_selected_customerId", customerId);

      updatefn(fromIsoDate, toIsoDate, customerId, selectedStatus, selectedProductId, selectedUserId);
    }
  };



  return (
    <Card p="16px">
      <Grid templateColumns="repeat(6, 1fr)" gap={6} alignItems={'center'}>
        <GridItem>
          <Text>Vælg dato interval:</Text>
        </GridItem>
        <GridItem colSpan={5}>
          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={(e) => {
              setSelectedDates(e);
              updateDates(e);
            }}
            configs={{
              dateFormat: "dd-MM-yyyy",
            }}
            propsConfigs={{
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  borderColor: "yellow.300",
                  _hover: {
                    background: "yellow.400",
                  },
                },
                isInRangeBtnProps: {
                  background: "yellow.300",
                  color: "black",
                },
                selectedBtnProps: {
                  background: "yellow.300",
                  color: "black",
                },
              },
            }}
          />
        </GridItem>

        <GridItem>
          <Text>Vælg status:</Text>
        </GridItem>
        <GridItem colSpan={5}>
          <Select
            multiple={false}
            value={selectedStatus}
            onChange={(e) => {
              updateStatus(e);
            }}
          >
            <option key={"status_1"} value="ALL">
              Alle
            </option>
            <option key={"status_12"} value="RECEIVED">
              Ordren modtaget
            </option>
            <option key={"status_2"} value="SELLER_NOT_RESPONDING">
              Kontaktperson træffes ikke
            </option>
            <option key={"status_3"} value="AWAITING_RESPONSE_FROM_CONTACT_PERSON">
              Afventer svar fra kontaktperson
            </option>
            <option key={"status_4"} value="PUT_ON_HOLD">
              Ordren er sat i bero
            </option>
            <option key={"status_5"} value="FOTOSHOT_PLANED">
              Fotografering planlagt
            </option>
            <option key={"status_6"} value="SEASON_FOTOSHOT_PLANED">
              Seasonopdatering planlagt
            </option>
            <option key={"status_7"} value="WEATHER_UPDATE_FOTOSHOT_PLANED">
              Vejropdatering planlagt
            </option>
            <option key={"status_8"} value="BEING_PROCESSED">
              Ordren efterbehandles
            </option>
            <option key={"status_13"} value="PROCESSING_COMPLETED">
              Efterbehandling er afsluttet
            </option>
            <option key={"status_9"} value="DELIVERED">
              Ordren er leveret
            </option>
            <option key={"status_10"} value="INVOICED">
              Ordren er faktureret
            </option>
            <option key={"status_11"} value="CANCELED">
              Ordren er annulleret
            </option>
          </Select>
        </GridItem>
        {showCustomerSelector && (
          <>
            <GridItem>
              <Text>Vælg kunde:</Text>
            </GridItem>

            <GridItem colSpan={5}>
              <Select
                multiple={false}
                value={selectedCustomerId}
                onChange={(e) => {
                  updateCustomer(e);
                }}
              >
                <option key={"customers_all"} value="">
                  Alle
                </option>
                {customers.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </GridItem>
          </>
        )}

        <>
          <GridItem >
            <Text display={'flex'} >Vælg produkt:</Text>
          </GridItem>

          <GridItem colSpan={5}>
            <Select
              multiple={false}
              value={selectedProductId}
              onChange={(e) => {
                updateProduct(e);
              }}
            >
              <option key={"products_all"} value="">
                Alle
              </option>
              {products.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          </GridItem>

          {['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType) && (
            <>
              <GridItem >
                <Text display={'flex'} >Vælg fotograf:</Text>
              </GridItem>

              <GridItem colSpan={5}>
                <Select
                  multiple={false}
                  value={selectedUserId}
                  onChange={(e) => {
                    updateUser(e);
                  }}
                >
                  <option key={"users_all"} value="">
                    Alle
                  </option>
                  {users.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Select>
              </GridItem>
            </>
          )}
        </>
      </Grid>
    </Card>
  );
};
/*
<GridItem>
          <Text>Vælg partner:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Select multiple={false} value={selectedAffiliatePartner} onChange={(e) => { setSelectedAffiliatePartner(e.target.value); updateAffiliatePartner(e) }}>
            <option key={'affiliatePartners_all'} value=''>Alle</option>
            {affiliatePartners.map((option) => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </Select>
        </GridItem>
        */

export default OrdersDataSelector;
