import {
    Flex, Text, useColorModeValue, useToast,
    Icon,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Button, Tabs, TabList, Tab, TabPanels, TabPanel, Input, Grid
} from "@chakra-ui/react";
import Card from "./../Card/Card";
import CardBody from "./../Card/CardBody";
import CardHeader from "./../Card/CardHeader";
import { useEffect, useState, useRef } from "react";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import Editor from "./../Editor/Editor";


export enum messageViewType {
    ViewMessage = "ViewMessage",
    ReplyMessage = "ReplyMessage",
    SentMessage = "SentMessage"
}

export enum messageType {
    SUBCONTRACTOR_ORDER_CORRECTION = "SUBCONTRACTOR_ORDER_CORRECTION",
    NORMAL_MESSAGE = "NORMAL_MESSAGE"
}

export interface MessageModalProps {
    onClose: any
    isOpen: boolean
    view: messageViewType
    messageType: messageType
    messageObject: any
    subcontractorId?: string
    subcontractorOrderId?: string
}

const SENT_MESSAGE = gql`
  mutation sentInternalMessage($input: SentMessageInput!) {
    sentInternalMessage(input: $input) {
      id
    }
  }
`;

const MessageModal: React.FC<MessageModalProps> = (props) => {
    const [subjectValue, setSubjectValue] = useState('Korrektion af ordre');
    const [textValue, setTextValue] = useState('');
    const [sentMessage] = useMutation(SENT_MESSAGE);


    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();
    const contractorBoxColor = useColorModeValue("gray.200", "white");
    const bgPrevButton = useColorModeValue("gray.100", "gray.100");

    const getHeading = () => {
        switch (props.messageType) {
            case messageType.SUBCONTRACTOR_ORDER_CORRECTION:
                return "Korrektion af ordre";
            default:
                return "Besked";
        }
    }

    const updateSubject = (value: any) => {
        setSubjectValue(value);
    };

    const updateText = (text) => {
        if (text !== "") {
            setTextValue(text);
        }
    };

    const handleSentMessage = () => {
        // Send message
        sentMessage({
            variables: {
                input: {
                    messageSubject: subjectValue,
                    messageText: textValue,
                    messageSender: "PHOTOGRAPHY_COMPANY",
                    subcontractorId: props.subcontractorId,
                    subcontractorOrderId: props.subcontractorOrderId,
                    messageType: props.messageType
                }
            }
        }).then((res) => {
            toast({
                title: "Beskeden er sendt",
                description: "",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
            props.onClose();
        });
    }

    return (
        <Modal onClose={props.onClose} size="xl" isOpen={props.isOpen} >
            <ModalOverlay />
            <ModalContent maxH="800px" maxW="1000px" bg={'#f9f9f9'}>
                <ModalHeader>{getHeading}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Card>
                        <CardHeader mb="40px">
                            <Text
                                color={textColor}
                                fontSize="lg"
                                fontWeight="bold"
                                mb="3px"
                            >
                                Send besked
                            </Text>
                        </CardHeader>
                        <CardBody>
                            <Flex direction="column" w="100%">

                                <Flex align="center" mb="18px">
                                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                        Emne:{" "}
                                    </Text>
                                    <Input
                                        variant="flushed"
                                        type="text"
                                        name="mailSubject"
                                        defaultValue={subjectValue}
                                        onBlur={(e) =>
                                            updateSubject(e.target.value)
                                        }
                                    />
                                </Flex>
                                <Flex align="center" mb="18px">
                                    <Editor value={""} changed={(e) => (updateText(e))} showFields={false} />
                                </Flex>
                                <Flex justify="right" gap={'20px'}>
                                    <Button
                                        variant="no-hover"
                                        bg={bgPrevButton}
                                        alignSelf="flex-end"
                                        mt="24px"
                                        w="100px"
                                        h="35px"
                                        onClick={() => { props.onClose() }}
                                    >
                                        <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                            Luk
                                        </Text>
                                    </Button>
                                    <Button
                                        variant="no-hover"
                                        bg="#151928"
                                        alignSelf="flex-end"
                                        mt="24px"
                                        w="100px"
                                        h="35px"
                                        onClick={() => { handleSentMessage() }}
                                    >
                                        <Text fontSize="xs" color="#fff" fontWeight="bold">
                                            Send
                                        </Text>
                                    </Button>
                                </Flex>
                            </Flex>

                        </CardBody>
                    </Card>
                </ModalBody>
            </ModalContent >
        </Modal >
    );
};

export default MessageModal;