export const MwMediaDescriptions = [
        "Streetview",
        "1. sal",
        "1. sal med mål",
        "1. sal uden mål",
        "2. sal",
        "2. sal med mål",
        "2. sal uden mål",
        "3. sal",
        "3. sal med mål",
        "3. sal uden mål",
        "4. sal",
        "4. sal med mål",
        "4. sal uden mål",
        "5. sal",
        "5. sal med mål",
        "5. sal uden mål",
        "Affald",
        "Affaldsskakt",
        "Aftægtsbolig",
        "Aktivitetsrum",
        "Alkove",
        "Alrum",
        "Altan",
        "Andet",
        "Anneks",
        "Anneks med mål",
        "Anneks uden mål",
        "Arbejdsværelse",
        "Arkiv",
        "Atelier",
        "Atriumgård",
        "Auditorium",
        "Bad",
        "Badebro",
        "Badeværelse",
        "Badeværelse med spa",
        "Bag facade",
        "Baggang",
        "Baggård",
        "Baghave",
        "Balkon",
        "Bar",
        "Beliggenhed",
        "Bilværksted",
        "Boks",
        "Boligfilm",
        "Boligvideo",
        "Brus",
        "Brusebadeværelse",
        "Bryggers",
        "Brændeovn",
        "Brændeskur",
        "Brændsel",
        "Butik",
        "Butikslokale",
        "By",
        "Byggegrund",
        "Byggeret",
        "Børneværelse",
        "Café",
        "Carport",
        "Cellekontor",
        "Chefkontor",
        "Cykelskur",
        "Cykler",
        "Dagligstue",
        "Depot",
        "Diskotek",
        "Disponibel",
        "Disponibelt rum",
        "Dronefilm",
        "Dobbeltværelse",
        "Domicil",
        "Drivhus",
        "Ejendom 1",
        "Ejendom 2",
        "Ejendom 3",
        "Ejendom 4",
        "Ejendom 5",
        "Ejendommen",
        "Elevator",
        "Enkeltmandskontor",
        "Entré",
        "Erhverv",
        "Fabrik",
        "Facade",
        "Facade bolig",
        "Facade erhverv",
        "Facade havesiden",
        "Facade lang",
        "Facade side",
        "Festlokale",
        "Festsal",
        "Fitness lokale",
        "Fordelingsgang",
        "Forgang",
        "Forhave",
        "Forretning",
        "Forretningslokale",
        "Forrum",
        "Forsidebillede",
        "Forstue",
        "Fortrappe",
        "Forældresoveværelse",
        "Fransk altan",
        "Frokoststue",
        "Fyr",
        "Fyrrum",
        "Fælles",
        "Fælles areal",
        "Fælles indgang",
        "Fælles reception",
        "Fællesrum",
        "Gade",
        "Gang",
        "Gangbro",
        "Garage",
        "Garderobe",
        "Gavl",
        "Gildesal",
        "Gildestue",
        "Grillbar",
        "Grovkøkken",
        "Grund",
        "Gæstebadeværelse",
        "Gæstetoilet",
        "Gæsteværelse",
        "Gågade",
        "Gård",
        "Gårdareal",
        "Gårdhave",
        "Gårdmiljø",
        "Gårdsiden",
        "Gårdsplads",
        "Hal",
        "Hall",
        "Have",
        "Havudsigt",
        "Havestue",
        "Hems",
        "Hems",
        "Hesteboks",
        "Hestefold",
        "Hestesolarium",
        "Hobby",
        "Hobbyrum",
        "Hotel",
        "Hus",
        "Hvilerum",
        "Højlager",
        "Ikke udgravet",
        "Ildsted",
        "Illustration",
        "Introfilm",
        "Indendørs",
        "Indendørs swimmingpool",
        "Indgang",
        "Indgang erhverv",
        "Indgangsparti",
        "Indkørsel",
        "Institution",
        "Kammer",
        "Kantine",
        "Karbadeværelse",
        "Kedelrum",
        "Klinik",
        "Konferencerum",
        "Kontor",
        "Kontor m møderum",
        "Kopirum",
        "Kort",
        "Krybekælder",
        "Kursuslokale",
        "Kvist",
        "Kvistværelse",
        "Kælder",
        "Kælder med mål",
        "Kælder uden mål",
        "Kælderplan",
        "Køjerum",
        "Køkken",
        "Køkken 1. sal",
        "Køkken alrum",
        "Køl",
        "Lade",
        "Lager",
        "Lagerhal",
        "Lagerrum",
        "Ledporte",
        "Loft",
        "Lokation",
        "Luftfoto",
        "Manøvrerum",
        "Marker",
        "Maskinhus",
        "Mastefoto",
        "Masterbedroom",
        "Matrikelkort",
        "Med mål",
        "Mellemgang",
        "Modelfoto",
        "Motionsrum",
        "Møderum",
        "Omgivelser",
        "Omklædning",
        "Område",
        "Opgang",
        "Opholdsrum",
        "Opholdsstue",
        "Oplagsplads",
        "Opmagasinering",
        "Orangeri",
        "Overdækket",
        "Overdækket indgang",
        "Overdækket terrasse",
        "Overetage",
        "Ovn",
        "P pladser",
        "Panoramaudsigt",
        "Park",
        "Parkering",
        "Parkeringsareal",
        "Parkeringsforhold",
        "Parkeringsplads",
        "Pejs",
        "Pejsestue",
        "Pergola",
        "Personalefaciliteter",
        "Plantegning",
        "Pool",
        "Poolrum",
        "Port",
        "Port facade",
        "Port indgang",
        "Port indkørsel",
        "Portræt",
        "Produktionslokale",
        "Projekt",
        "Pult",
        "Pulterrum",
        "Påklædningsværelse",
        "Rampe",
        "Reception",
        "Redskabsrum",
        "Repos",
        "Restaurant",
        "Ridebane",
        "Ridehal",
        "Rum",
        "Rytterstue",
        "Sadelrum",
        "Salon",
        "Salgsareal",
        "Sauna",
        "Serverrum",
        "Set fra haven",
        "Set fra vejen",
        "Showroom",
        "Skab",
        "Skabe",
        "Skabsgang",
        "Skabsrum",
        "Skole",
        "Skov",
        "Skrald",
        "Skunk",
        "Skur",
        "Sluseporte",
        "Solgård",
        "Soveværelse",
        "Spa",
        "Spisekammer",
        "Spisekrog",
        "Spisekøkken",
        "Spiseplads",
        "Spisestue",
        "Stald",
        "Standard indendørs",
        "Standard udendørs",
        "Storrumskontor",
        "Strand",
        "Strandgrund",
        "Stue",
        "Stue ensuite",
        "Stue m. karnap",
        "Stueetage",
        "Stueetage med mål",
        "Stueetage uden mål",
        "Stueplan",
        "Svalegang",
        "Swimmingpool",
        "Søudsigt",
        "Tagetage",
        "Tagterrasse",
        "Teknik",
        "Terrasse",
        "Thekøkken",
        "Til salgsopstilling",
        "Tilbygning",
        "Tilbygning med mål",
        "Tilbygning uden mål",
        "Toilet",
        "Trappe",
        "Trappeopgang",
        "Trapperum",
        "Træningslokale",
        "TV stue",
        "Tårn",
        "Tørrerum",
        "Uddannelseslokale",
        "Udekøkken",
        "Uden mål",
        "Udendørs",
        "Udendørsareal",
        "Udendørsbokse",
        "Udenomsarealer",
        "Udestue",
        "Udhus",
        "Udhus med mål",
        "Udhus uden mål",
        "Udsigt",
        "Udsigt fra toppen",
        "Udsigt til dam",
        "Udsigt til sø",
        "Udstillingsvinduer",
        "Udstykningsforslag",
        "Underetage",
        "Undervisningslokale",
        "Uudnyttet",
        "Uudnyttet loft",
        "Varmerum",
        "Vask",
        "Vaskerum",
        "Veranda",
        "Video",
        "Viktualierum",
        "Vindfang",
        "Vinkælder",
        "Vinterhave",
        "Vognly",
        "Værelse",
        "Værksted",
        "Walker",
        "Walkin closet",
        "WC",
        "Nederste plan",
        "Øverste plan",
        "Alle plan",
        "SoMe Film"
    ]
  