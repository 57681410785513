// Chakra imports
import { Flex, Text, useColorModeValue, Textarea, useToast } from "@chakra-ui/react";
// Custom components
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";
import React from "react";

const updateOrderGql = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

export enum NoteType {
  note = "note", // eslint-disable-line
  internalNote = "internalNote", // eslint-disable-line
}

interface IProps {
  data: any
  dataChanged: any
  heading: String
  noteType: NoteType
}

const OrderNotes: React.FC<IProps> = ({ data, dataChanged, heading, noteType }) => {
  const toast = useToast();
  const [updateOrder] = useMutation(updateOrderGql);
  const [currentNoteText, setCurrentNoteText] = React.useState("");
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");


  const handleNoteFocus = (event: any) => {
    const currentText = event.target.value;
    setCurrentNoteText(currentText);
  };

  const updateData = (orderId, field, data) => {

    if (data === currentNoteText) {
      console.log('Note text has not changed');
      return;
    }

    data = data.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = orderId;

    updateOrder({ variables: { input: inputData } }).then(() => {
      dataChanged();
      if (noteType === NoteType.note) {
        toast({
          title: "Ændringen er blevet gemt og der er sendt en besked til mægler.",
          description: "",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        })
      }
    })
      .catch(() => {
        toast({
          title: "Opdatering af ordren fejlede.",
          description: "Det var ikke muligt at opdatere noten.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });

  };

  const addLinebreaks = (text: String) => {
    return text.replaceAll("\\n", "\n");
  };

  const defaultNote = noteType === NoteType.note ? data.note : data.internalNote;

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} style={{height: '100%'}}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold"  >
          {heading}
        </Text>
      </CardHeader>
      <CardBody px="5px" height={"100%"}>
        <Flex direction="column" style={{ width: "100%", height: "100%" }}>
          <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"}>
            <Textarea
              onFocus={handleNoteFocus}
              height={"100%"}
              name={noteType} // Add missing declaration for fieldName
              defaultValue={addLinebreaks(defaultNote)}
              onBlur={(e) => updateData(data.id, noteType, e.target.value)}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card >
  );
};

export default OrderNotes;
