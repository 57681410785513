// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
} from "@chakra-ui/react";
// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import React, { useRef, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { MdDeleteOutline, MdEditNote } from "react-icons/md";

const createConditionGql = gql`
  mutation createCondition($input: CreateConditionInput!) {
    createCondition(input: $input) {
      id
    }
  }
`;

const deleteConditionGql = gql`
  mutation deleteCondition($id: ID!) {
    deleteCondition(id: $id)
  }
`;

const updateConditionGql = gql`
  mutation updateCondition($input: UpdateConditionInput!) {
    updateCondition(input: $input) {
      id
    }
  }
`;

export enum conditionType { // eslint-disable-line
  Product = "Product", // eslint-disable-line
  ProductCategory = "ProductCategory", // eslint-disable-line
}

interface IProps {
  conditions: any[]
  conditionFields: any[]
  forceRerender: any
  type: conditionType
  refId: String
}

const ConditionList: React.FC<IProps> = ({ conditions, conditionFields, forceRerender, type, refId }) => {

  const toast = useToast();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAddConditionDialogOpen, onOpen: onAddConditionDialogOpen, onClose: onAddConditionDialogClose } = useDisclosure();

  const cancelRef = React.useRef();
  const [createCondition] = useMutation(createConditionGql);
  const [deleteCondition] = useMutation(deleteConditionGql);
  const [updateCondition] = useMutation(updateConditionGql);

  const [operators, setOperators] = useState([]);
  const [selectedField, setSelectedField] = useState(conditionFields[0]);
  const [selectedCondition, setSelectedCondition] = useState<any>();
  const [fieldValues, setFieldValues] = useState([]);
  const [numberInputValue, setNumberInputValue] = useState(0);
  const [stringInputValue, setStringInputValue] = useState("");

  const inputOperatorSelectRef = useRef(null);
  const inputValueSelectRef = useRef(null);
  const inputValueNumberRef = useRef(null);
  const inputValueStringRef = useRef(null);

  const textColor = useColorModeValue("gray.700", "white");

  //const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const addCommas = (num) => num.toString();
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
  const handleNumberInputChange = (event) => setNumberInputValue(addCommas(removeNonNumeric(event.target.value)));

  const handleAddConditionClicked = () => {
    onAddConditionDialogOpen();
  };

  if (type !== conditionType.Product){
    conditionFields = conditionFields.filter((conditionField) => conditionField.name.indexOf('Produkt - '))
  }

  const onAddConditionClicked = () => {
    if (selectedField) {
      let selectedValue = "";
      switch (selectedField.fieldType) {
        case "STRING":
          selectedValue = inputValueStringRef.current.value;
          break;
        case "NUMBER":
          selectedValue = inputValueNumberRef.current.value;
          break;
        case "BOOLEAN":
          selectedValue = inputValueSelectRef.current.value;
          break;
        case "ENUM":
          selectedValue = inputValueSelectRef.current.value;
          break;
        default:
          break;
      }

      if (selectedCondition) {
        const inputData = {
          id: selectedCondition.id,
          conditionType: "DATA_FIELD",
          fieldId: selectedField.id,
          operator: inputOperatorSelectRef.current.value,
          value: selectedValue,
        };

        updateCondition({ variables: { input: inputData } });
      } else {
        let inputData = {
          conditionType: "DATA_FIELD",
          fieldId: selectedField.id,
          operator: inputOperatorSelectRef.current.value,
          value: selectedValue,
        } as any;

        switch (type) {
          case conditionType.ProductCategory:
            inputData.productCategoryId = refId;
            break;
          case conditionType.Product:
            inputData.productId = refId;
            break;
          default:
            break;
        }

        createCondition({ variables: { input: inputData } });
      }

      onAddConditionDialogClose();

      toast({
        title: "Ændringen er blevet gemt.",
        description: "",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      setSelectedCondition(null);

      setTimeout(() => {
        forceRerender();
      }, 1000);
    }
  };

  const handleDeleteClick = (currentConditionId) => {
    for (let f = 0; f < conditions.length; f++) {
      if (currentConditionId === conditions[f].id) {
        setSelectedCondition(conditions[f]);
      }
    }
    onOpen();
  };

  const handleEditClick = (currentConditionId) => {
    for (let f = 0; f < conditions.length; f++) {
      if (currentConditionId === conditions[f].id) {
        setSelectedCondition(conditions[f]);
        updateSelects(conditions[f].field.id);

        if (conditions[f].field.fieldType === "NUMBER") {
          setNumberInputValue(conditions[f].value);
        } else {
          setStringInputValue(conditions[f].value);
        }
      }
    }

    onAddConditionDialogOpen();
  };

  const updateSelects = (currentFieldId) => {
    if (currentFieldId === "") {
      return false;
    }
    for (let f = 0; f < conditionFields.length; f++) {
      if (currentFieldId === conditionFields[f].id) {
        const field = conditionFields[f];
        setSelectedField(field);
        let operators = [];
        let fieldValues = [];
        switch (field.fieldType) {
          case "STRING":
            operators = [
              {
                id: "IS_EQUAL_TO",
                name: "er lig med",
              },
              {
                id: "IS_NOT_EQUAL_TO",
                name: "er ikke lig med",
              },
              {
                id: "CONTAINS",
                name: "indeholder",
              },
              {
                id: "DO_NOT_CONTAIN",
                name: "indeholder ikke",
              },
            ];
            break;
          case "NUMBER":
            operators = [
              {
                id: "IS_EQUAL_TO",
                name: "er lig med",
              },
              {
                id: "IS_NOT_EQUAL_TO",
                name: "er ikke lig med",
              },
              {
                id: "IS_LESS_THAN",
                name: "er mindre end",
              },
              {
                id: "IS_LESS_THAN_OR_EQUAL_TO",
                name: "er mindre end eller lig med",
              },
              {
                id: "IS_GREATER_THAN",
                name: "er større end",
              },
              {
                id: "IS_GREATER_OR_EQUAL_TO",
                name: "er større end eller lig med",
              },
            ];
            break;
          case "BOOLEAN":
            operators = [
              {
                id: "IS_EQUAL_TO",
                name: "er lig med",
              },
              {
                id: "IS_NOT_EQUAL_TO",
                name: "er ikke lig med",
              },
            ];
            fieldValues = [
              {
                id: "true",
                name: "Ja",
              },
              {
                id: "false",
                name: "Nej",
              },
            ];

            break;
          case "DATE":
            operators = [
              {
                id: "IS_EQUAL_TO",
                name: "er lig med",
              },
              {
                id: "IS_NOT_EQUAL_TO",
                name: "er ikke lig med",
              },
              {
                id: "IS_LESS_THAN",
                name: "er mindre end",
              },
              {
                id: "IS_LESS_THAN_OR_EQUAL_TO",
                name: "er mindre end eller lig med",
              },
              {
                id: "IS_GREATER_THAN",
                name: "er større end",
              },
              {
                id: "IS_GREATER_OR_EQUAL_TO",
                name: "er større end eller lig med",
              },
            ];
            break;
          case "ENUM":
            operators = [
              {
                id: "IS_EQUAL_TO",
                name: "er lig med",
              },
              {
                id: "IS_NOT_EQUAL_TO",
                name: "er ikke lig med",
              },
            ];
            const DatafieldValues = field.values.split(",");

            for (let e = 0; e < DatafieldValues.length; e++) {
              const obj = {
                id: DatafieldValues[e],
                name: DatafieldValues[e],
              };

              fieldValues.push(obj);
            }

            break;
          default:
            operators = [];
            break;
        }

        setOperators(operators);
        setFieldValues(fieldValues);
      }
    }
  };

  const deleteConditionClicked = () => {
    onClose();
    deleteCondition({ variables: { id: selectedCondition.id } });

    setTimeout(() => {
      forceRerender();
    }, 200);
    setSelectedCondition(null);
  };

  const onConditionModalClose = () => {
    onAddConditionDialogClose();
    setSelectedCondition(null);
    setStringInputValue("");
    setNumberInputValue(0);
  };

  return (
    <Card p="16px">
      <CardHeader style={{ justifyContent: "space-between" }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Betingelser for visning
        </Text>
        <Text fontSize="12px" fontWeight="bold" cursor="pointer" onClick={() => handleAddConditionClicked()}>
          Tilføj
        </Text>
      </CardHeader>

      <CardBody w="100%" pt="28px" style={{ flexDirection: "column" }}>
        {conditions.length > 0 && (
          <Table>
            <Thead>
              <Tr>
                <Th style={{ color: "var(--chakra-colors-gray-400)" }}>BETINGELSE</Th>
                <Th style={{ color: "var(--chakra-colors-gray-400)", textAlign: "center", width: "114px" }}>REDIGER</Th>
                <Th style={{ color: "var(--chakra-colors-gray-400)", textAlign: "center", width: "114px" }}>SLET</Th>
              </Tr>
            </Thead>
            <Tbody>
              {conditions.map((condition) => (
                <Tr key={condition.id}>
                  <Td onClick={() => handleEditClick(condition.id)}>
                    <Flex align="center">
                      <Flex direction="column" cursor="pointer">
                        <Text fontSize="sm">{condition.field.name}</Text>
                      </Flex>
                      <Flex direction="column" cursor="pointer" pl="5px" pr="5px">
                        <Text fontSize="sm" fontWeight="bold">
                          {t(condition.operator)}
                        </Text>
                      </Flex>
                      <Flex direction="column" cursor="pointer">
                        {condition.field.fieldType === "NUMBER" ? (
                          <Text fontSize="sm">{t(condition.value)}</Text>
                        ) : (
                          <Text fontSize="sm">{t(condition.value)}</Text>
                        )}
                      </Flex>
                    </Flex>
                  </Td>
                  <Td style={{ color: "var(--chakra-colors-gray-400)", textAlign: "center" }}>
                    <Flex direction="column" cursor="pointer">
                      <Text fontSize="sm">
                        <Icon
                          as={MdEditNote}
                          w={{ sm: "16px", md: "16px" }}
                          h={{ sm: "16px", md: "16px" }}
                          color="gray.500"
                          onClick={() => handleEditClick(condition.id)}
                        ></Icon>
                      </Text>
                    </Flex>
                  </Td>
                  <Td style={{ color: "var(--chakra-colors-gray-400)", textAlign: "center" }}>
                    <Flex direction="column" cursor="pointer">
                      <Text fontSize="sm">
                        <Icon
                          as={MdDeleteOutline}
                          w={{ sm: "16px", md: "16px" }}
                          h={{ sm: "16px", md: "16px" }}
                          color="gray.500"
                          onClick={() => handleDeleteClick(condition.id)}
                        ></Icon>
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </CardBody>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={deleteConditionClicked}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet betingelse
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteConditionClicked} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isAddConditionDialogOpen} onClose={onConditionModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Opret ny betingelse</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Du er ved at oprettet en ny betingelse, udfyld nedenstående og klik opret.</Text>
            <br />
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Felt:{" "}
                </Text>
                <Select
                  multiple={false}
                  onChange={(e) => {
                    updateSelects(e.target.value);
                  }}
                >
                  <option value="">Vælg felt</option>
                  {conditionFields.map((option) => {
                    if (selectedCondition) {
                      if (option.id === selectedCondition.field.id) {
                        return (
                          <option key={option.id} selected value={option.id}>
                            {option.name}
                          </option>
                        );
                      } else {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      );
                    }
                  })}
                </Select>
              </Flex>
            </Flex>
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Operatør:{" "}
                </Text>
                <Select multiple={false} ref={inputOperatorSelectRef}>
                  {operators.map((option) => {
                    if (selectedCondition) {
                      if (option.id === selectedCondition.operator) {
                        return (
                          <option key={option.id} selected value={option.id}>
                            {option.name}
                          </option>
                        );
                      } else {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      );
                    }
                  })}
                </Select>
              </Flex>
            </Flex>
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Værdi:{" "}
                </Text>

                {(selectedField.fieldType === "ENUM" || selectedField.fieldType === "BOOLEAN") && (
                  <Select
                    ref={inputValueSelectRef}
                    multiple={false}
                    onChange={(e) => {
                      updateSelects(e.target.value);
                    }}
                  >
                    {fieldValues.map((option) => {
                      if (selectedCondition) {
                        if (selectedCondition.value === option.id) {
                          return (
                            <option key={option.id} selected value={option.id}>
                              {t(option.name)}
                            </option>
                          );
                        } else {
                          return (
                            <option key={option.id} value={option.id}>
                              {t(option.name)}
                            </option>
                          );
                        }
                      } else {
                        return (
                          <option key={option.id} value={option.id}>
                            {t(option.name)}
                          </option>
                        );
                      }
                    })}
                  </Select>
                )}

                {selectedField.fieldType === "STRING" && (
                  <Input variant="flushed" defaultValue={stringInputValue} onChange={() => {}} type="text" name="value" ref={inputValueStringRef} />
                )}

                {selectedField.fieldType === "NUMBER" && (
                  <Input
                    variant="flushed"
                    type="text"
                    value={numberInputValue}
                    onChange={handleNumberInputChange}
                    name="value"
                    ref={inputValueNumberRef}
                    style={{ textAlign: "end" }}
                  />
                )}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onConditionModalClose}>Fortryd</Button>
            <Button colorScheme="green" onClick={onAddConditionClicked} ml={3}>
              {selectedCondition ? <div>Gem</div> : <div>Opret</div>}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default ConditionList;
