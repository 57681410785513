// Chakra imports
import {
  Flex, Icon, Stack, Grid, GridItem, useColorModeValue, Text, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Input,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Select
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import { ClockIcon, CartIcon, WalletIcon, DocumentIcon } from "./../../../components/Icons/Icons";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
// assets

// Custom icons
import EventCalendar from "./../../../components/Calendars/EventCalendar";
import Card from "./../../../components/Card/Card.js";
import CardBody from "./../../../components/Card/CardBody.js";
import CardHeader from "./../../../components/Card/CardHeader.js";
import IconBox from "./../../../components/Icons/IconBox";
import moment from "moment";
import MiniStatistics from "./components/MiniStatistics";
import LeadprMonth from "./components/LeadprMonth";
import { signOut } from "../../../auth-context/authHelper";
import OrderAddress from "./../Orders/components/orderAddress"
import OrderHeader from "./../Orders/components/orderHeader"
import OrderSentMessage, { MessageType } from "./../Orders/components/orderSentMessage"
import QRCode from 'qrcode'
import { useAuth } from "../../../auth-context/auth.context";
import OrderInfo from "../Orders/components/orderInfo";
import OrderSeller from "../Orders/components/orderSeller";
import OrderNotes, { NoteType } from "../Orders/components/orderNotes";
import { useHistory } from "react-router-dom";
import OrderCustomer from "../Orders/components/orderCustomer";

const UPDATE_CALENDER_ITEM_GQL = gql`
mutation updateCalenderItem($input: UpdateCalenderItemsInput!) {
  updateCalenderItem(input: $input) {
    id
  }
}
`;

const DELETE_CALENDER_ITEM_GQL = gql`
mutation deleteCalenderItem($id: ID! ) {
  deleteCalenderItem(id: $id)
}`

const CREATE_CALENDER_ITEM_GQL = gql`
  mutation createCalenderItem($input: CreateCalenderItemsInput!) {
    createCalenderItem(input: $input) {
      id
    }
  }
`;

const GET_DASHBOARD_INFO = gql`
  query GET_DASHBOARD_INFO($input: GetCalenderItemsInput!, $getOrdersinput: GetOrdersByUserInput!, $orderId: ID!, $includeOrder: Boolean!) {
    turnoverCurrentMonth
    newLeadsToDay
    leadsReceivedPrMonth {
      month
      count
    }
    getCalenderItems(input: $input){
      id
      start
      end
      title
      user {
        id
        name
        calenderColor
      }
      description
      eventType
      order {
        id
        estateZipCode
        estateCity
      }
    }
    getOrdersByUser(input: $getOrdersinput){
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      createdAt
    }
    users {
      id
      name
      userType
      calenderColor
    }
    order: getOrder(id: $orderId) @include(if: $includeOrder) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      estateType
      estateFloorCount
      estateRoomCount
      estateLivingArea
      estateBusinessArea
      sellerName
      sellerPhone
      sellerEmail
      sellerType
      estateAgentName
      estateAgentEmail
      estateAgentPhone
      note
      internalNote
      totalPrice
      deliveryDate
      photeshotEvent {
        id
        start
        end
      }
      products {
        id
        name
        description
      }
      customer {
        id 
        name
        address
        zipCode
        city
        email
        phoneNo
      }
      assignedUser {
        id
        name
      }
      authorOrder {
        id
        subcontractor {
          id
          name
          subcontractorType
        }
        orderUiUrl
        status
      }
      
      status
      createdAt
      updatedAt
    }
  }
`;
//const GET_DASHBOARD_INFO = gqlnewLocal;

const AppointmentObj = {
  id: '',
  startDate: '',
  startTime: '12:00',
  endDate: '',
  endTime: '14:00',
  orderId: '',
  title: '',
  description: '',
  addressLink: '',
  mailHeader: '',
  mailText: '',
  userId: '',
  eventType: 'PHOTOSHOT'
}

const Dashboard = () => {
  const { isOpen: calenderCreateNewIsOpen, onOpen: calenderCreateNewOnOpen, onClose: calenderCreateNewOnClose } = useDisclosure();
  const { isOpen: downloadCalsIsOpen, onOpen: downloadCalsOnOpen, onClose: downloadCalsOnClose } = useDisclosure();

  const [createCalenderItem] = useMutation(CREATE_CALENDER_ITEM_GQL);
  const [updateCalenderItem] = useMutation(UPDATE_CALENDER_ITEM_GQL);
  const [deleteCalenderItem] = useMutation(DELETE_CALENDER_ITEM_GQL);
  const userData = useAuth()
  const history = useHistory();
  const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
  const deleteWarningCancelRef = React.useRef < HTMLButtonElement > (null); // eslint-disable-line
  const { paramsOrderId } = useParams();
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [calsUrl, setCalsUrl] = useState("");
  const [updateState, setUpdateState] = useState('');

  const [scrollPosition, setScrollPosition] = useState(0);
  //const [calenderItemType, setCalenderItemType] = useState('PHOTOSHOT');

  // let OnlyMyCalenderItems = localStorage.getItem("dashboard_showOnlyMyItemCalenderData") ? localStorage.getItem("dashboard_showOnlyMyItemCalenderData") === 'true' : true;
  // const [showOnlyMyItemCalenderData, setShowOnlyMyItemCalenderData] = useState(OnlyMyCalenderItems);

  let CalenderSelectedUser = localStorage.getItem("dashboard_calenderSelectedUser") ? localStorage.getItem("dashboard_calenderSelectedUser") : '0';
  const [calenderSelectedUser, setCalenderSelectedUser] = useState(CalenderSelectedUser);



  const [currentAppointment, setCurrentAppointment] = useState(AppointmentObj);
  const [showSentMessage, setShowSentMessage] = useState(false);
  const textColor = useColorModeValue("gray.700", "white");
  const iconTeal = useColorModeValue("gray.400", "gray.400");

  const toast = useToast();

  //current date minus 3 months
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 3);
  fromDate.setDate(1);

  //current date plus 3 months
  const toDate = new Date();
  toDate.setMonth(toDate.getMonth() + 3);
  toDate.setDate(1);

  const { loading, error, data, refetch } = useQuery(GET_DASHBOARD_INFO, {
    variables: {
      input: {
        userId: localStorage.getItem("system_user_id"),
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
      },

      orderId: paramsOrderId ? paramsOrderId : 0,
      includeOrder: !!paramsOrderId,
      getOrdersinput: {
        userId: localStorage.getItem("system_user_id"),
      }
    },
    manual: true,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  },
  );



  useEffect(() => {
    //set scroll position to buttom of page
    window.scrollTo(0, scrollPosition);

  }, [data]);

  if (loading) {
    return <p></p>; //loading
  }
  if (error) {
    if (error.message.includes('401')) {
      signOut();
    }
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }
  if (!data) {
    return <p>Error getting stats from server (2)</p>;
  }



  //Orders not planned
  let notPlanedOrders = data.getOrdersByUser.map((order) => {
    if (['RECEIVED'].includes(order.status)) {
      return {
        id: order.id,
        address: order.estateAddress + ", " + order.estateZipCode + ' ' + order.estateCity,
        caseNo: order.caseNo,
        addressLink: '/#/admin/order/' + order.id + '/planning'
      }

    } else {
      return null;
    }
  });
  notPlanedOrders = notPlanedOrders.filter(order => order != null)

  //new orders
  let orderCount = 0;
  let newOrders = notPlanedOrders.map((order) => {
    //if (orderCount < 4) {
      orderCount++
      return {
        id: order.id,
        address: order.address,
        caseNo: order.caseNo,
        addressLink: '/#/admin/order/' + order.id + '/planning'
      }
    //} else {
    //  return null;
    //}
  });
  newOrders = newOrders.filter(order => order != null)

  //Calender data
  const handleUserChanged = (value) => {
    setCalenderSelectedUser(value);
    localStorage.setItem("dashboard_calenderSelectedUser", value);

    console.log('handleUserChanged:', value)
  }

  let calenderData = data.getCalenderItems.map((x) => {
    let itemClass = 'calenderItem calenderItem_' + x.user.calenderColor

    let orderId = '';
    if (x.order) {
      orderId = x.order.id;
    }
    if (x.order && paramsOrderId === x.order.id) {
      itemClass = 'currentOrder'
    }


    let title = x.title;
    if (x.eventType === 'SEASON_FOTOSHOT' || x.eventType === 'PHOTOSHOT' || x.eventType === 'WEATHER_UPDATE') {
      //fix for calender title (line break and zip code)
      if (!x.title.includes(x.order.estateZipCode)){
        title = x.title.replace(' - ', '\n\r') + '\n\r' + x.order.estateZipCode + ' ' + x.order.estateCity;
      }
    }

    return {
      id: x.id,
      orderId: orderId,
      userId: x.user.id,
      title: title,
      status: "A",
      description: '',
      start: x.start,
      end: x.end,
      eventType: x.eventType,
      className: itemClass,
    }
  });

  if (calenderSelectedUser !== '0') {
    calenderData = calenderData.filter((x) => x.userId === calenderSelectedUser);
  }

  //fix to make sure the button is rendered
  setTimeout(() => {
    if (['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType)) {
      //renderEventFilterButton()
    } else {
      let button = document.querySelectorAll('button[title="Vis mine aftaler"]');
      if (button.length > 0) {
        button[0].style.display = 'none';
      }
    }

    //fix for height of calender
    try {
      document.getElementsByClassName('fc-theme-standard')[0].parentElement.parentElement.parentElement.style.height = (document.getElementsByClassName('fc-scroller-liquid-absolute')[0].scrollHeight + 170) + 'px';
    }catch(e) {
      console.log('Error:', e)
    }
  }, 100);

  /*
  let count = 0;
  let upCommingEvents = data.getCalenderItems.map((x) => {

    const today = new Date();
    if (count < 4 && new Date(x.start) > today) {
      if (x.eventType === 'OTHER') {
        return null;
      }
      const startDate = new Date(x.start)
      count++;
      return {
        id: x.id,
        orderId: x.order.id,
        title: x.title,
        startDate: x.start.substr(0, 10),
        startTime: String(startDate.getHours()).padStart(2, '0') + ":" + String(startDate.getMinutes()).padStart(2, '0'),
        addressLink: '/#/admin/order/' + x.order.id + '/planning'
      }

    } else {
      return null;
    }
  });

  upCommingEvents = upCommingEvents.filter(event => event != null)
  */

  let followUpEvents = data.getOrdersByUser.map((x) => {
    //only orders with status SELLER_NOT_RESPONDING, AWAITING_RESPONSE_FROM_CONTACT_PERSON and PROCESSING_COMPLETED
    if (['SELLER_NOT_RESPONDING', 'AWAITING_RESPONSE_FROM_CONTACT_PERSON'].includes(x.status)) {

      let status = 'Kontaktperson træffes ikke';
      if (x.status === 'AWAITING_RESPONSE_FROM_CONTACT_PERSON') {
        status = 'Afventer svar fra kontaktperson';
      }
      
      
      return {
        id: x.id,
        address: x.estateAddress + ", " + x.estateZipCode + ' ' + x.estateCity,
        caseNo: x.caseNo,
        title: x.caseNo + ' - ' + x.estateAddress,
        addressLink: '/#/admin/order/' + x.id + '/planning',
        status: status
      }
    } else {
      return null;
    }
  });

  followUpEvents = followUpEvents.filter(event => event != null)


  let processingCompletedEvents = data.getOrdersByUser.map((x) => {
    
    if (['PROCESSING_COMPLETED'].includes(x.status)) {

      let status = '';
      if (x.status === 'PROCESSING_COMPLETED') {
        status = 'Efterbehandling er afsluttet';
      }
      
      return {
        id: x.id,
        address: x.estateAddress + ", " + x.estateZipCode + ' ' + x.estateCity,
        caseNo: x.caseNo,
        title: x.caseNo + ' - ' + x.estateAddress,
        addressLink: '/#/admin/order/' + x.id + '/planning',
        status: status
      }
    } else {
      return null;
    }
  });

  processingCompletedEvents = processingCompletedEvents.filter(event => event != null)



  let putOnHoldEvents = data.getOrdersByUser.map((x) => {
    //only orders with status SELLER_NOT_RESPONDING, AWAITING_RESPONSE_FROM_CONTACT_PERSON and PROCESSING_COMPLETED
    if (['PUT_ON_HOLD'].includes(x.status)) {

      let status = '';
      if (x.status === 'PUT_ON_HOLD') {
        status = 'Ordren er sat i bero';
      }
      
      return {
        id: x.id,
        address: x.estateAddress + ", " + x.estateZipCode + ' ' + x.estateCity,
        caseNo: x.caseNo,
        title: x.caseNo + ' - ' + x.estateAddress,
        addressLink: '/#/admin/order/' + x.id + '/planning',
        status: status
      }
    } else {
      return null;
    }
  });

  putOnHoldEvents = putOnHoldEvents.filter(event => event != null)

  const updateNewAppointmentData = (field, data) => {

    if (field === 'startDate') {
      AppointmentObj['endDate'] = data;
    }

    AppointmentObj[field] = data;
    setCurrentAppointment({ ...AppointmentObj })
  }

  const deleteCalenderItemOnServer = () => {
    deleteCalenderItem({ variables: { id: AppointmentObj.id } }).then(() => {
      deleteWarningOnClose();
      calenderCreateNewOnClose();
      refetch();
    });
  }

  const handleNewAppointmentOrderChange = (value, item) => {
    AppointmentObj.orderId = value;
    AppointmentObj.title = item.label;
    setCurrentAppointment(AppointmentObj)
  }

  const closeSentMessage = () => {
    setShowSentMessage(false);
  }


  const handleCreateUpdateAppointmentOnServer = () => {
    let startDate = new Date(AppointmentObj.startDate)
    setScrollPosition(window.pageYOffset)

    if (AppointmentObj.eventType !== 'OTHER' && AppointmentObj.orderId === '') {
      toast({
        title: "Der er ikke valgt en ordre.",
        description: "",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    startDate.setHours(AppointmentObj.startTime.split(':')[0])
    startDate.setMinutes(AppointmentObj.startTime.split(':')[1])

    let endDate = new Date(AppointmentObj.endDate)
    endDate.setHours(AppointmentObj.endTime.split(':')[0])
    endDate.setMinutes(AppointmentObj.endTime.split(':')[1])

    let inputData = {};

    inputData.start = startDate
    inputData.end = endDate
    inputData.title = AppointmentObj.title
    inputData.description = ''
    inputData.eventType = AppointmentObj.eventType
    inputData.sentMail = false
    inputData.mailHeader = ''
    inputData.mailContent = ''
    inputData.userId = AppointmentObj.userId;

    

    if (AppointmentObj.id !== '') {
      inputData.id = AppointmentObj.id;

      updateCalenderItem({ variables: { input: inputData } }).then(() => {
        
        refetch();

        setUpdateState(new Date().getTime());
        setShowSentMessage(true);  
  

        //dataChanged();
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        calenderCreateNewOnClose();

      })
        .catch(() => {
          toast({
            title: "Opdatering af ordren fejlede.",
            description: "Det var ikke muligt at opdatere ordren.",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });
    } else {

      console.log('AppointmentObj userId:', AppointmentObj.userId)
      // inputData.userId = AppointmentObj.userId; // localStorage.getItem("system_user_id")
      inputData.orderId = AppointmentObj.orderId;

      createCalenderItem({ variables: { input: inputData } }).then(() => {
        
        refetch();
        
        //setTimeout(() => {
          setUpdateState(new Date().getTime());
          setShowSentMessage(true);  
        //}, 100);
        
        
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        calenderCreateNewOnClose();
      })
        .catch(() => {
          toast({
            title: "Opdatering af ordren fejlede.",
            description: "Det var ikke muligt at opdatere ordren.",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });
    }
  }

  const handleCreateNewAppointment = () => {
    const today = new Date();
    AppointmentObj.id = ''
    AppointmentObj.eventType = 'PHOTOSHOT'
    AppointmentObj.orderId = ''
    AppointmentObj.startDate = today.toISOString().substring(0, 10);
    AppointmentObj.endDate = today.toISOString().substring(0, 10);
    AppointmentObj.startTime = "12:00";
    AppointmentObj.endTime = "14:00";
    AppointmentObj.userId = userData.user.id;

    setCurrentAppointment(AppointmentObj);
    calenderCreateNewOnOpen()
  }

  const handleDownloadCalsClicked = async () => {
    let calendarUrlLink = process.env.REACT_APP_EXTERNAL_API_URL.replace('https', 'webcals').replace('http', 'webcals') + "/calender/" + userData.user.photographyCompany.id;
    let qrcodeUrl = process.env.REACT_APP_ADMIM_UI_URL + "/#/handlers/calendar/" + userData.user.photographyCompany.id;

    if (calenderSelectedUser !== '0') {
      calendarUrlLink = calendarUrlLink + "/" + calenderSelectedUser
      qrcodeUrl = qrcodeUrl + "/" + calenderSelectedUser
    }

    const qrCodeImageUrl = await QRCode.toDataURL(qrcodeUrl, { errorCorrectionLevel: 'H' })
    setQrCodeUrl(qrCodeImageUrl)
    setCalsUrl(calendarUrlLink)
    downloadCalsOnOpen();
  }

  const handleEventMoved = (startStr, endStr, itemId) => {
    //find event
    let event = undefined;

    for (let i = 0; i < calenderData.length; i++) {
      if (itemId === calenderData[i].id) {
        event = calenderData[i];
        break;
      }
    }

    if (event) {
      const eventStartDate = new Date(event.start)
      const eventEndDate = new Date(event.end)
      AppointmentObj.title = event.title
      AppointmentObj.id = event.id
      AppointmentObj.eventType = event.eventType
      AppointmentObj.orderId = event.orderId
      AppointmentObj.userId = event.userId
      AppointmentObj.addressLink = '/#/admin/order/' + event.orderId + '/planning'
      AppointmentObj.startDate = startStr.substr(0, 10);
      AppointmentObj.endDate = endStr.substr(0, 10);
      AppointmentObj.startTime = String(eventStartDate.getHours()).padStart(2, '0') + ":" + String(eventStartDate.getMinutes()).padStart(2, '0')
      AppointmentObj.endTime = String(eventEndDate.getHours()).padStart(2, '0') + ":" + String(eventEndDate.getMinutes()).padStart(2, '0')
      setCurrentAppointment(AppointmentObj);


      calenderCreateNewOnOpen()
    }
  }

  const handleEventClicked = (e) => {
    //find event
    let event = undefined;

    for (let i = 0; i < calenderData.length; i++) {
      if (e.event.id === calenderData[i].id) {
        event = calenderData[i];
        break;
      }
    }

    if (event) {
      const eventStartDate = new Date(event.start)
      const eventEndDate = new Date(event.end)
      AppointmentObj.title = event.title
      AppointmentObj.id = event.id
      AppointmentObj.eventType = event.eventType
      AppointmentObj.orderId = event.orderId
      AppointmentObj.userId = event.userId
      AppointmentObj.addressLink = '/#/admin/order/' + event.orderId + '/planning'
      AppointmentObj.startDate = event.start.substr(0, 10);
      AppointmentObj.endDate = event.end.substr(0, 10);
      AppointmentObj.startTime = String(eventStartDate.getHours()).padStart(2, '0') + ":" + String(eventStartDate.getMinutes()).padStart(2, '0')
      AppointmentObj.endTime = String(eventEndDate.getHours()).padStart(2, '0') + ":" + String(eventEndDate.getMinutes()).padStart(2, '0')
      setCurrentAppointment(AppointmentObj);

      calenderCreateNewOnOpen()
    }
  }

  const handleCalenderItemDropped = (dateStr, orderId, orderTitle) => {
    AppointmentObj.id = ''
    AppointmentObj.eventType = 'PHOTOSHOT'
    AppointmentObj.title = orderTitle
    AppointmentObj.description = ''
    AppointmentObj.orderId = orderId
    AppointmentObj.addressLink = '/#/admin/order/' + orderId + '/planning'
    AppointmentObj.startDate = dateStr;
    AppointmentObj.endDate = dateStr;
    AppointmentObj.userId = userData.user.id
    AppointmentObj.startTime = "12:00";
    AppointmentObj.endTime = "14:00";

    setCurrentAppointment(AppointmentObj);
    calenderCreateNewOnOpen()
  }

  const handleDateClicked = (e) => {

    AppointmentObj.title = ''
    AppointmentObj.eventType = 'PHOTOSHOT'
    AppointmentObj.description = ''
    AppointmentObj.orderId = ''
    AppointmentObj.id = ''
    AppointmentObj.startDate = e.dateStr;
    AppointmentObj.endDate = e.dateStr;
    AppointmentObj.userId = userData.user.id
    AppointmentObj.startTime = "12:00";
    AppointmentObj.endTime = "14:00";

    setCurrentAppointment(AppointmentObj);
    calenderCreateNewOnOpen()
  }


  let currentDate = new Date();
  //currentDate.setDate(1);

  if (data.order) {
    if (AppointmentObj.orderId === '') {
      AppointmentObj.orderId = data.order.id;
      AppointmentObj.title = data.order.caseNo + ' - ' + data.order.estateAddress;
      setCurrentAppointment(AppointmentObj);
    }
  }

  //console.log('qrCodeUrl', qrCodeUrl)
  let calenderSpan = 2;
  if (data.order) {
    calenderSpan = 3;
  }

  const getMessageType = (eventType) => {

    switch (eventType) {
      case 'PHOTOSHOT':
        return MessageType.ORDER_BOOKED;
      case 'WEATHER_UPDATE':
        return MessageType.ORDER_WEATHER_UPDATE_BOOKED;
      case 'SEASON_FOTOSHOT':
        return MessageType.ORDER_SEASON_UPDATE_BOOKED;
      default:
        return null;
    }
  }


  const filterUsers = () => {
    if (userData.user.userType === 'PHOTOGRAPHER') {
      return data.users.filter((user) => user.id === userData.user.id);
    }

    if (userData.user.userType === 'PHOTOGRAPHY_COMPANY_ADMIN') {

      return data.users.filter((user) => user.userType !== 'ADMIN');
    }

    if (userData.user.userType === 'ADMIN') {
      return data.users;
    }
  }
  const handleTextButtonClicked = () => {
    history.push('/admin/order/' + data.order.id + '/text');
  }

  const handleMediaButtonClicked = () => {
    history.push('/admin/order/' + data.order.id + '/media');
  };


  //console.log('CurrentAppointment:', currentAppointment)
  return (
    <>
      {(showSentMessage && AppointmentObj.eventType !== 'OTHER') && (
        <OrderSentMessage key={'OrderSentMessage'} messageType={getMessageType(AppointmentObj.eventType)} isVisible={(showSentMessage && AppointmentObj.eventType !== 'OTHER')} orderId={AppointmentObj.orderId} onClose={() => { closeSentMessage() }} />
      )}
      <Flex flexDirection="column" pt={{ base: "120px", md: "35px" }}>
        {data.order && (
          <OrderHeader
            name={"Ordre - " + data.order.caseNo + ' - ' + data.order.estateAddress + ' - ' + data.order.estateZipCode + ' ' + data.order.estateCity}
            description={'Kalender overblik'}
            onCreateOrderClicked={() => { }}
            showCreateOrderButton={false}
            showBackButton={true}


            onMediaButtonClicked={handleMediaButtonClicked}
            showMediaButton={true}

            showTextButton={true}
            onTextButtonClicked={handleTextButtonClicked}
          />
        )}



        <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap="24px" my="26px" mb={{ lg: "26px" }}>
          {(!data.order && ['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType)) && (
            <GridItem colSpan={2} rowSpan={2}>
              <LeadprMonth data={data.leadsReceivedPrMonth} />
            </GridItem>
          )}
          {(!data.order && ['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType)) && (
            <>
              <GridItem colSpan={1} rowSpan={1}>
                <MiniStatistics
                  title={"Omsætning nuværende måned"}
                  amount={data.turnoverCurrentMonth}
                  percentage={null}
                  icon={<WalletIcon h={"24px"} w={"24px"} color={iconTeal} />}
                />
              </GridItem>
              <GridItem colSpan={1} rowSpan={1}>
                <MiniStatistics
                  title={"Nye ordrer denne måned"}
                  amount={data.newLeadsToDay}
                  percentage={null}
                  icon={<CartIcon h={"24px"} w={"24px"} color={iconTeal} />}
                />
              </GridItem>

            </>
          )}
          {data.order && (
            <GridItem colSpan={3} rowSpan={0}>
              <Grid templateColumns={{
                sm: "1fr",
                md: "repeat(3, 1fr)",
                xl: "repeat(3, 1fr)",
              }}
                gap="22px">
                <GridItem colSpan={0} rowSpan={1}>
                  <OrderInfo data={data.order} dataChanged={() => { refetch() }} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1}>
                  <OrderAddress data={data.order} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1}>
                  <OrderCustomer data={data.order} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1}>
                  <OrderSeller data={data.order} dataChanged={() => { }} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1} >
                  <OrderNotes data={data.order} noteType={NoteType.note} dataChanged={() => { }} heading={'Note - delt med kunde'} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1}>
                  <OrderNotes data={data.order} noteType={NoteType.internalNote} dataChanged={() => { }} heading={'Note - intern'} />
                </GridItem>
              </Grid>
            </GridItem>

          )}
          <GridItem colSpan={calenderSpan} rowSpan={7} style={{ height: '100vw' }}>
            <Card w={{ sm: "100%" }} h="100%">
              <CardHeader pt="6px" mb="8px"></CardHeader>
              <CardBody position="relative" display="block" height="100%">
                <EventCalendar
                  initialDate={currentDate}
                  calendarData={calenderData}
                  onCreateClicked={() => { handleCreateNewAppointment() }}
                  onDateDblClicked={(e) => { handleDateClicked(e) }}
                  onEventDblClicked={(e) => { handleEventClicked(e) }}
                  onEventClicked={(e) => { handleEventClicked(e) }}
                  onDownloadClicked={() => { handleDownloadCalsClicked() }}
                  onExternalItemDropped={(dateStr, orderId, orderTitle) => { handleCalenderItemDropped(dateStr, orderId, orderTitle) }}
                  onItemChanged={(startStr, endStr, itemId) => { handleEventMoved(startStr, endStr, itemId) }}
                  users={filterUsers()}
                  onSelectUserChanged={(value) => { handleUserChanged(value) }}
                />
              </CardBody>
            </Card>
          </GridItem>

          {!data.order ? (
            <>
              <GridItem>
                <Card w={{ sm: "100%" }} h="375px">
                  <CardHeader pt="6px" mb="8px">
                    <Flex direction="column">
                      <Text fontSize="lg" color={textColor} fontWeight="bold">
                        Nye Ordrer
                      </Text>
                    </Flex>
                  </CardHeader>
                  <CardBody position="relative" display="block" height="100%" style={{ overflowY: 'auto' }}>
                    <Stack direction="column" spacing="20px">
                      {newOrders.map((item, cid) => (

                        <Flex data-orderid={item.id} data-ordertitle={item.caseNo + ' - ' + item.address} align="center" key={`newOrder-${cid}`} className='draggable-calendar-event'>
                          <a href={item.addressLink} target="_self" referrerPolicy="no-referrer" className="upCommingEventLink">
                            <IconBox h={"50px"} w={"50px"} bg="gray.100" me="16px">
                              <Icon as={DocumentIcon} h={"22px"} w={"22px"} color={iconTeal} />
                            </IconBox>
                            <Flex direction="column">
                              <Text color={textColor} fontSize="sm" fontWeight="bold">
                                {item.caseNo}
                              </Text>
                              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                                {item.address}
                              </Text>
                            </Flex>
                          </a>
                        </Flex>

                      ))}
                    </Stack>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem>

                <Card w={{ sm: "100%" }} h="375px">
                  <CardHeader pt="6px" mb="8px">
                    <Flex direction="column">
                      <Text fontSize="lg" color={textColor} fontWeight="bold">
                        Opfølgning
                      </Text>
                    </Flex>
                  </CardHeader>
                  <CardBody position="relative" display="block" height="100%" style={{ overflowY: 'auto' }}>
                    <Stack direction="column" spacing="20px" >


                      {followUpEvents.map((item, cid) => (

                        <Flex data-orderid={item.id} data-ordertitle={item.caseNo + ' - ' + item.address} align="center" key={`followUpEvents-${cid}`} className='draggable-calendar-event'>
                          <a href={item.addressLink} target="_self" referrerPolicy="no-referrer" className="upCommingEventLink">
                            <IconBox h={"50px"} w={"50px"} bg="gray.100" me="16px">
                              <Icon as={ClockIcon} h={"22px"} w={"22px"} color={iconTeal} />
                            </IconBox>
                            <Flex direction="column">
                              <Text color={textColor} fontSize="sm" fontWeight="bold" lineHeight={'15px'}>
                                {item.caseNo}<br />{item.address}
                              </Text>
                              <Text color="gray.400" fontSize="sm" fontWeight="normal" lineHeight={'17px'}>
                                {item.status}
                              </Text>
                            </Flex>
                          </a>
                        </Flex>

                      ))}

                    </Stack>

                  </CardBody>
                </Card>
              </GridItem>

              <GridItem>

                <Card w={{ sm: "100%" }} h="375px">
                  <CardHeader pt="6px" mb="8px">
                    <Flex direction="column">
                      <Text fontSize="lg" color={textColor} fontWeight="bold">
                        Efterbehandling
                      </Text>
                    </Flex>
                  </CardHeader>
                  <CardBody position="relative" display="block" height="100%" style={{ overflowY: 'auto' }}>
                    <Stack direction="column" spacing="20px" >


                      {processingCompletedEvents.map((item, cid) => (

                        <Flex data-orderid={item.id} data-ordertitle={item.caseNo + ' - ' + item.address} align="center" key={`processingCompletedEvents-${cid}`} className='draggable-calendar-event'>
                          <a href={item.addressLink} target="_self" referrerPolicy="no-referrer" className="upCommingEventLink">
                            <IconBox h={"50px"} w={"50px"} bg="gray.100" me="16px">
                              <Icon as={ClockIcon} h={"22px"} w={"22px"} color={iconTeal} />
                            </IconBox>
                            <Flex direction="column">
                              <Text color={textColor} fontSize="sm" fontWeight="bold" lineHeight={'15px'}>
                                {item.caseNo}<br />{item.address}
                              </Text>
                              <Text color="gray.400" fontSize="sm" fontWeight="normal" lineHeight={'17px'}>
                                {item.status}
                              </Text>
                            </Flex>
                          </a>
                        </Flex>

                      ))}

                    </Stack>

                  </CardBody>
                </Card>
              </GridItem>
              <GridItem>

                <Card w={{ sm: "100%" }} h="375px">
                  <CardHeader pt="6px" mb="8px">
                    <Flex direction="column">
                      <Text fontSize="lg" color={textColor} fontWeight="bold">
                        Bero
                      </Text>
                    </Flex>
                  </CardHeader>
                  <CardBody position="relative" display="block" height="100%" style={{ overflowY: 'auto' }}>
                    <Stack direction="column" spacing="20px" >


                      {putOnHoldEvents.map((item, cid) => (

                        <Flex data-orderid={item.id} data-ordertitle={item.caseNo + ' - ' + item.address} align="center" key={`putOnHoldEvents-${cid}`} className='draggable-calendar-event'>
                          <a href={item.addressLink} target="_self" referrerPolicy="no-referrer" className="upCommingEventLink">
                            <IconBox h={"50px"} w={"50px"} bg="gray.100" me="16px">
                              <Icon as={ClockIcon} h={"22px"} w={"22px"} color={iconTeal} />
                            </IconBox>
                            <Flex direction="column">
                              <Text color={textColor} fontSize="sm" fontWeight="bold" lineHeight={'15px'}>
                                {item.caseNo}<br />{item.address}
                              </Text>
                              <Text color="gray.400" fontSize="sm" fontWeight="normal" lineHeight={'17px'}>
                                {item.status}
                              </Text>
                            </Flex>
                          </a>
                        </Flex>

                      ))}

                    </Stack>

                  </CardBody>
                </Card>
              </GridItem>

            </>
          ) : (
            <>
              {/*
             
              <GridItem colSpan={3}>
                <OrderNotes data={data.order} noteType={NoteType.note} dataChanged={() => { }} heading={'Note - delt med kunde'} />
              </GridItem>
              <GridItem colSpan={3}>
                <OrderNotes data={data.order} noteType={NoteType.internalNote} dataChanged={() => { }} heading={'Note - intern'} />
              </GridItem>
            
            */}
            </>
          )}


        </Grid>

        {/* Modal for download calender */}
        <Modal onClose={downloadCalsOnClose} size="xl" isOpen={downloadCalsIsOpen}>
          <ModalOverlay />
          <ModalContent width={470}>
            <ModalHeader>Importer kalender</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" justifyContent="Center" style={{ width: "100%", height: "100%" }}>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                  whiteSpace={"nowrap"}
                  mb="18px"
                  minWidth={"150px"}
                >
                  Scan QR kode eller klik på linket under for at importere<br /> kalender til din telefon eller computer.
                </Text>

                <Flex align="center" mb="18px" justifyContent="Center">
                  <img src={qrCodeUrl} alt="QR code" />
                </Flex>
                <Flex align="center" mb="18px" justifyContent="Center">
                  <a href={calsUrl} referrerPolicy="no-referrer">Klik her for at importer kalender</a>
                </Flex>
              </Flex>
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Modal for create new calender item */}
        <Modal onClose={calenderCreateNewOnClose} size="xl" isOpen={calenderCreateNewIsOpen}>
          <ModalOverlay />
          <ModalContent>
            {currentAppointment.id === '' ? (
              <ModalHeader>Opret ny kalender aftale</ModalHeader>
            ) : (
              <ModalHeader>Redigere kalender aftale</ModalHeader>
            )}
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" style={{ width: "100%", height: "100%" }}>
                {currentAppointment.id === '' ? (
                  <Flex align="center" mb="18px">
                    <Text
                      fontSize="md"
                      color={textColor}
                      fontWeight="bold"
                      me="10px"
                      whiteSpace={"nowrap"}
                      minWidth={"150px"}
                    >
                      Aftale:{" "}
                    </Text>
                    <Select multiple={false} defaultValue={currentAppointment.eventType} onChange={(e) => updateNewAppointmentData("eventType", e.target.value)}>
                      <option key={'eventType1'} value={"PHOTOSHOT"}>
                        Fotografering - Normal
                      </option>
                      <option key={'eventType2'} value={"WEATHER_UPDATE"}>
                        Fotografering - Vejropdatering
                      </option>
                      <option key={'eventType3'} value={"SEASON_FOTOSHOT"}>
                        Fotografering - Seasonopdatering
                      </option>
                      <option key={'eventType4'} value={"OTHER"}>
                        Andet
                      </option>
                    </Select>
                  </Flex>
                ) : (
                  <Flex align="center" mb="18px">
                    <Text
                      fontSize="md"
                      color={textColor}
                      fontWeight="bold"
                      me="10px"
                      whiteSpace={"nowrap"}
                      minWidth={"150px"}
                    >
                      Aftale:{" "}
                    </Text>
                    <Text
                      fontSize="md"
                      me="10px"
                      whiteSpace={"nowrap"}
                      minWidth={"150px"}
                    >
                      {currentAppointment.eventType === 'PHOTOSHOT' ? 'Fotografering - Normal' : ''}
                      {currentAppointment.eventType === 'WEATHER_UPDATE' ? 'Fotografering - Vejropdatering' : ''}
                      {currentAppointment.eventType === 'SEASON_FOTOSHOT' ? 'Fotografering - Seasonopdatering' : ''}
                      {currentAppointment.eventType === 'OTHER' ? 'Andet' : ''}
                    </Text>
                  </Flex>

                )}

                {currentAppointment.eventType === 'OTHER' && (
                  <>
                    <Flex align="center" mb="18px">
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        me="10px"
                        whiteSpace={"nowrap"}
                        minWidth={"150px"}
                      >
                        Title:{" "}
                      </Text>
                      <Input
                        variant="flushed"
                        name="title"
                        defaultValue={currentAppointment.title}
                        onChange={(e) =>
                          updateNewAppointmentData("title", e.target.value)
                        }
                      />
                    </Flex>
                  </>
                )}
                {currentAppointment.eventType !== 'OTHER' && (
                  <>
                    <Flex align="center" mb="18px">
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        me="10px"
                        whiteSpace={"nowrap"}
                        minWidth={"150px"}
                      >
                        Ordre:{" "}
                      </Text>
                      {currentAppointment.orderId || currentAppointment.id || data.order ? (
                        <>
                          <Text
                            fontSize="md"
                            me="10px"
                            whiteSpace={"nowrap"}
                            minWidth={"150px"}
                            overflow="hidden"
                          >
                            {currentAppointment.title}
                          </Text>
                          <a href={currentAppointment.addressLink} target="_blank" rel="noreferrer" referrerPolicy="no-referrer">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g id="Interface / External_Link">
                                <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </g>
                            </svg>
                          </a>
                        </>


                      ) : (
                        <AutoComplete openOnFocus onChange={(value, item) => { handleNewAppointmentOrderChange(value, item) }}>
                          <AutoCompleteInput variant="filled" />
                          <AutoCompleteList>
                            {notPlanedOrders.map((order, cid) => (
                              <AutoCompleteItem
                                key={`option-${cid}`}
                                value={order.id}
                                id={order.id}
                                label={order.caseNo + ' - ' + order.address}
                              >
                                {order.caseNo + ' - ' + order.address}
                              </AutoCompleteItem>
                            ))}
                          </AutoCompleteList>
                        </AutoComplete>
                      )}


                    </Flex>
                    
                  </>
                )}
                <Flex align="center" mb="18px">
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        me="10px"
                        whiteSpace={"nowrap"}
                        minWidth={"150px"}
                      >
                        Fotograf:{" "}
                      </Text>
                      <Select multiple={false} onChange={(e) =>
                        updateNewAppointmentData("userId", e.target.value)
                      }>
                        {userData.user.userType === 'PHOTOGRAPHER' ? (
                          <option key={userData.user.id} selected value={userData.user.id}>
                            {userData.user.name}
                          </option>

                        ) : (

                          data.users.map((user) => {
                            if (user.id === currentAppointment.userId) {
                              return (
                                <option key={user.id} selected value={user.id}>
                                  {user.name}
                                </option>
                              );
                            } else {


                              if (userData.user.userType !== 'ADMIN') {
                                if (user.userType !== 'ADMIN') {
                                  return (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  );
                                } else {
                                  return ""
                                }
                              }

                              if (userData.user.userType === 'ADMIN') {
                                return (
                                  <option key={user.id} value={user.id}>
                                    {user.name}
                                  </option>
                                );
                              }
                            }

                          })

                        )}
                      </Select>
                    </Flex>
                <Flex align="center" mb="18px">
                  <Text
                    fontSize="md"
                    color={textColor}
                    fontWeight="bold"
                    me="10px"
                    whiteSpace={"nowrap"}
                    minWidth={"150px"}
                  >
                    Starttidspunkt:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="date"
                    name="startDate"
                    defaultValue={currentAppointment.startDate}
                    onChange={(e) =>
                      updateNewAppointmentData("startDate", e.target.value)
                    }
                  />
                  <Input
                    variant="flushed"
                    type="time"
                    name="startTime"
                    defaultValue={currentAppointment.startTime}
                    onChange={(e) =>
                      updateNewAppointmentData("startTime", e.target.value)
                    }
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text
                    fontSize="md"
                    color={textColor}
                    fontWeight="bold"
                    me="10px"
                    whiteSpace={"nowrap"}
                    minWidth={"150px"}
                  >
                    Sluttidspunkt:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="date"
                    name="endDate"
                    value={currentAppointment.endDate}
                    onChange={(e) =>
                      updateNewAppointmentData("endDate", e.target.value)
                    }
                  />
                  <Input
                    variant="flushed"
                    type="time"
                    name="endTime"
                    defaultValue={currentAppointment.endTime}
                    onChange={(e) =>
                      updateNewAppointmentData("endTime", e.target.value)
                    }
                  />
                </Flex>

              </Flex>
            </ModalBody>
            <ModalFooter>
              {currentAppointment.id === '' ? (
                <Button onClick={handleCreateUpdateAppointmentOnServer}>Opret aftale</Button>
              ) : (
                <>
                  <Button onClick={() => { deleteWarningOnOpen() }}>Slet aftale</Button>
                  <Button onClick={handleCreateUpdateAppointmentOnServer} ml={3}>Opdater aftale</Button>
                </>
              )
              }
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Slet aftale
              </AlertDialogHeader>

              <AlertDialogBody>Er du sikker?</AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={deleteWarningOnClose}>
                  Fortryd
                </Button>
                <Button colorScheme="red" onClick={() => { deleteCalenderItemOnServer() }} ml={3}>
                  Slet
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex >
    </>
  );
};
export default Dashboard;
