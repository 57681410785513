// Chakra imports
import {
    Flex, useColorModeValue, Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Input,
    useToast,
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../components/SettingsHeader";
import React from "react";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import SettleTable from "./../../../../components/Tables/SettleTable";
import { useQuery, useMutation, gql } from "@apollo/client";
import moment from "moment";
import { signOut } from "./../../../../auth-context/authHelper"

const PHOTOGRAPHYCOMPANY_GQL = gql`
query getPhotographyCompanys {
    getPhotographyCompanys {
        id
        name
        email
        phoneNo
        address
        zipCode
        city
        contactPersonName
        createdAt
    }
}`

const LOGINAS_GQP = gql`
mutation loginAsPhotographyCompany($id: ID!){
    loginAsPhotographyCompany(id:$id)
}`

const CREATE_PHOTOGRAPHYCOMPANY_GQL = gql`
mutation createPhotographyCompany($input: CreatePhotographyCompanyInput!){
    createPhotographyCompany(input:$input){
        id
    }
}`

const CreatePhotographyCompanyObj = {
    name: '' as String,
}

function PhotographyCompanies() {
    const toast = useToast()
    //const userData = useAuth()
    //const history = useHistory();
    //const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
    const [validate, setValidate] = React.useState(false);
    //const [selectedUserId, setSelectedUserId] = React.useState('');

    const [createUserData, setCreateUserData] = React.useState(CreatePhotographyCompanyObj);
    //const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
    const { isOpen: createUserIsOpen, onOpen: createUserOnOpen, onClose: createUserOnClose } = useDisclosure();

    //Colors
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    /*
    const [deleteUser] = useMutation(
        DELETE_USER_GQL,
        {
            refetchQueries: [USERS_GQL]
        }
    )
    */

    const [loginAsPhotographyCompany] = useMutation(
        LOGINAS_GQP
    )

    const [createPhotographyCompany] = useMutation(
        CREATE_PHOTOGRAPHYCOMPANY_GQL,
        {
            refetchQueries: [PHOTOGRAPHYCOMPANY_GQL],
        }
    )

    const { loading, error, data } = useQuery(PHOTOGRAPHYCOMPANY_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting photographyCompanies from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting photographyCompanies from server (2)</p>);
    }

    //const loginAsClicked = (obj) => {
        //set current user to a new photography company
    //}

    /*
    const deleteUserClicked = (obj) => {
        setSelectedUserId(obj.id)
        deleteWarningOnOpen();
    }
   
    const deleteUserConfirmed = () => {
        deleteUser({ variables: { id: selectedUserId } } as any);
        deleteWarningOnClose();
    }
 */

    const columns = [
        {
            header: "",
            accessor: "id",
            hidden: true
        },
        
        {
            header: "Navn",
            accessor: "name",
            onClick: rowClicked
        },
        {
            header: "E-mail",
            accessor: "email",
            onClick: rowClicked
        },
        {
            header: "Telefon nr.",
            accessor: "phoneNo",
            onClick: rowClicked
        },
        {
            header: "Kontakt person",
            accessor: "contactPersonName",
            onClick: rowClicked
        },


        {
            header: "Oprettet",
            accessor: "createdAt",
            onClick: rowClicked
        },
    ];

    function rowClicked(obj) {
        loginAsPhotographyCompany(
            {
                variables: { id: obj.id }
            }
        ).then(() => {
            signOut();
        }).catch(() => {
            toast({
                title: 'Login as fejlede.',
                description: "Det var ikke muligt at logge ind som virksomheden.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        });
    }

    const createPhotographyCompanyOnServer = () => {
        if (CreatePhotographyCompanyObj.name === '') {
            setValidate(true)
        } else {
            createPhotographyCompany({
                variables: { input: CreatePhotographyCompanyObj } as any
            }).then(() => {
                createUserOnClose()
            }).catch((() => {
                toast({
                    title: 'Oprettelsen af fotograf virksomheden fejlede.',
                    description: "Det var ikke muligt at oprette fotograf virksomheden.",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            })
            );
        }
    }

    //create obj for table component
    let tableData = JSON.parse(JSON.stringify(data.getPhotographyCompanys));

    for (var i = 0; i < tableData.length; i++) {
        tableData[i].createdAt = moment(tableData[i].createdAt).format('DD-MM-YYYY');
    }

    const createUserClicked = () => {
        //CreateUserObj.photographyCompanyId = '';
        CreatePhotographyCompanyObj.name = '';
        setCreateUserData(CreatePhotographyCompanyObj)
        setValidate(false)
        createUserOnOpen();
    }

    const updateData = (key, value) => {
        CreatePhotographyCompanyObj[key] = value;
        setCreateUserData(CreatePhotographyCompanyObj)
        setValidate(false)
    }

    return (
        <div>
            <Flex direction="column">
                <SettingsHeader
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Fotograf virksomheder"}
                    description={'Opret og rediger fotograf virksomheder i systemet'}
                    showCreateUserButton={false}
                    onCreateUserClicked={createUserClicked}
                    showBackButton={false}
                    showUserSentWellcomesMessageButton={false}
                    onSentWellcomesMessageClicked={() => { }}
                />

                <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                    <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">

                        <Flex direction="column">
                            <Text fontSize="lg" color={textColor} fontWeight="bold">
                                Fotograf virksomheder
                            </Text>
                        </Flex>
                        <Flex direction="column">
                            <Text
                                color={textColor}
                                fontSize="12px"
                                fontWeight="bold"
                                mb="6px"
                                cursor="pointer"
                                onClick={() => {
                                    createUserClicked();
                                }}
                            >
                                Opret ny fotograf virksomhed
                            </Text>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <SettleTable data={tableData} columnsData={columns} setDragData={false} csvData={[]} enableSelect={false} />
                    </CardBody>
                </Card>
            </Flex>


            <Modal onClose={createUserOnClose} size="xl" isOpen={createUserIsOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Opret ny fotograf virksomhed</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Du er ved at oprettet en ny fotograf virksomhed, udfyld nedenstående og klik opret.</Text>
                        <br /><br />
                        <Text fontSize='md' fontWeight='bold'>Fotograf virksomhed:</Text>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Navn:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name="name"
                                    onKeyUp={(e) => { updateData('name', e.currentTarget.value) }}
                                    className={validate && createUserData.name === '' ? 'error' : ''}
                                />
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createUserOnClose}>Fortryd</Button>
                        <Button colorScheme='green' onClick={createPhotographyCompanyOnServer} ml={3}>Opret</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}

export default PhotographyCompanies;
