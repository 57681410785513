// Chakra imports
import { Text, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import SettleTable from "./../../../../../components/Tables/SettleTable";
import React from "react";
import CardHeader from "../../../../../components/Card/CardHeader";

const GET_PRODUCTS = gql`
query getExtraServiceProducts {
  getExtraServiceProducts {
    id
    name
    description
  }
}`

const DELETE_PRODUCT = gql`
mutation deleteProduct($id: ID!){
    deleteProduct(id:$id)
}`

const CREATE_PRODUCT = gql`
mutation createProduct($input: CreateProductInput!){
  createProduct(input:$input){
    id
  }
}`

const UPDATE_PRODUCT_SORTING = gql`
mutation updateProductSorting($input: [UpdateProductSortingInput]){
  updateProductSorting(input:$input)
}`

const CreateProductObj = {
    name: '' as string,
}

const SubProductList = () => {
    // Chakra color mode
    const toast = useToast()
    const history = useHistory();
    const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
    const [validate, setValidate] = React.useState(false);
    const [createProductData, setCreateProductData] = React.useState(CreateProductObj);
    const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
    const { isOpen: createProductIsOpen, onOpen: createProductOnOpen, onClose: createProductOnClose } = useDisclosure();
    const [selectedProductId, setSelectedProductId] = React.useState('');
    const textColor = useColorModeValue("gray.700", "white");

    const [deleteProduct] = useMutation(
        DELETE_PRODUCT,
        {
            refetchQueries: [GET_PRODUCTS],
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        }
    )

    const [createNewProduct] = useMutation(
        CREATE_PRODUCT,
        {
            refetchQueries: [GET_PRODUCTS],
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        }
    )

    const [updateProductSorting] = useMutation(
        UPDATE_PRODUCT_SORTING,
        {
            refetchQueries: [GET_PRODUCTS],
        }
    )

    const { loading, error, data } = useQuery(GET_PRODUCTS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return <p></p>; //loading

    if (error) {
        return (<p>Error getting customer from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting customer from server (2)</p>);
    }



    function rowClicked(obj) {
        //redirect to product page
        history.push('/admin/setting/product/' + obj.id);
    }

    const createProduct = () => {
        if (CreateProductObj.name === '') {
            console.log('Validate - CreateUserObj:', CreateProductObj)
            setValidate(true)
        } else {
            createNewProduct({
                variables: { input: CreateProductObj } as any
            }).then(() => createProductOnClose())
                .catch((() => {
                    toast({
                        title: 'Opret produkt fejlede.',
                        description: "Det var ikke muligt at oprette produkt.",
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    });

                })
                );
        }
    }

    const createProductClicked = () => {
        CreateProductObj.name = '';

        setCreateProductData(CreateProductObj)
        setValidate(false)
        createProductOnOpen();
    }


    const updateProductData = (field, value) => {
        CreateProductObj[field] = value;
        setCreateProductData(CreateProductObj);
    }

    const deleteProductClicked = (obj) => {
        setSelectedProductId(obj.id)
        deleteWarningOnOpen();
    }

    const deleteProductConfirmed = () => {
        deleteProduct({ variables: { id: selectedProductId } } as any);
        deleteWarningOnClose();
    }

    const setTableData = (obj) => {
        var tableData = obj(data.getExtraServiceProducts);
        let tmptableData = JSON.parse(JSON.stringify(tableData));

        for (var i = 0; i < tmptableData.length; i++) {
            tmptableData[i].sorting = i + 1;
        }

        var result = tmptableData.map((product) => ({ id: product.id, sorting: product.sorting }));

        updateProductSorting({
            variables: {
                input: result,
            },
        });
    };

    const columns = [
        {
            header: "",
            accessor: "id",
            hidden: true
        },
        {
            header: "Sortering",
            accessor: "sorting",
            alignHeader: "center",
            alignData: "right",
            hidden: true,
            onClick: rowClicked,
        },
        {
            header: "Navn",
            accessor: "name",
            onClick: rowClicked,
            width: 200,
        },
        {
            header: "Rediger",
            icon: "edit",
            alignHeader: "center",
            alignData: "right",
            width: 100,
            accessor: "delete",
            onClick: rowClicked,
        },
        {
            header: "Slet",
            accessor: "2",
            icon: "delete",
            alignHeader: "center",
            alignData: "right",
            width: 100,
            onClick: deleteProductClicked,
        },
    ];

    console.log('data:', data)
    let tableData = JSON.parse(JSON.stringify(data.getExtraServiceProducts));



    return (

        <>

            <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
                    <Flex direction="column">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Tillægsprodukter
                        </Text>
                    </Flex>
                    <Flex direction="row">
                        
                        <Text
                            color={textColor}
                            fontSize="12px"
                            fontWeight="bold"
                            mb="6px"
                            cursor="pointer"
                            onClick={() => {
                                createProductClicked();
                            }}
                        >
                            Opret nyt tillægsprodukt
                        </Text>
                        
                    </Flex>
                </CardHeader>
                <CardBody>
                    <SettleTable data={tableData} columnsData={columns} setDragData={setTableData} csvData={[]} enableSelect={false} />
                </CardBody>
            </Card>




            <Modal onClose={createProductOnClose} size="xl" isOpen={createProductIsOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Opret tillægsprodukt</ModalHeader>

                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Du er ved at oprette et nyt tillægsprodukt, udfyld nedenstående og klik opret.</Text>
                        <br /><br />
                        <Text fontSize='md' fontWeight='bold'>Tillægsprodukt:</Text>

                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} fontWeight="bold" me='10px' whiteSpace={'nowrap'} minWidth={'110px'}>
                                    Navn:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name="name"
                                    defaultValue={createProductData.name}
                                    onKeyUp={(e) => { updateProductData('name', e.currentTarget.value) }}
                                    className={validate && createProductData.name === '' ? 'error' : ''}
                                />
                            </Flex>
                        </Flex>

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createProductOnClose}>Fortryd</Button>
                        <Button colorScheme='green' onClick={createProduct} ml={3}>Opret</Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>


            <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Slet produkt
                        </AlertDialogHeader>

                        <AlertDialogBody>Du er ved at slette et produkt. <br />Er du sikker?</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={deleteWarningOnClose}>
                                Nej, behold
                            </Button>
                            <Button colorScheme="red" onClick={deleteProductConfirmed} ml={3}>
                                Ja, jeg er sikker
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>


        </>
    );
}

export default SubProductList;
