
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useAuth } from "../../../../auth-context/auth.context";
import SettleTable from "../../../../components/Tables/SettleTable";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";


interface HelpCenterContainerProps {
    data: any;
    onDeleteItemClicked: any;
    onEditItemClicked: any;
}


export const HelpCenterContainer: React.FC<HelpCenterContainerProps> = ({ data,onDeleteItemClicked,onEditItemClicked /* other props */ }) => {
    const textColor = useColorModeValue("gray.700", "white");
    
    const userData = useAuth()
    const rowClicked = (row) => {
        window.open(row.url, '_blank');
    }


    const columns = [
        {
            header: "",
            accessor: "id",
            hidden: true
        },
        {
            header: "",
            accessor: "url",
            hidden: true
        },
        {
            header: "",
            accessor: "itemType",
            hidden: true
        },
        {
            header: "Type",
            accessor: "itemTypeText",
            onClick: rowClicked,
            width: '70px'
        },
        {
            header: "Title",
            accessor: "title",
            onClick: rowClicked,
            width: '570px'
        },
        {
            header: "Beskrivelse",
            accessor: "description",
            onClick: rowClicked,
            width: '70px'
        },
       
    ] as any;

    const tableData = data.map((item) => {
        return {
            id: item.id,
            title: item.title,
            description: item.description,
            itemType: item.itemType,
            itemTypeText: item.itemType === 'PDF' ? 'Dokument' : 'Video',
            url: item.url,
        }
    });

    console.log('tableData', tableData)

    if (['ADMIN'].includes(userData.user.userType)) {
        columns.push({
            header: "Rediger",
            icon: "edit",
            alignHeader: "center",
            alignData: "right",
            width: 100,
            accessor: "edit",
            onClick: onEditItemClicked
        });
        columns.push({
            header: "Slet",
            icon: "delete",
            alignHeader: "center",
            alignData: "right",
            width: 100,
            accessor: "delete",
            onClick: onDeleteItemClicked
        });
    }

    return (
        <Card p='16px'>
            <CardHeader p='12px 5px' mb='12px'>
                <Text fontSize='lg' color={textColor} fontWeight='bold'>
                    {'Hvad kan vi hjælpe med?'}
                </Text>
            </CardHeader>
            <CardBody px='5px'>
                <Flex direction='column' width={'100%'}>
                    <Text fontSize='sm' color='gray.500' fontWeight='600' mb='20px' style={{ whiteSpace: 'break-spaces' }}>
                        {'Søg i feltet nedenfor, eller vælg et punkt for at finde den hjælp du skal bruge.'}
                    </Text>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <SettleTable
                            setDragData={false}
                            csvData={[]}
                            data={tableData}
                            columnsData={columns}
                            enableSelect={false}
                            onSelectedChanged={() => { }}
                            customButtonText1={''}
                            customButtonAction1={() => { }}
                            customButtonText2={''}
                            customButtonAction2={() => { }}
                        />
                    </div>
                </Flex>
            </CardBody>
        </Card>
    )
}