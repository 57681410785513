// Chakra imports
import { Text, Checkbox, Input, Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../../components/SettingsHeader";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import CardHeader from "./../../../../../components/Card/CardHeader";
import Editor from "./../../../../../components/Editor/Editor";
import { convert } from 'html-to-text';

const GET_STANDARD_MESSAGE = gql`
query standardMessage($messageType: MessageType!){
  standardMessage(messageType:$messageType){
    id
    name
    description
    messageType
    mailSubject
    mailText
    smsText
    sentMailToCustomer
    sentMailToContactPerson
    sentMailToPhotograhyCompany
    sentSmsToCustomer
    sentSmsToContactPerson
    sentSmsToPhotograhyCompany
    createdAt
    updatedAt
  }
  getPhotographyCompany{
    smsEnabled
  }

}`

const UPDATE_CREATE_STANDARD_MESSAGE = gql`
  mutation createUpdateStandardMessage($input: CreateUpdateStandardMessageInput!) {
    createUpdateStandardMessage(input: $input) {
      id
    }
  }
`;



function StandardMessage() {
  const toast = useToast();
  const [updateCreateStandardMessage] = useMutation(UPDATE_CREATE_STANDARD_MESSAGE);
  const [standardMessageId, setStandardMessageId] = useState("");
  const [smsText, setsmsText] = useState("");

  // Chakra color mode
  interface Params {
    messageType: string;
  }

  const { messageType } = useParams<Params>();

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const textColor = useColorModeValue("gray.700", "white");

  const { loading, error, data } = useQuery(GET_STANDARD_MESSAGE, {
    variables: { messageType: messageType.toUpperCase() },
    fetchPolicy: "no-cache"
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting customer from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting customer from server (2)</p>);
  }

  if (smsText === '' && data.standardMessage.smsText !== '') {
    setsmsText(data.standardMessage.smsText);
  }

  const isSmsToLong = smsText.length > 800 ? true : false;

  var timeout = null;
  const updateText = (field, text) => {
    if (text !== "") {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        const sms = convert(text, {
          wordwrap: 130
        });
        setsmsText(sms);
        if (sms.length > 800) {
          updateData("sentSmsToCustomer", false, false);
          updateData("sentSmsToContactPerson", false, false);
          updateData("sentSmsToPhotograhyCompany", false, false);
        }

        updateData(field, text, true);
      }, 2000);
    }
  };

  const updateData = (field, value, showInfo) => {
    const dataObj = {}

    if (data.standardMessage.id) {
      dataObj['id'] = data.standardMessage.id;
    }
    if (standardMessageId !== "") {
      dataObj['id'] = standardMessageId;
    }

    dataObj['messageType'] = messageType.toUpperCase();
    dataObj[field] = value;


    updateCreateStandardMessage({ variables: { input: dataObj } })
      .then((data) => {
        setStandardMessageId(data.data.createUpdateStandardMessage.id);

        if (showInfo) {
          toast({
            title: "Ændringen er blevet gemt.",
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  let name = '';
  switch (data.standardMessage.messageType) {
    case 'ORDER_RECEIVED':
      name = 'Ny bestilling';
      break;
    case 'ORDER_CONFIRMATION':
      name = 'Ordrebekræftelse';
      break;
    case 'UNSUCCESSFULL_CONTACT_ATTEMPT':
      name = 'Forgæves kontakt';
      break;
    case 'ORDER_BOOKED':
      name = 'Ordre booket';
      break
    case 'ORDER_WEATHER_UPDATE_BOOKED':
      name = 'Vejropdatering planlagt';
      break
    case 'ORDER_SEASON_UPDATE_BOOKED':
      name = 'Sæsonopdatering planlagt';
      break
    case 'ORDER_DELIVERED':
      name = 'Ordre leveret';
      break;
    case 'ORDER_CANCELED':
      name = 'Ordre annulleret';
      break;
    case 'ORDER_REMINDER':
      name = 'Ordre påmindelse';
      break;
    case 'ORDER_NOTE_CHANGED':
      name = 'Ny besked';
      break;
    default:
      break;
  }

  return (
    <Flex direction="column">
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={name}
        description={data.standardMessage.description}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={true}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px">
        <GridItem colSpan={2} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Standard besked
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"100px"} me="10px" whiteSpace={"nowrap"}>
                    Emne (e-mail):{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="mailSubject"
                    defaultValue={data.standardMessage.mailSubject}
                    onBlur={(e) => updateData('mailSubject', e.target.value, true)}
                  />
                </Flex>

                <Flex align="top" mb="18px" >
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"100px"} me="10px" whiteSpace={"nowrap"}>
                    Besked:{" "}
                  </Text>
                  <Editor id="email_123" value={data.standardMessage.mailText} changed={(e) => (updateText('mailText', e))} showFields={true} />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={0} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Indstillinger
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Checkbox size='lg' iconColor='gray.800' colorScheme="white" defaultChecked={data.standardMessage.sentMailToCustomer ? true : false} onChange={(e) => updateData('sentMailToCustomer', e.target.checked, true)}>
                    <Text fontSize="16px" color={textColor} >
                      Send mail til mægler forretningen
                    </Text>
                  </Checkbox>
                </Flex>

                <Flex align="center" mb="18px">
                  <Checkbox size='lg' iconColor='gray.800' colorScheme="white" defaultChecked={data.standardMessage.sentMailToContactPerson ? true : false} onChange={(e) => updateData('sentMailToContactPerson', e.target.checked, true)}>
                    <Text fontSize="16px" color={textColor} >
                      Send mail til kontaktperson
                    </Text>
                  </Checkbox>
                </Flex>

                <Flex align="center" mb="18px">
                  <Checkbox size='lg' iconColor='gray.800' colorScheme="white" defaultChecked={data.standardMessage.sentMailToPhotograhyCompany ? true : false} onChange={(e) => updateData('sentMailToPhotograhyCompany', e.target.checked, true)}>
                    <Text fontSize="16px" color={textColor} >
                      Send mail til fotograf virksomheden
                    </Text>
                  </Checkbox>
                </Flex>

                {data.getPhotographyCompany.smsEnabled ? (
                  <>
                    {isSmsToLong ? (
                      <Flex align="center" mb="18px">
                        <Text fontSize="sm" color={textColor} fontWeight="bold">
                          Teksten er for lang til at sende som SMS
                        </Text>
                      </Flex>
                    ) : (
                      <>
                        <Flex align="center" mb="18px">
                          <Checkbox size='lg' iconColor='gray.800' colorScheme="white" defaultChecked={data.standardMessage.sentSmsToCustomer ? true : false} onChange={(e) => updateData('sentSmsToCustomer', e.target.checked, true)}>
                            <Text fontSize="16px" color={textColor} >
                              Send SMS til mægler på ordren
                            </Text>
                          </Checkbox>
                        </Flex>

                        <Flex align="center" mb="18px">
                          <Checkbox size='lg' iconColor='gray.800' colorScheme="white" defaultChecked={data.standardMessage.sentSmsToContactPerson ? true : false} onChange={(e) => updateData('sentSmsToContactPerson', e.target.checked, true)}>
                            <Text fontSize="16px" color={textColor} >
                              Send SMS til kontaktperson
                            </Text>
                          </Checkbox>
                        </Flex>

                        {/*}
                  <Flex align="center" mb="18px">
                    <Checkbox size='lg' iconColor='gray.800' colorScheme="white" defaultChecked={data.standardMessage.sentSmsToPhotograhyCompany ? true : false} onChange={(e) => updateData('sentSmsToPhotograhyCompany', e.target.checked)}>
                      Send SMS til fotograf
                    </Checkbox>
                  </Flex>
                  */}
                      </>
                    )}
                  </>
                ) : (
                  <Flex align="center" mb="18px">
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                      * SMS service er ikke aktiveret, kontakt MagnaMulti for mere information.
                    </Text>
                  </Flex>
                )}
              </Flex>
            </CardBody>
          </Card>
        </GridItem>



      </Grid>
    </Flex>
  );
}

export default StandardMessage;
