import { Flex, Image, Box, Text, useColorModeValue, Button, Textarea } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import ideaImage from "./../../../../assets/img/idea.png";
import { useRef, useState } from "react";

export const HelpCenterIdea: React.FunctionComponent = () => {
  const suggestionRef = useRef<HTMLAnchorElement>(null);
  const [formValue, setFormValue] = useState('');
  const textColor = useColorModeValue("gray.700", "white");

  const handleFormValue = (event) => {
    setFormValue(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (suggestionRef.current) {
      suggestionRef.current.click();
    }
  };

  return (
    <Card p='16px'>

      <Box mb='20px' position='relative' borderRadius='15px'>
        <Image src={ideaImage} borderRadius='15px' style={{ objectFit: 'cover', width: '75%', marginLeft: 'auto', marginRight: 'auto' }} />

      </Box>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {'Har du de bedste idéer til nye features i Magna Multi?'}
        </Text>
      </CardHeader>
      <CardBody px='5px'>

        <Flex direction='column'>

          <Text fontSize='sm' color='gray.500' fontWeight='600' mb='20px' style={{ whiteSpace: 'break-spaces' }}>
            {'Magna Multi er designet sammen med brugerne - og sådan skal det fortsætte med at være. Savner du en feature eller har du en idé til, hvordan systemet kan blive hurtigere eller nemmere at arbejde med, så lad os endelig høre det.'}
          </Text>

          <form onSubmit={handleFormSubmit}>
            <Textarea
              height={'100px'}
              value={formValue}
              onChange={handleFormValue}
              placeholder={'Skriv dit forslag til nye features her'}
            />
            <a
              ref={suggestionRef}
              href={`mailto:info@magnamulti.dk?subject=God ide&body=${formValue}`}
              style={{ display: 'none' }}
            >
              send
            </a>
            <div style={{ width: '100%', textAlign: 'end' }}>
              <Button type="submit" variant="outline" style={{ marginTop: '20px' }}  >
                Send forslag
              </Button>
            </div>
          </form>
        </Flex>
      </CardBody>
    </Card>
  );

}