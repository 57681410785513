// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
//import { gql, useMutation } from "@apollo/client";

/*
const UPDATE_ORDER_GQL = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;
*/

const OrderCustomer = ({ data }) => {
  ///const toast = useToast();
  // Chakra color mode
  //const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
  const textColor = useColorModeValue("gray.700", "white");
  //const toast = useToast();

  /*
  const updateData = (orderId, field, data) => {
    let inputData = {};
    inputData.id = orderId;
    inputData[field] = data;

    updateOrderInfo({
      variables: { input: inputData },
    })
      .then(() => {
        dataChanged();
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Opdatering af ordren fejlede.",
          description: "Det var ikke muligt at opdatere ordren.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });

  };
  */

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} style={{ height: "100%" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Kunde information
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Navn:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.customer.name}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Kontakt person:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
            {data.estateAgentName}
            </Text>
          </Flex>
          <Flex align="flex-start" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
            Kontakt telefon nr.:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.estateAgentPhone}
            </Text>
          </Flex>
          <Flex align="flex-start" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
            Kontakt e-mail:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.estateAgentEmail}
            </Text>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default OrderCustomer;
