// Chakra imports
import {
  Flex,
  Stat,
  StatHelpText,
  StatNumber,
  useColorModeValue,
  Text
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card.js";
import CardBody from "./../../../../components/Card/CardBody.js";
import IconBox from "./../../../../components/Icons/IconBox";

const MiniStatistics = ({ title, amount, percentage, icon }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card minH='83px'>
      <CardBody>
        <Flex flexDirection='row' align='center' justify='center' w='100%'>
          <Stat me='auto'>
          <Text fontSize="lg" color={textColor} fontWeight="bold">
              {title}
            </Text>
            <Flex>
              <StatNumber fontSize='lg' color={textColor}>
                {amount}
              </StatNumber>
              {percentage != null ? (
              <StatHelpText
                alignSelf='flex-end'
                justifySelf='flex-end'
                m='0px'
                color={percentage > 0 ? "green.400" : "red.400"}
                fontWeight='bold'
                ps='3px'
                fontSize='md'>
                {percentage > 0 ? `+${percentage}%` : `${percentage}%`}
              </StatHelpText>
              ):<p></p>}
            </Flex>
          </Stat>
          <IconBox h={"50px"} w={"50px"} bg="gray.100" me="16px">
              {icon}
          </IconBox>


        </Flex>
      </CardBody>
    </Card>
  );
};

export default MiniStatistics;
