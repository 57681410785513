// Chakra imports
import { Text, Textarea, Input, Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../../components/SettingsHeader";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import CardHeader from "./../../../../../components/Card/CardHeader";
import { Select } from "chakra-react-select";
import Editor from "./../../../../../components/Editor/Editor";
import { FaSignInAlt } from "react-icons/fa";

const GET_SUBCONTRACTOR = gql`
query subcontractor($id: ID!){
  subcontractor(id: $id){
    id
    name
    address
    zipCode
    city
    cvr
    email
    phoneNo
    contactPersonName
    bookingEmail
    websiteUrl
    note
    language
    mailSubject
    mailText
    subcontractorType
    createdAt
    updatedAt
    description
    subcontractorUiUrl
  }
}`

const UPDATE_SUBCONTRACTOR = gql`
  mutation updateSubcontractor($input: UpdateSubcontractorInput!) {
    updateSubcontractor(input: $input) {
      id
    }
  }
`;

function SubcontractorInfo() {
  const toast = useToast();
  const [updateSubcontractor] = useMutation(UPDATE_SUBCONTRACTOR);

  // Chakra color mode
  interface Params {
    subcontractorId: string;
  }

  const { subcontractorId } = useParams<Params>();

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const textColor = useColorModeValue("gray.700", "white");

  const { loading, error, data } = useQuery(GET_SUBCONTRACTOR, {
    variables: { id: subcontractorId },
    fetchPolicy: "no-cache"
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting subcontractor from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting subcontractor from server (2)</p>);
  }

  var timeout = null;
  const updateText = (field, text) => {
    if (text !== "") {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        updateData(field, text);
      }, 2000);
    }
  };

  const updateData = (field, value) => {
    const dataObj = {}
    dataObj['id'] = data.subcontractor.id;
    if (field === 'zipCodeAndCity') {
      dataObj['zipCode'] = value.match(/\d+/g)[0];
      dataObj['city'] = value.match(/[a-zA-Z]+/g)[0];
    } else {
      dataObj[field] = value;
    }

    updateSubcontractor({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  const addLinebreaks = (text) => {
    return text.replaceAll("\\n", "\n");
  };

  const subcontractorTypeOptions = () => {
    let options = [];
    options.push({ value: 'AUTHER', label: 'Forfatter' });
    options.push({ value: 'MEDIA_PROCESSOR', label: 'Mediebehandler' });
    return options;
  }

  const selectedSubcontractorType = () => {
    const subcontractorTypes = subcontractorTypeOptions();
    const subcontractorType = subcontractorTypes.find(type => type.value === data.subcontractor.subcontractorType);
    return subcontractorType;
  }

  const languageOptions = () => {
    let options = [];
    options.push({ value: 'da-DK', label: 'Dansk' });
    options.push({ value: 'en-GB', label: 'Engelsk' });
    return options;
  }

  const selectedLanguage = () => {
    const languages = languageOptions();
    const language = languages.find(lang => lang.value === data.subcontractor.language);
    return language;
  }

  const handleLoginAs = () => {
    window.open(data.subcontractor.subcontractorUiUrl, '_blank');
  }

  return (
    <Flex direction="column">
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={data.subcontractor.name}
        description={'Her kan du redigere underleverandørens data og rettigheder.'}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
        showBackButton={true}
        customButtonShow={true}
        customButtonIcon={FaSignInAlt}
        customButtonOnClick={() => { handleLoginAs() }}
        customButtonText={"Login som underleverandør"}
        
        
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(4, 1fr)", xl: "repeat(4, 1fr)" }} gap="24px">
        <GridItem colSpan={2} rowSpan={2} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Underleverandør
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Navn:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="name"
                    defaultValue={data.subcontractor.name}
                    onBlur={(e) => updateData('name', e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Kontakt person:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="contactPersonName"
                    defaultValue={data.subcontractor.name}
                    onBlur={(e) => updateData('contactPersonName', e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Kontakt e-mail:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="email"
                    defaultValue={data.subcontractor.email}
                    onBlur={(e) => updateData('email', e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                  Vejnavn og nr:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="address"
                    defaultValue={data.subcontractor.address}
                    onBlur={(e) => updateData('address', e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                  Post nr. og by:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="zipCodeAndCity"
                    defaultValue={data.subcontractor.zipCode + " " + data.subcontractor.city}
                    onBlur={(e) => updateData('zipCodeAndCity', e.currentTarget.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                  CVR:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="cvr"
                    defaultValue={data.subcontractor.cvr}
                    onBlur={(e) => updateData('cvr', e.currentTarget.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Telefon nr.:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="phoneNo"
                    defaultValue={data.subcontractor.phoneNo}
                    onBlur={(e) => updateData('phoneNo', e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Website:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="websiteUrl"
                    defaultValue={data.subcontractor.websiteUrl}
                    onBlur={(e) => updateData('websiteUrl', e.target.value)}
                  />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={2} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Indstillinger
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
              <Flex align='center' mb='18px'>
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Type:{" "}
                  </Text>
                  <Select
                    name="subcontractorType"
                    className="selectSubContractorType"
                    defaultValue={selectedSubcontractorType()}
                    options={subcontractorTypeOptions()}
                    placeholder="Vælg type..."
                    closeMenuOnSelect={true}
                    onChange={(obj) => updateData('subcontractorType', obj.value)}
                  />
                </Flex>
              <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Sprog:{" "}
                  </Text>
                  <Select
                    name="subcontractorType"
                    className="selectSubContractorType"
                    defaultValue={selectedLanguage()}
                    options={languageOptions()}
                    placeholder="Vælg sprog..."
                    closeMenuOnSelect={true}
                    onChange={(obj) => updateData('language', obj.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Booking e-mail:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="bookingEmail"
                    defaultValue={data.subcontractor.bookingEmail}
                    onBlur={(e) => updateData('bookingEmail', e.target.value)}
                  />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={2} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
              Noter
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"}>
                  <Textarea
                    height={"155px"}
                    name="name"
                    defaultValue={addLinebreaks(data.subcontractor.note)}
                    onBlur={(e) => updateData("note", e.currentTarget.value)}
                  />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem colSpan={4} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Standard besked
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"100px"} me="10px" whiteSpace={"nowrap"}>
                    Emne:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="mailSubject"
                    defaultValue={data.subcontractor.mailSubject}
                    onBlur={(e) => updateData('mailSubject', e.target.value)}
                  />
                </Flex>
                <Flex align="top" mb="18px" >
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"100px"} me="10px" whiteSpace={"nowrap"}>
                    Besked:{" "}
                  </Text>
                  <Editor id="email_123" value={data.subcontractor.mailText} changed={(e) => (updateText('mailText', e))} showFields={true} />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Flex>
  );
}

export default SubcontractorInfo;
