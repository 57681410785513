/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

//import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

import interactionPlugin, { Draggable } from "@fullcalendar/interaction"; // needed for dayClick
import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list';
import { useRef, useEffect } from 'react';

function EventCalendar(props) {
  const { calendarData, initialDate, onCreateClicked, onDateDblClicked, onEventDblClicked, onEventClicked, onDownloadClicked, onExternalItemDropped, onItemChanged, users, onSelectUserChanged } = props;
  const calendarRef = useRef(null);
  const eventTimeFormat = {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hour12: false
  }

  let clickCount = 0;
  let singleClickTimer = null;
  const handleDayClicked = (info) => {
    clickCount++;
    if (clickCount === 1) {
      singleClickTimer = setTimeout(function () {
        clickCount = 0;
        //alert('single click');
      }, 400);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      onDateDblClicked(info);
      //alert('double click');
      //console.log('double click, info:', info)
    }
  }

  const handleEventClicked = (info) => {
    clickCount++;
    if (clickCount === 1) {
      singleClickTimer = setTimeout(function () {
        clickCount = 0;
        onEventClicked(info);
      }, 400);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      onEventDblClicked(info);
      //alert('double click');
      //console.log('double click, info:', info)
    }
  }

  setTimeout(() => {
    let draggableEl = document.getElementsByClassName('draggable-calendar-event');
    for (let i = 0; i < draggableEl.length; i++) {
      new Draggable(draggableEl[i]);
    }

  }, 100);

  function handleEventReceive(info) {
    onExternalItemDropped(info.dateStr, info.draggedEl.getAttribute('data-orderid'), info.draggedEl.getAttribute('data-ordertitle'))
  }

  function handleEventChanged(info) {
    onItemChanged(info.event.startStr, info.event.endStr, info.event.id)
  }

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }
  useEffect(() => {
    // This effect will run once the component mounts
    //const calendarApi = calendarRef.current.getApi();

    if (users.length === 1) {
      console.log('only one user, hiding dropdown')
      const toolbar = document.querySelector('.fc-toolbar');
      const customButton = toolbar.querySelector('.fc-customButton-button');
      customButton.style.display = 'none';
      console.log('customButton:', customButton);
      onSelectUserChanged(users[0].id);
      return;
      //
    }


    const customDropdown = document.createElement('select');
    customDropdown.className = 'calenderUserSelect';

    let options = '<option value="0">Vis alle aftaler</option>';
    for (var i = 0; i < users.length; i++) {
      if (localStorage.getItem("dashboard_calenderSelectedUser") === users[i].id) {
        options += `<option value="${users[i].id}" selected>${users[i].name}</option>`;
      } else {
        options += `<option value="${users[i].id}">${users[i].name}</option>`;
      }
    }
    customDropdown.innerHTML = options;
    customDropdown.addEventListener('change', (event) => {
      onSelectUserChanged(event.target.value);
    });

    const toolbar = document.querySelector('.fc-toolbar');
    const customButton = toolbar.querySelector('.fc-customButton-button');
    if (customButton) {
      customButton.replaceWith(customDropdown);
    }
  }, []);

  const handleCustomButtonClick = () => {
    console.log('Custom button clicked!');
  };

  const handleAfterAllRender = (view) => {
    console.log('handleAfterAllRender - view:', view);
  }

  let initialView = "dayGridMonth";
  if (localStorage.getItem("dashboard_calenderView")) {
    initialView = localStorage.getItem("dashboard_calenderView");
  }

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
      headerToolbar={{
        start: 'title', // will normally be on the left. if RTL, will be on the right
        center: '',
        end: 'customButton downloadCals myCustomButton dayGridMonth,dayGridWeek,dayGridDay prev,next' // will normally be on the right. if RTL, will be on the left
      }}
      initialView={initialView}
      contentHeight="600"
      events={calendarData}
      initialDate={initialDate}
      editable={true}
      droppable={true}
      drop={handleEventReceive}
      eventDrop={handleEventChanged}

      selectable={false}
      height="100%"
      locales={allLocales}
      locale={'dk'}
      firstDay={1}
      weekNumbers={true}
      weekText={''}
      displayEventTime={true}
      displayEventEnd={true}
      eventTimeFormat={eventTimeFormat}

      buttonText={{
        today: 'I dag',
        month: 'Måned',
        week: 'Uge',
        day: 'Dag',
        list: 'Liste'
      }}


      customButtons={{

        downloadCals: {
          text: 'Hent kalender',
          click: onDownloadClicked,
        },
        myCustomButton: {
          text: 'Opret aftale',
          click: onCreateClicked,
        },

        customButton: {
          text: 'Custom Dropdown',
          click: handleCustomButtonClick
        }
        /*
             showMyItemsButton: {
               text: 'Vis mine aftaler',
               click: onShowMyItemsClicked,
             },
        */

      }}
      dateClick={function (info) {
        handleDayClicked(info);
      }}
      eventClick={function (info) {
        handleEventClicked(info);
      }

      }
      eventContent={renderEventContent}
      datesSet={function (info) {
        localStorage.setItem("dashboard_calenderView", info.view.type);
      }}

    />
  );
}

export default EventCalendar;
