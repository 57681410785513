/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Button } from "@chakra-ui/react";
import React from "react";
import ReactQuill from "react-quill";




const CustomToolbarSimple = (props) => {

  return (
    <Box
      id={props.customId}
      borderTopStartRadius="15px"
      borderTopEndRadius="15px"
      borderBottom="0px solid transparent !important"
    >

{props.showFields && (
      <select className="ql-placeholder" placeholder="Vælg flet felt" style={{ width: '150px' }} >
        <option value="[CaseNo]">Sags nr.</option>
        <option value="[EstateType]">Ejendommen - type</option>
        <option value="[EstateAddress]">Ejendommen - vej og hus nr.</option>
        <option value="[EstateZipCodeAndCite]">Ejendommen - post nr. og by</option>
        <option value="[EstateFloorCount]">Ejendommen - antal etager</option>
        <option value="[EstateRoomCount]">Ejendommen - antal rum</option>
        <option value="[EstateRoomCount]">Ejendommen - bolig areal</option>
        <option value="[EstateRoomCount]">Ejendommen - erhvervs areal</option>
        <option value="[EstateRoomCount]">kontaktperson - Navn</option>
        <option value="[EstateRoomCount]">kontaktperson - Telefon nr.</option>
        <option value="[EstateRoomCount]">kontaktperson - E-mail</option>

        <option value="[EstateRoomCount]">Mægler - butiksnavn</option>
        <option value="[EstateRoomCount]">Mægler - cvr</option>
        <option value="[EstateRoomCount]">Mægler - vej og hus nr.</option>
        <option value="[EstateRoomCount]">Mægler - post nr. og by</option>

        <option value="[EstateRoomCount]">Mægler kontakt - navn</option>
        <option value="[EstateRoomCount]">Mægler kontakt - telefon nr.</option>
        <option value="[EstateRoomCount]">Mægler kontakt - e-mail</option>

        <option value="[EstateRoomCount]">Fakturering - firmanavn</option>
        <option value="[EstateRoomCount]">Fakturering - cvr</option>
        <option value="[EstateRoomCount]">Fakturering - vej og hus nr.</option>
        <option value="[EstateRoomCount]">Fakturering - post nr. og by</option>

        <option value="[OrderDate]">Ordre - dato</option>
        <option value="[EstateRoomCount]">Ordre - note</option>

        <option value="[EstateRoomCount]">Ordre - Produkt navn</option>
        <option value="[EstateRoomCount]">Ordre - Produkt pris</option>

        <option value="[EstateRoomCount]">Ordre - Produkt (tilvalg) navn</option>
        <option value="[EstateRoomCount]">Ordre - Produkt (tilvalg) pris</option>


        <option value="[EstateRoomCount]">Ordre - Fotograferings dato</option>
        <option value="[EstateRoomCount]">Ordre - Fotograferings tidspunkt</option>

        <option value="<a href='[Link]' >Klik her for at hente vores klargøringsbrochuren</a>">Link til klargøringsbrochure</option>

        
      </select>
)}
    </Box>
  )
};


const CustomToolbar = (props) => {
 
  return (
    <Box
      id={props.customId}
      borderTopStartRadius="15px"
      borderTopEndRadius="15px"
      borderBottom="0px solid transparent !important"
    >

      <Button
        display="flex !important"
        justifyContent="center !important"
        alignItems="center !important"
        me="5px !important"
        className="ql-bold"
      ></Button>
      <Button
        display="flex !important"
        justifyContent="center !important"
        alignItems="center !important"
        me="5px !important"
        className="ql-italic"
      ></Button>
      <Button
        display="flex !important"
        justifyContent="center !important"
        alignItems="center !important"
        me="5px !important"
        className="ql-underline"
      ></Button>
      <Button
        display="flex !important"
        justifyContent="center !important"
        alignItems="center !important"
        me="5px !important"
        className="ql-list"
        value="ordered"
      ></Button>
      <Button
        display="flex !important"
        justifyContent="center !important"
        alignItems="center !important"
        className="ql-list"
        value="bullet"
      ></Button>

      <select className="ql-align" />
      <Button
        display="flex !important"
        justifyContent="center !important"
        alignItems="center !important"
        className="ql-link"
        value="link"
      ></Button>

      <select className="ql-header" />


      {props.showFields && (
      <select className="ql-placeholder" placeholder="Vælg flet felt" style={{ width: '150px' }} >
        <option value="{{_sellerName_}}">Kontaktperson - navn</option>
        <option value="{{_sellerPhone_}}">Kontaktperson - telefon nr.</option>
        <option value="{{_sellerEmail_}}">Kontaktperson - e-mail</option>
        <option value="{{_sellerType_}}">Kontaktperson - type</option>
        <option value="{{_billingName_}}">Fakturering - firmanavn</option>
        <option value="{{_billingCvr_}}">Fakturering - cvr</option>
        <option value="{{_billingAddress_}}">Fakturering - vej og hus nr.</option>
        <option value="{{_billingZipCode_}}">Fakturering - post nr.</option>
        <option value="{{_billingCity_}}">Fakturering - by</option>
        <option value="{{_customerName_}}">Mægler - butiksnavn</option>
        <option value="{{_customerCvr_}}">Mægler - cvr</option>
        <option value="{{_customerAddress_}}">Mægler - vej og hus nr.</option>
        <option value="{{_customerZipCode_}}">Mægler - post nr.</option>
        <option value="{{_customerCity_}}">Mægler - by</option>
        <option value="{{_estateAgentName_}}">Mægler kontakt - navn</option>
        <option value="{{_estateAgentPhone_}}">Mægler kontakt - telefon nr.</option>
        <option value="{{_estateAgentEmail_}}">Mægler kontakt - e-mail</option>
        <option value="{{_orderTotalPrice_}}">Ordre - total pris</option>
        <option value="{{_caseNo_}}">Ordre - Sags nr.</option>
        <option value="{{_estateType_}}">Ordre - ejendomstype</option>
        <option value="{{_estateAddress_}}">Ordre - Ejendommens vej og hus nr.</option>
        <option value="{{_estateZipcode_}}">Ordre - Ejendommens - post nr.</option>
        <option value="{{_estateCity_}}">Ordre - Ejendommens - by</option>
        <option value="{{_estateFloorCount_}}">Ordre - Ejendommens etage antal</option>
        <option value="{{_estateRoomCount_}}">Ordre - Ejendommens antal rum</option>
        <option value="{{_estateLivingArea_}}">Ordre - Ejendommens bolig areal</option>
        <option value="{{_estateBusinessArea_}}">Ordre - Ejendommens erhvervs areal</option>
        <option value="{{_orderNote_}}">Ordre - note</option>
        <option value="{{_nextPhotoshootDate_}}">Ordre - næste fotograferingsdato</option>
        <option value="{{_nextPhotoshootTime_}}">Ordre - næste fotograferingstidspunkt</option>
        <option value="{{_orderReceivedDate_}}">Ordre - modtaget dato</option>
        <option value="{{_orderReceivedTime_}}">Ordre - modtaget tidspunkt</option>
        <option value="{{#products}}<p><strong>Produkt:</strong> {{_productName_}}</p><p><strong>Pris:</strong> {{_productPrice_}}</p><p><strong>Beskrivelse:</strong> {{_productDescription_}}</p>{{/products}} {{#if extraServiceProducts.length}}{{#extraServiceProducts}}<p><strong>Produkt:</strong> {{_productName_}}</p><p><strong>Pris:</strong> {{_productPrice_}}</p><p><strong>Beskrivelse:</strong> {{_productDescription_}}</p>{{/extraServiceProducts}}{{/if}}">Ordre - produkter</option>
        <option value="<a href='{{_linkToBrochure_}}'>Link til klargøringsbrochure</a>">Link til klargøringsbrochure</option>
        <option value="{{_linkToAuther_}}">Link til sagsmateriale (Tekstforfatter)</option>
        <option value="{{_linkToMediaProcesser_}}">Link til sagsmateriale (Mediabehandler)</option>
        <option value="{{_linkToMediaViewer_}}">Link til billedefremviser</option>
      </select>
      )}
    </Box>
  )
};

export default class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(html) {
    this.setState({ editorHtml: html });
    if (this.props.changed) {
      //this.props.editorValue = this.state.editorHtml;
      this.props.changed(this.state.editorHtml);
    }
  }

  modules = {
    toolbar: {
      container: "#toolbar_" + this.props.id,
      handlers: {
        "placeholder": function (value) {
          if (value) {
            const cursorPosition = this.quill.getSelection().index;
            this.quill.clipboard.dangerouslyPasteHTML(cursorPosition, value);
            //this.quill.insertText(cursorPosition, value);
            this.quill.setSelection(cursorPosition + value.length);
          }
        }
      }
    },
    clipboard: {
      matchVisual: false,
    },
  };

  render() {
    return (
      <div id={this.props.id} className="text-editor" style={{ "width": "100%" }}>
        {this.props.type === 'simple' ? (
          <CustomToolbarSimple customId={'toolbar_' + this.props.id} showFields={this.props.showFields} />
        ) : (
          <CustomToolbar customId={'toolbar_' + this.props.id} showFields={this.props.showFields} />
        )}
        <ReactQuill
          id={'editor_' + this.props.id}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          defaultValue={this.props.value}
          modules={this.modules}

        />
      </div>
    );
  }
}


Editor.modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      "placeholder": function (value) {
        if (value) {
          const cursorPosition = this.quill.getSelection().index;
          this.quill.clipboard.dangerouslyPasteHTML(cursorPosition, value);
          //this.quill.insertText(cursorPosition, value);
          this.quill.setSelection(cursorPosition + value.length);
        }
      }
    }
  },
  clipboard: {
    matchVisual: false,
  },
};


/*
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];
*/