// Chakra imports
import { Box, Flex, Text, useColorModeValue} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card.js";
import CardHeader from "./../../../../components/Card/CardHeader.js";
import LineChart from "./../../../../components/Charts/LineChart";

const LeadprMonth = ({ data }) => {

  const dates = data.map(item => item.month)
  const leadCount = data.map(item => item.count)

   const lineChartDataDefault = [
    {
      name: "Leads",
      data: leadCount,
    }
  ];
  
   const lineChartOptionsDefault = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "string",
      categories: dates,
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#A0AEC0", "#2D3748"],
    },
    colors: ["#A0AEC0", "#2D3748"],
  };



  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card p='28px 10px 16px 0px' mb={{ sm: "26px", lg: "0px" }}>
      <CardHeader mb='0px' pl='22px'>
        <Flex direction='column' alignSelf='flex-start'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
            Ordrer pr. måned seneste år
          </Text>
        </Flex>
      </CardHeader>
      <Box w='100%' h={{ sm: "150px" }} ps='8px'>
        <LineChart
              chartData={lineChartDataDefault}
              chartOptions={lineChartOptionsDefault}
            />
      </Box>
    </Card>
  );
};

export default LeadprMonth;
