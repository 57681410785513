// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
//import { FaCube, FaPenFancy } from "react-icons/fa";
import { FaRegEnvelope, FaPlus, FaArrowLeft  } from "react-icons/fa";




interface IProps {
  backgroundHeader: string
  backgroundProfile: string
  name: string
  description: string
  onCreateUserClicked: () => void
  showCreateUserButton: boolean
  showBackButton: boolean
  showUserSentWellcomesMessageButton: boolean
  onSentWellcomesMessageClicked: () => void
  customButtonShow?: boolean
  customButtonIcon?: any
  customButtonOnClick?: () => void
  customButtonText?: string

}

const SettingsHeader: React.FC<IProps> = ({ backgroundHeader,
  backgroundProfile,
  name,
  description,
  onCreateUserClicked,
  showCreateUserButton,
  showBackButton,
  showUserSentWellcomesMessageButton,
  onSentWellcomesMessageClicked,
  customButtonShow,
  customButtonIcon,
  customButtonOnClick,
  customButtonText, }) => {

/*
const SettingsHeader = ({
  backgroundHeader,
  backgroundProfile,
  name,
  description,
  onCreateUserClicked,
  showCreateUserButton,
  showBackButton,
  showUserSentWellcomesMessageButton,
  onSentWellcomesMessageClicked,
  customButtonShow,
  customButtonIcon,
  customButtonOnClick,
  customButtonText,
}) => {
 */
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const borderProfileColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );
  const emailColor = useColorModeValue("gray.400", "gray.300"); 
 
  return (
    <Box
      mb={{ sm: "0px", md: "75px", xl: "70px" }}
      borderRadius="15px"
      px="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box
        bgImage={backgroundHeader}
        filter="grayscale(1)"
        w="100%"
        h="200px"
        borderRadius="25px"
        bgPosition="50%"
        bgRepeat="no-repeat"
        position="relative"
        display="flex"
        justifyContent="center"
      >
        <Flex
          direction={{ sm: "column", md: "row" }}
          mx="1.5rem"
          maxH="330px"
          w={{ sm: "90%", xl: "95%" }}
          justifyContent={{ sm: "center", md: "space-between" }}
          align="center"
          backdropFilter="saturate(200%) blur(50px)"
          position="absolute"
          boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
          border="2px solid"
          borderColor={borderProfileColor}
          bg={backgroundProfile}
          p="24px"
          borderRadius="20px"
          transform={{
            sm: "translateY(45%)",
            md: "translateY(70%)",
            lg: "translateY(70%)",
          }}
        >
          <Flex
            align="center"
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "row" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}
          >
            
            <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
              <Text
                fontSize={{ sm: "lg", lg: "xl" }}
                color={textColor}
                fontWeight="bold"
                ms={{ sm: "8px", md: "0px" }}
              >
                {name}
              </Text>
              <Text
                fontSize={{ sm: "sm", md: "md" }}
                color={emailColor}
                fontWeight="semibold"
              >
                {description}
              </Text>
              {showBackButton && (
              <Text
                fontSize={"12px"}
                color={textColor}
                fontWeight="bold"
                cursor="pointer"
                onClick={() => window.history.back()}
                style={{ position: "absolute", top: "109px"}}
              >
                <Icon as={FaArrowLeft} me="6px" style={{ position: "relative", top: "1px"}} /> Tilbage
              </Text>
              )}
            </Flex>
          </Flex>
    
          {customButtonShow && (
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            >
              <Button
                p="0px"
                bg="transparent"
                _hover={{ bg: "none" }}
                variant="transparent-with-icon"
              >
                <Flex
                  align="center"
                  w={{ sm: "100%", lg: "100%" }}
                  transition="all .5s ease"
                  borderRadius="15px"
                  justifyContent="center"
                  py="10px"
                  cursor="pointer"
                  onClick={customButtonOnClick}
                >
                  <Icon as={customButtonIcon} me="6px" />
                  <Text fontSize="xs" color={textColor} fontWeight="bold">
                    {customButtonText}
                  </Text>
                </Flex>
              </Button>
            </Flex>
          )}
          <Flex
            direction={{ sm: "column", lg: "row" }}
            w={{ sm: "100%", md: "50%", lg: "auto" }}
          >
            {showCreateUserButton && (
            <Button
              p="0px"
              bg="transparent"
              _hover={{ bg: "none" }}
              variant="transparent-with-icon"
            >
              <Flex
                align="center"
                w={{ sm: "100%", lg: "135px" }}
                transition="all .5s ease"
                borderRadius="15px"
                justifyContent="center"
                py="10px"
                cursor="pointer"
                onClick={() =>
                  onCreateUserClicked()
                }
              >
                <Icon as={FaPlus} me="6px" />
                <Text fontSize="xs" color={textColor} fontWeight="bold">
                  Opret ny bruger
                </Text>
              </Flex>
            </Button>
            )}
            {showUserSentWellcomesMessageButton && (
            <Button
              p="0px"
              bg="transparent"
              _hover={{ bg: "none" }}
              variant="transparent-with-icon"
            >
              <Flex
                align="center"
                w={{ sm: "100%", lg: "175px" }}
                transition="all .5s ease"
                borderRadius="15px"
                justifyContent="center"
                py="10px"
                cursor="pointer"
                onClick={() =>
                  onSentWellcomesMessageClicked()
                }
              >
                <Icon as={FaRegEnvelope} me="6px" />
                <Text fontSize="xs" color={textColor} fontWeight="bold">
                  Send velkomstbesked til bruger
                </Text>
              </Flex>
            </Button>
            )}


          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default SettingsHeader;
