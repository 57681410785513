// Chakra imports
import {
    Flex, useColorModeValue, Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Input,
    Select,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../components/SettingsHeader";
import React from "react";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import SettleTable from "./../../../../components/Tables/SettleTable";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../../../auth-context/auth.context";

const SUBCONTRACTORS_GQL = gql`
query subcontractors {
    subcontractors {
        id
        name
        bookingEmail
        subcontractorType
        createdAt
        isGlobal
    }
}`

const DELETE_SUBCONTRACTOR_GQL = gql`
mutation deleteSubcontractor($id: ID!){
    deleteSubcontractor(id:$id)
}`

const CREATE_SUBCONTRACTOR_GQL = gql`
mutation createSubcontractor($input: CreateSubcontractorInput!){
    createSubcontractor(input:$input){
        id
    }
}`

enum SubcontractorType { // eslint-disable-line
    AUTHER = "AUTHER", // eslint-disable-line
    MEDIA_PROCESSOR = "MEDIA_PROCESSOR", // eslint-disable-line
}

const CreateSubcontractorObj = {
    name: '' as String,
    bookingEmail: '' as String,
    subcontractorType: 'AUTHER' as SubcontractorType
}

function Subcontractors() {
    const toast = useToast()
    const userData = useAuth()
    const history = useHistory();
    const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
    const [validate, setValidate] = React.useState(false);
    const [selectedSubcontractorId, setSelectedSubcontractorId] = React.useState('');

    const [createSubcontractorData, setCreateSubcontractorData] = React.useState(CreateSubcontractorObj);
    const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
    const { isOpen: createSubcontractorIsOpen, onOpen: createSubcontractorOnOpen, onClose: createSubcontractorOnClose } = useDisclosure();

    //Colors
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [deleteSubcontractor] = useMutation(
        DELETE_SUBCONTRACTOR_GQL,
        {
            refetchQueries: [SUBCONTRACTORS_GQL]
        }
    )

    const [createNewSubcontractor] = useMutation(
        CREATE_SUBCONTRACTOR_GQL,
        {
            refetchQueries: [SUBCONTRACTORS_GQL]
        }
    )

    const { loading, error, data } = useQuery(SUBCONTRACTORS_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting subcontractor from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting subcontractor from server (2)</p>);
    }

    const deleteSubcontractorClicked = (obj) => {
        setSelectedSubcontractorId(obj.id)
        deleteWarningOnOpen();
    }

    const deleteSubcontractorConfirmed = () => {
        deleteSubcontractor({ variables: { id: selectedSubcontractorId } } as any);
        deleteWarningOnClose();
    }

    const columns = [
        {
            header: "",
            accessor: "id",
            hidden: true
        },
        {
            header: "",
            accessor: "isGlobal",
            hidden: true
        },
        {
            header: "Oprettet",
            accessor: "createdAt",
            onClick: rowClicked
        },
        {
            header: "Navn",
            accessor: "name",
            onClick: rowClicked
        },
        {
            header: "E-mail",
            accessor: "bookingEmail",
            onClick: rowClicked
        },
        {
            header: "Type",
            accessor: "typeText",
            onClick: rowClicked
        },
        {
            header: "Slet",
            icon: "delete",
            alignHeader: "center",
            alignData: "right",
            width: 100,
            accessor: "delete",
            onClick: deleteSubcontractorClicked,
        }
    ];

    function rowClicked(obj) {

        history.push('/admin/setting/subcontractor/' + obj.id);

    }

    const createSubcontractor = () => {
        if (CreateSubcontractorObj.name === '') {
            setValidate(true)
        } else {
            createNewSubcontractor({
                variables: { input: CreateSubcontractorObj } as any
            }).then(() => {
                createSubcontractorOnClose()
            }).catch((() => {
                toast({
                    title: 'Oprettelse af leverandøren fejlede.',
                    description: "Det var ikke muligt at oprette leverandøren.",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            })
            );
        }
    }

    //create obj for table component
    let tableData = JSON.parse(JSON.stringify(data.subcontractors));
    for (var i = 0; i < tableData.length; i++) {
        tableData[i].createdAt = moment(tableData[i].createdAt).format('DD-MM-YYYY');

        switch (tableData[i].subcontractorType) {
            case 'AUTHER':
                tableData[i].typeText = 'Forfatter'
                break;
            case 'MEDIA_PROCESSOR':
                tableData[i].typeText = 'Mediebehandler'
                break;
            default:
                break;
        }
    }

    //remove global subcontractor

    if (userData.user.userType !== 'ADMIN') {
        tableData = tableData.filter(function (el) {
            return !el.isGlobal;
        });
    }

    const createSubcontractorClicked = () => {
        CreateSubcontractorObj.name = '';
        CreateSubcontractorObj.subcontractorType = SubcontractorType.AUTHER;
        setCreateSubcontractorData(CreateSubcontractorObj)
        setValidate(false)
        createSubcontractorOnOpen();
    }

    const updateData = (key, value) => {
        CreateSubcontractorObj[key] = value;
        setCreateSubcontractorData(CreateSubcontractorObj)
        setValidate(false)
    }

    return (
        <div>
            <Flex direction="column">
                <SettingsHeader
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Leverandører"}
                    description={'Opret og rediger leverandører i systemet'}
                    showCreateUserButton={false}
                    onCreateUserClicked={() => { }}
                    showBackButton={false}
                    showUserSentWellcomesMessageButton={false}
                    onSentWellcomesMessageClicked={() => { }}
                />

                <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                    <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">

                        <Flex direction="column">
                            <Text fontSize="lg" color={textColor} fontWeight="bold">
                                Leverandører
                            </Text>
                        </Flex>
                        <Flex direction="column">
                            <Text
                                color={textColor}
                                fontSize="12px"
                                fontWeight="bold"
                                mb="6px"
                                cursor="pointer"
                                onClick={() => {
                                    createSubcontractorClicked();
                                }}
                            >
                                Opret ny leverandør
                            </Text>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <SettleTable data={tableData} columnsData={columns} setDragData={false} csvData={[]} enableSelect={false} />
                    </CardBody>
                </Card>
            </Flex>


            <Modal onClose={createSubcontractorOnClose} size="xl" isOpen={createSubcontractorIsOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Opret ny leverandør</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Du er ved at oprettet en ny leverandør, udfyld nedenstående og klik opret.</Text>
                        <br /><br />
                        <Text fontSize='md' fontWeight='bold'>Leverandør:</Text>

                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Type:{" "}
                                </Text>
                                <Select multiple={false} onChange={(e) => { updateData('subcontractorType', e.target.value) }}>
                                    <option key={'AUTHER'} value={'AUTHER'}>Forfatter</option>
                                    <option key={'MEDIA_PROCESSOR'} value={'MEDIA_PROCESSOR'}>Mediebehandler</option>
                                </Select>
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Navn:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name="name"
                                    onKeyUp={(e) => { updateData('name', e.currentTarget.value) }}
                                    className={validate && createSubcontractorData.name === '' ? 'error' : ''}
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Booking e-mail:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name="bookingEmail"
                                    onKeyUp={(e) => { updateData('bookingEmail', e.currentTarget.value) }}
                                    className={validate && createSubcontractorData.bookingEmail === '' ? 'error' : ''}
                                />
                            </Flex>
                        </Flex>




                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createSubcontractorOnClose}>Fortryd</Button>
                        <Button colorScheme='green' onClick={createSubcontractor} ml={3}>Opret</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Slet leverandør
                        </AlertDialogHeader>

                        <AlertDialogBody>Du er ved at slette en leverandør. <br />Er du sikker?</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={deleteWarningOnClose}>
                                Nej, behold
                            </Button>
                            <Button colorScheme="red" onClick={deleteSubcontractorConfirmed} ml={3}>
                                Ja, jeg er sikker
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </div>
    );
}

export default Subcontractors;
