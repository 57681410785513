// Chakra imports
import { Flex, Text,Icon, useColorModeValue, Input, useToast } from "@chakra-ui/react";
// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";
import { FaRegEnvelope } from "react-icons/fa";

const updatePhotographyCompanyInfoGql = gql`
  mutation UpdatePhotographyCompanyInput($input: UpdatePhotographyCompanyInput!) {
    updatePhotographyCompany(input: $input) {
      id
    }
  }
`;

const validateSMTP = gql`
  mutation validateSMTP {
    validateSMTP {
      isValid
      error
    }
  }
`;


const ProfileSmtpSetting = ({ data }) => {
  const toast = useToast();
  const [updateCustomerInfo] = useMutation(updatePhotographyCompanyInfoGql);
  const [testSmtpInfo] = useMutation(validateSMTP);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (field, value) => {
    const dataObj = {}

    dataObj['id'] = data.id;
    dataObj[field] = value;

    if (field === 'emailPassword' && value === '********') {
      return;
    }

    updateCustomerInfo({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  const handleTestSmtpClicked = () => {

    testSmtpInfo().then((res) => {
      if (res.data.validateSMTP.isValid) {
        toast({
          title: "STMP indstillingerne virker.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "STMP indstillingerne virker ikke.",
          description: res.data.validateSMTP.error,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }

    }).catch((err) => {
      toast({
        title: "STMP indstillingerne virker ikke.",
        description: err.message,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    });
    
  }

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
      <CardHeader p="12px 5px" mb="12px" display="flex" justifyContent="space-between">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Mail opsætning (Avanceret)
        </Text>
        <div style={{display:'flex', alignItems: 'center'}}>
        <Icon as={FaRegEnvelope} me="6px" />
        <Text fontSize="sm" color={textColor} fontWeight="" cursor="pointer" onClick={handleTestSmtpClicked}>
          Test opsætningen
        </Text>
        </div>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
              Smtp server:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="emailSmtpServer"
              defaultValue={data.emailSmtpServer}
              onBlur={(e) => updateData('emailSmtpServer', e.currentTarget.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
              Smtp server port:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="emailSmtpServerPort"
              defaultValue={data.emailSmtpServerPort}
              onBlur={(e) => updateData('emailSmtpServerPort', e.currentTarget.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
              Smtp server bruger/email:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="emailUser"
              defaultValue={data.emailUser}
              onBlur={(e) => updateData('emailUser', e.currentTarget.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
              Smtp server adgangskode:{" "}
            </Text>
            <Input
              variant="flushed"
              type="password"
              name="emailPassword"
              defaultValue={'********'}
              onBlur={(e) => updateData('emailPassword', e.currentTarget.value)}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProfileSmtpSetting;
