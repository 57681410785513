import { Flex, Grid, useColorModeValue, GridItem, useDisclosure, Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter
 } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import Header from "../../../components/Headers/Header";
import { HelpCenterIdea } from "./components/HelpCenterIdea";
import { HelpCenterContainer } from "./components/HelpCenterContainer";
import { FaRegImages } from "react-icons/fa";
import { useAuth } from "../../../auth-context/auth.context";
import { useState } from "react";
import { DialogType, HelpCenterCreateEditDialog } from "./components/HelpCenterCreateEditDialog";
import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";

interface IProps {

}

const DELETE_HELP_CENTER_ITEM = gql`
  mutation deleteHelpCenterItem($id: ID!){
    deleteHelpCenterItem(id:$id)
}`

const HELP_CENTER_ITEMS = gql`
    query getHelpCenterItems {
        getHelpCenterItems {
            id
            title
            description
            itemType
            url
        }
    }
`

const HelpCenter: React.FC<IProps> = () => {

    const userData = useAuth()
    const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
    const [showHelpCenterEditDialog, setShowHelpCenterEditDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
    const { loading, error, data, refetch } = useQuery(HELP_CENTER_ITEMS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });
    const [deleteHelpCenterItem] = useMutation(
        DELETE_HELP_CENTER_ITEM,
        {
            refetchQueries: [HELP_CENTER_ITEMS],
        }
    )

     // Chakra color mode
     const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    if (loading) {
        return (<p></p>); //TODO: make error component
    }

    if (error) {
        return (<p>Error getting help center items from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting help center items from server (2)</p>);
    }

    const handleEditItemClicked = (row:any) => {
        setSelectedItem(row);
        setShowHelpCenterEditDialog(true);
    }

    const handleDeleteItemClicked = (row:any) => {
        console.log('Delete item with id:', row.id);
        setSelectedItem(row)
        deleteWarningOnOpen();
    };

    const deleteHelpCenterItemConfirmed = () => {
        deleteHelpCenterItem({ variables: { id: selectedItem.id } } as any);
        deleteWarningOnClose();
    }
   

    const handleCloseHelpCenterEditDialog = (updateUi: boolean) => {
        setShowHelpCenterEditDialog(false);

        if (updateUi) {
            refetch();
        }
    };


    return (
        <>
            <Flex direction="column">
                {['ADMIN'].includes(userData.user.userType) ? (
                    <Header buttonIcon={FaRegImages} buttonAction={() => { setSelectedItem(null);setShowHelpCenterEditDialog(true) }} buttonText={'Opret nyt punkt'} description={'Her kan du finde hjælp til hvordan du bruger systemet'} backgroundHeader={ProfileBgImage} backgroundProfile={bgProfile} name={"Hjælpecenter"} />
                ) : (
                    <Header description={'Her kan du finde hjælp til hvordan du bruger systemet'} backgroundHeader={ProfileBgImage} backgroundProfile={bgProfile} name={"Hjælpecenter"} />
                )}
                <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 2fr)" }} gap="22px">
                    <GridItem colSpan={0} rowSpan={0}>
                        <HelpCenterIdea />
                    </GridItem>
                    <GridItem colSpan={2} rowSpan={0}>
                        <HelpCenterContainer data={data.getHelpCenterItems} onDeleteItemClicked={handleDeleteItemClicked} onEditItemClicked={handleEditItemClicked} />
                    </GridItem>
                </Grid>
            </Flex>

            <HelpCenterCreateEditDialog isOpen={showHelpCenterEditDialog} onClose={handleCloseHelpCenterEditDialog} dialogType={selectedItem ? DialogType.UPDATE : DialogType.CREATE} helpCenterItemObj={selectedItem} />

            <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Slet punkt
                        </AlertDialogHeader>

                        <AlertDialogBody>Er du sikker?</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={deleteWarningOnClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={deleteHelpCenterItemConfirmed} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}


export default HelpCenter;