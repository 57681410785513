import { gql, useMutation } from "@apollo/client";
import {
    Flex, useColorModeValue, Text, Grid, GridItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Select,
    useToast
} from "@chakra-ui/react";
import { use } from "i18next";
import React, { useEffect } from "react";
import { useRef } from "react";

export enum DialogType {
    CREATE,
    UPDATE
}

interface IProps {
    onClose: any,
    dialogType: DialogType,
    helpCenterItemObj: any,
    isOpen: boolean
}


const CREATE_HELP_CENTER_ITEM = gql`
  mutation createHelpCenterItem($input: CreateHelpCenterItemInput!) {
    createHelpCenterItem(input: $input) {
        id
    }
  }
`;


const UPDATE_HELP_CENTER_ITEM = gql`
  mutation updateHelpCenterItem($input: UpdateHelpCenterItemInput!) {
    updateHelpCenterItem(input: $input) {
        id
    }
  }
`;


export const HelpCenterCreateEditDialog: React.FC<IProps> = ({ dialogType, isOpen, onClose, helpCenterItemObj }) => {

    const [createHelpCenterItem, { loading: loadingCreateHelpCenterItem, data: dataCreateHelpCenterItem }] = useMutation(CREATE_HELP_CENTER_ITEM);
    const [updateHelpCenterItem, { loading: loadingUpdateHelpCenterItem, data: dataUpdateHelpCenterItem }] = useMutation(UPDATE_HELP_CENTER_ITEM);
    const [item, setItem] = React.useState(  { title: '', description: '', mimeType: '', filename: '', fileContentBase64: '', itemType: '' });
    const inputRef = useRef(null);
    const textColor = useColorModeValue("gray.700", "white");

    const handleSelectFileClick = () => {
        inputRef.current?.click();
    };

    const handleClearFileClick = () => {
        //setShowFile(false);
        //setFile(null);
    };

    useEffect(() => {
        if (helpCenterItemObj !== null) {
            setItem({ title: helpCenterItemObj.title, description: helpCenterItemObj.description, mimeType: helpCenterItemObj.mimeType, filename: helpCenterItemObj.filename, fileContentBase64: helpCenterItemObj.fileContentBase64, itemType: helpCenterItemObj.itemType });
        }else{
            setItem({ title: '', description: '', mimeType: '', filename: '', fileContentBase64: '', itemType: '' });
        }
    }, [helpCenterItemObj]);
    


    const handleSaveClicked = () => {
        if (dialogType === DialogType.CREATE) {
            createHelpCenterItem({ variables: { input: item } }).then(() => {
                onClose(true);
            });
        } else {
            const updatedItem = {
                id: helpCenterItemObj.id,
                title: item.title,
                description: item.description,
            };
            //console.log('updatedItem', updatedItem)
            updateHelpCenterItem({ variables: { input: updatedItem } }).then(() => {
                onClose(true);
            });
        }
    }

    const handleUpdateData = (field: string, value: string) => {
        setItem({ ...item, [field]: value });
    }

    async function handleFileChange(e) {
        if (!e.target.files) {
            return;
        }

        const fileBase64 = await toBase64(e.target.files[0]);

        let itemType = 'VIDEO'
        if (e.target.files[0].type.indexOf("pdf") > -1) {
            itemType = 'PDF'
        }

        //update item with mimeType and filename
        setItem({ ...item, mimeType: e.target.files[0].type, filename: e.target.files[0].name, fileContentBase64: (fileBase64 as string), itemType: itemType });
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

        console.log('item', item)

    return (
        <Modal onClose={() => { onClose(false) }} size="xl" isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
                {dialogType === DialogType.CREATE ? <ModalHeader>Opret nyt punkt</ModalHeader> : <ModalHeader>Rediger punkt</ModalHeader>}
                <ModalCloseButton />
                <ModalBody>
                    <Text>Du er ved at oprette et nyt punkt til hjælpe centeret, udfyld nedenstående og klik gem.</Text>
                    <br />
                    <Flex direction='column' style={{ width: '100%' }}>
                        <Flex align="center" mb="18px">
                            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                Title:{" "}
                            </Text>
                            <Input
                                variant="flushed"
                                type="text"
                                name="title"
                                defaultValue={helpCenterItemObj ? helpCenterItemObj.title : ''}
                                onBlur={(e) =>
                                    handleUpdateData("title", e.target.value)
                                }
                            />
                        </Flex>
                        <Flex align="center" mb="18px">
                            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                Beskrivelse:{" "}
                            </Text>
                            <Input
                                variant="flushed"
                                type="text"
                                name="description"
                                defaultValue={helpCenterItemObj ? helpCenterItemObj.description : ''}
                                onBlur={(e) =>
                                    handleUpdateData("description", e.target.value)
                                }
                            />
                        </Flex>
                        {dialogType === DialogType.CREATE && (
                            <Flex align="center" mb="18px">
                                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                    Fil:{" "}
                                </Text>
                                {item.mimeType === '' ? (
                                    <Text fontSize="12px" fontWeight="bold" cursor="pointer" onClick={() => handleSelectFileClick()}>
                                        Klik her for at vælge en fil (MP4, PDF)
                                    </Text>
                                ) : (
                                    <Text fontSize="12px" fontWeight="bold" cursor="pointer" onClick={() => handleClearFileClick()}>
                                        {item.filename}
                                    </Text>
                                )}
                                <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} />
                            </Flex>
                        )}
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { onClose(false) }}>Fortryd</Button>
                    <Button colorScheme='green' onClick={handleSaveClicked} ml={3}>Gem</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}