// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
  Button
} from "@chakra-ui/react";

import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import OrderHeader from "../components/orderHeader"
import OrderMediaList from "../components/orderMediaList"
import { FaPaperPlane } from "react-icons/fa";
import MediaProcesserV2 from "../components/mediaProcesserV2"
import { useState } from "react";
import { SubcontractorType } from "../../../../types";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import moment from "moment";
import MessageModal, { messageType, messageViewType } from "../../../../components/MessageModal/MessageModal";


const GET_ORDER = gql`
  query order($id: ID!) {
    getOrder(id: $id) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      estateType
      estateFloorCount
      estateRoomCount
      estateLivingArea
      estateBusinessArea
      sellerName
      sellerPhone
      sellerEmail
      sellerType
      estateAgentName
      estateAgentEmail
      estateAgentPhone
      note
      internalNote
      photeshotEvent {
        id
        start
        end
      }
      products {
        id
        name
        description
      }
      customer {
        id 
        name
        address
        zipCode
        city
        email
        phoneNo
      }
      subcontractorOrders {
        id
        subcontractor {
          id
          name
          subcontractorType
        }
        status
        orderUiUrl
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
      
    }
  }
`;

function OrderMedia() {
  const { orderId } = useParams() as any;
  const textColor = useColorModeValue("gray.700", "white");
  const [showOrderMediaProcessingDialog, setshowOrderMediaProcessingDialog] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [subcontractorOrder, setSubcontractorOrder] = useState(null);

  const { loading, error, data, refetch } = useQuery(
    GET_ORDER,
    {
      variables: { id: orderId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",

    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting order from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting order from server (2)</p>;
  }

  const mediaOrders = data.getOrder.subcontractorOrders.filter((order) => order.subcontractor.subcontractorType === SubcontractorType.MEDIA_PROCESSOR);

  const handleOpenUrl = (url) => {
    window.open(url, '_blank');
  }

  const orderStatusText = (order) => {
    switch (order.status) {
      case 'SENT':
        return (<div className="subcontractorOrderStatusText"><p>Der er bestilt media behandling hos <b>{order.subcontractor.name}</b> d. {moment(order.createdAt).format('DD-MM-YYYY')}. Filerne er IKKE leveret endnu. </p>
          <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'260px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
        </div>);
      case 'DOWNLOADED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil media behandling hos <b>{order.subcontractor.name}</b>, som har åbnet ordren. </p>
          <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'260px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
        </div>);
      case 'CORRECTION_SENT':
        return (<div className="subcontractorOrderStatusText"><p>Der er sendt en koorektion til <b>{order.subcontractor.name}</b>. </p>
          <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'260px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
        </div>);
      case 'COMPLETED':
        return (<div className="subcontractorOrderStatusText"><p>Der er bestilt media behandling hos <b>{order.subcontractor.name}</b>, filerne er leveret d. {moment(order.updatedAt).format('DD-MM-YYYY')}. </p>
          <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'260px'} onClick={() => { setSubcontractorOrder(order); setShowMessageDialog(true) }}>Send besked ang. korrektur</Button>
        </div>);
      case 'REJECTED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil media behandling hos <b>{order.subcontractor.name} </b>, som har afvist ordren. </p>
          <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'260px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
        </div>);
      case 'CANCELED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil media behandling hos <b>{order.subcontractor.name} </b>, som har annulleret ordren. </p>
          <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'260px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
        </div>);
      default:
        return '';
    }
  }

  return (
    <>
    {subcontractorOrder && (
      <MessageModal isOpen={showMessageDialog} onClose={() => { setShowMessageDialog(false); refetch(); }} view={messageViewType.SentMessage} messageType={messageType.SUBCONTRACTOR_ORDER_CORRECTION} messageObject={{}} subcontractorId={subcontractorOrder.subcontractor.id} subcontractorOrderId={subcontractorOrder.id} />
    )}
      <Flex direction="column">
        <OrderHeader
          name={"Ordre - " + data.getOrder.caseNo + ' - ' + data.getOrder.estateAddress + ' - ' + data.getOrder.estateZipCode + ' ' + data.getOrder.estateCity}
          description={'Billeder og video´er'}
          onMediaButtonClicked={() => { }}
          onTextButtonClicked={() => { }}
          showMediaButton={false}
          showTextButton={false}
          showBackButton={true}
          customButtonShow={true}
          customButtonIcon={FaPaperPlane}
          customButtonOnClick={() => { setshowOrderMediaProcessingDialog(true) }}
          customButtonText={"Send materiale til redigering"}
        />

        <Grid
          templateColumns={{
            sm: "1fr",
            md: "repeat(4, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gap="22px"
        >
          <GridItem w="100%" colSpan={4} rowSpan={2}>
            {mediaOrders.length > 0 && (
              <Card p="16px" bg="#efefef">
                <CardHeader p="12px 5px" mb="12px" style={{ justifyContent: 'space-evenly', alignItems: 'flex-start', flexWrap: 'nowrap', flexDirection: 'column' }}>
                  <Text fontSize="lg" color={textColor} fontWeight="bold"  >
                    Bestillinger
                  </Text>
                </CardHeader>
                <CardBody px="5px">
                  <Flex direction="column" style={{ width: "100%" }}>
                    {mediaOrders.map((order) => (
                      <Flex align="center" mb="0px" key={order.id}>
                        <Text fontSize="md" color={textColor} width={"100%"} minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                          {orderStatusText(order)}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </CardBody>
              </Card>
            )}
          </GridItem>

          <GridItem w="100%" colSpan={4} rowSpan={2}>
            <OrderMediaList orderId={data.getOrder.id} />
          </GridItem>
        </Grid>
      </Flex>

      <MediaProcesserV2 isOpen={showOrderMediaProcessingDialog} onClose={() => { setshowOrderMediaProcessingDialog(false); refetch(); }} orderId={data.getOrder.id} />
    </>
  );
}
export default OrderMedia;