// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import CustomerHeader from "./../components/customerHeader";
import { useParams } from "react-router-dom";
import { useQuery,useMutation, gql } from "@apollo/client";
import CustomerInfo from "../components/customerInfo";
import CustomerIntegrations from "../components/customerIntegrations";
import CustomerNotes from "../components/customerNotes";
import CustomerDocument, { DocumentType } from "../components/customerDocument";
import CustomerMainPhotografer from "../components/customerMainPhotografer";
//import EstateBrokerInfo from "./../components/estateBrokerInfo"
//import EstateBrokerNotes from "./../components/estateBrokerNotes"
//import EstateBrokerAddress from "./../components/estateBrokerAddress"
//import EstateBrokerDocuments from "./../components/estateBrokerDocuments"
//import EstateBrokerBankAccountInfo from "./../components/estateBrokerBankAccountInfo"

const GET_CUSTOMER = gql`
query GET_CUSTOMER($id: ID!){
  getCustomer(id:$id){
    id
    name
    address
    zipCode
    city
    cvr
    email
    phoneNo
    contactPersonName
    mwShopId
    mwSystemName
    leadhubEnabled
    note
    token
    createdAt
    mainPhotographerUser{
      id
    }
    documents {
      id
      filename
      orgDocStorageKey
      orgDocStorageUrl
      documentType
    }
  }
}`

const SENT_WELCOMEMAIL_CUSTOMER = gql`
  mutation sentCustomerWelcomeMail($customerId: ID!) {
    sentCustomerWelcomeMail(customerId: $customerId)
  }
`;

function Info() {
  // Chakra color mode
  const toast = useToast();
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { customerId } = useParams() as any;
  const [sentWelcomeMail] = useMutation(SENT_WELCOMEMAIL_CUSTOMER);

  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER, {
    variables: { id: customerId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting customer from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting customer from server (2)</p>);
  }

  function forceRerender() {
    refetch()
  }

  const handleSentWelcomeMailClicked = () => {
    sentWelcomeMail({ variables: { customerId: customerId } }).then(() => {
      toast({
        title: "Der er nu sendt en velkomst mail til kunden.",
        description: "",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, der blev IKKE sendt en velkomst mail til kunden.",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  return (
    <Flex direction="column">
      <CustomerHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={data.getCustomer.name}
        description={"Overblik"}
        customerId={data.getCustomer.id}
        customerToken={data.getCustomer.token}
        onSentWellcomesMessageClicked={handleSentWelcomeMailClicked}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} gap="24px">
        <GridItem colSpan={1} rowSpan={2} >
          <CustomerInfo data={data.getCustomer} />
        </GridItem>

        <GridItem colSpan={0} rowSpan={0} >
          <CustomerIntegrations data={data.getCustomer} />
        </GridItem>
        <GridItem colSpan={0} rowSpan={0} >
          <CustomerMainPhotografer data={data.getCustomer} />
        </GridItem>
        <GridItem colSpan={0} rowSpan={2} >
          <CustomerNotes data={data.getCustomer} />
        </GridItem>

        <GridItem colSpan={0} rowSpan={0} >
          <CustomerDocument data={data.getCustomer} forceRerender={forceRerender} documentType={DocumentType.CONTRACT} heading="Samarbejdsaftale" allowMultiple={false} />
        </GridItem>

        <GridItem colSpan={0} rowSpan={0} >
          <CustomerDocument data={data.getCustomer} forceRerender={forceRerender} documentType={DocumentType.PROPARATION_BROCHURE} heading="Klargøringsbrochure" allowMultiple={false} />
        </GridItem>

      </Grid>
    </Flex>
  );
}

export default Info;
