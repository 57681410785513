// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Select,
  useDisclosure
} from "@chakra-ui/react";
// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import SettleTable from "../../../../../components/Tables/SettleTable";

const GET_PRODUCTS_GQL = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      subProducts {
        id
        name
      }
    }
    getExtraServiceProducts {
      id
      name
    }
  }
`;

const ADD_PRODUCT_GQL = gql`
  mutation addExtraServiceProduct($input: AddExtraServiceProductInput!) {
    addExtraServiceProduct(input: $input)
  }
`;

const REMOVE_PRODUCT_GQL = gql`
  mutation removeExtraServiceProduct($input: RemoveExtraServiceProductInput!) {
    removeExtraServiceProduct(input: $input)
  }
`;



const AddExtraServiceProductsList = ({ productId }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [seletedRow, setSeletedRow] = React.useState<any>();
  const [seletedProductId, setSeletedProductId] = React.useState("");
  const cancelRefAddDialog = React.useRef(null);
  const cancelRefRemoveDialog = React.useRef(null);
  const { isOpen: isOpenAddDialog, onOpen: onOpenAddDialog, onClose: onCloseAddDialog } = useDisclosure();
  const { isOpen: isOpenRemoveDialog, onOpen: onOpenRemoveDialog, onClose: onCloseRemoveDialog } = useDisclosure();
  const [addProduct] = useMutation(ADD_PRODUCT_GQL, { refetchQueries: [GET_PRODUCTS_GQL] });
  const [removeProduct] = useMutation(REMOVE_PRODUCT_GQL, { refetchQueries: [GET_PRODUCTS_GQL] });

  const { loading, error, data } = useQuery(
    GET_PRODUCTS_GQL,
    {
      variables: { id: productId },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting partner products from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting partner products from server (2)</p>;
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: removeClicked,
    },
  ];

  function rowClicked(obj) {
    console.log("rowClicked - obj:", obj);
  }

  function removeClicked(obj) {
    setSeletedRow(obj);
    onOpenRemoveDialog();
  }

  const removeProductClicked = () => {
    removeProduct({
      variables: {
        input: {
          productId: productId,
          extraServiceProductId: seletedRow.id
        }
      },
    });
    onCloseRemoveDialog();
  };

  const showAddModal = () => {
    onOpenAddDialog();
  };

  const addProductClicked = () => {
    let inputData = {} as any;
    inputData.productId = productId;
    inputData.extraServiceProductId = seletedProductId;

    addProduct({
      variables: { input: inputData },
    });

    onCloseAddDialog();
  };

  if (seletedRow) {
    for (let i = 0; i < data.getProduct.subProducts.length; i++) {
      if (data.getProduct.subProducts[i].id === seletedRow.id) {
        if (seletedRow === data.getProduct.subProducts[i]) {
          console.log("Objecterne er ens");
        } else {
          console.log("Objecterne er FORSKELLIGE");
          setSeletedRow(data.getProduct.subProducts[i]);
        }
      }
    }
  }

  let products = [...data.getExtraServiceProducts];

  products.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });


  return (
    <div style={{ width: "100%" }}>
      <Card p="16px" >
        <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Tillægsprodukter
            </Text>
          </Flex>
          <Flex direction="column">
            <Text
              fontSize="12px"
              fontWeight="bold"
              cursor="pointer"
              mb="6px"
              onClick={() => {
                showAddModal();
              }}
            >
              Tilføj
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px" height={"100%"}>
          <SettleTable data={data.getProduct.subProducts} columnsData={columns} setDragData={false} csvData={[]} enableSelect={false} />
        </CardBody>
      </Card>

            <AlertDialog isOpen={isOpenRemoveDialog} leastDestructiveRef={cancelRefRemoveDialog} onClose={onCloseRemoveDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Fjern produkt.
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRefRemoveDialog} onClick={onCloseRemoveDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={removeProductClicked} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenAddDialog} leastDestructiveRef={cancelRefAddDialog} onClose={onCloseAddDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Tilføj produkt.
            </AlertDialogHeader>
            <AlertDialogBody>
              Du er ved at tilføje et produkt.
              <br />
              <br />
              Vælg product:
              <Select
                multiple={false}
                onChange={(e) => {
                  setSeletedProductId(e.target.value);
                }}
              >
                <option key={""} value="">
                  Vælg produkt
                </option>
                {data.getExtraServiceProducts.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <br />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRefAddDialog}
                onClick={() => {
                  onCloseAddDialog();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="green" onClick={addProductClicked} ml={3}>
                Opret
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default AddExtraServiceProductsList;
