// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../components/SettingsHeader";

import SubProductList from "../Products/components/subProductList";
//import CustomerInfo from "../components/customerInfo";


function ExtraServiceProducts() {
  // Chakra color mode
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  return (
    <Flex direction="column">
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Tillægsprodukter"}
        description={'Her kan du oprette og redigere dine tillægsprodukter.'}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={false}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />
      <SubProductList />
    </Flex>
  );
}

export default ExtraServiceProducts;
