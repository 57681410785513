// Chakra imports
import { Flex, useColorModeValue, Grid, GridItem } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import { useState } from "react";
import Card from  "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import Header from "./../../../components/Headers/Header";
import SettleTable from "./../../../components/Tables/SettleTable";
import DataSelectorCard from "./components/DataSelectorCard"
import { useQuery, gql } from "@apollo/client";


const REPORT_GQL = gql`
query productTurnoverReportDetailed($input: ProductTurnoverReportDetailedInput){
    productTurnoverReportDetailed(input: $input){
        productName
        extraService
        totalPrice
    }
    getCustomers{
        id
        name
    }
}`

const columns = [
    {
        header: "",
        accessor: "id",
        hidden: true
    },
    {
        header: "Produkt",
        accessor: "productName",
    },
    {
        header: "Tillægsprodukt",
        accessor: "extraService",
        width: 100
    },
    {
        header: "Omsætning",
        accessor: "totalPrice",
        width: 100
    }
];


function ProductTurnoverReportDetailed() {
    // Chakra color mode

    const today = new Date();
    const defaultfromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

    const [requestData, setrequestData] = useState({
        fromDate: defaultfromDate.toISOString().split('T')[0],
        toDate: today.toISOString().split('T')[0],
        customerIds: [],
    });

    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );



    //const { estateBrokerId } = useParams();
    const { loading, error, data } = useQuery(REPORT_GQL, {
        variables: {
            input: {
                fromDate: requestData.fromDate,
                toDate: requestData.toDate,
                customerIds: requestData.customerIds,
            }
        },
        fetchPolicy: 'network-only',
        manual: true,
    });

    if (loading) return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Omsætning fordelt på produkter"}
                description={'Detaljeret rapport over omsætning fordelt på produkter.'}
            />
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={4} rowSpan={2} >
                   
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                </CardBody>
            </Card>
        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting stats from server, error: {error}</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting stats from server (2)</p>);
    }

    const updatefn = (fromDate, toDate, customerSelected) => {
        let customerIds = [];
        if (!Array.isArray(customerSelected)) {
            if (customerSelected.indexOf(',')) {
                customerIds = customerSelected.split(',')
            } else {
                customerIds.push(customerSelected);
            }
        }

        setrequestData({
            fromDate: fromDate,
            toDate: toDate,
            customerIds: customerIds,
        })
    }

    //add fake id for table component
    let tableData = JSON.parse(JSON.stringify(data.productTurnoverReportDetailed));
   //add fake id for table component
    tableData.map((item, index) => {
        item.id = index;
        item.extraService = item.extraService ? 'Ja' : 'Nej';
        return item;
    });

    //function numberWithCommas(x) {
    //    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    //}

    //pricesTotal = numberWithCommas(pricesTotal);

    var csvData = tableData.map(item => (
        { 
            Produkt: item.productName,
            Tillægsprodukt: item.extraService,
            Omsætning: item.totalPrice
        }
    ));

    console.log('tableData', tableData)

    return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Omsætning fordelt på produkter"}
                description={'Detaljeret rapport over omsætning fordelt på produkter.'}
            />
    
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={4} rowSpan={2} >
                    <DataSelectorCard
                        updatefn={updatefn}
                        fromDate={requestData.fromDate}
                        toDate={requestData.toDate}
                        customers={data.getCustomers}
                        customerSelected={requestData.customerIds.toString()}
                    />
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                    <SettleTable csvData={csvData} data={tableData} columnsData={columns} />
                </CardBody>
            </Card>
        </Flex>
    );
}

export default ProductTurnoverReportDetailed;
